<template>
  <v-container :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'">
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col">
        <main-filters
          :reportRequest="reportRequest"
          :page="pageData"
          @cubeDataClick="cubeData"
          @cubeDataMultiClick="cubeDataMulti"
          @graphType="graphTypeClick"
          @stopCalc="stopCube"
          @calendarRange="(val) => (this.range = val)"
          @cities="(val) => (this.cities = val)"
          @tvList="(val) => (this.channels = val)"
          @sources="(val) => (this.sources = val)"
          @aggregations="(val) => (this.aggregations = val)"
          :loading="chart_loading"
          :maxChannels="12"
        >
        </main-filters>
        <v-card
          outlined
          class="mt-2 pl-2"
          v-if="chart_loading === false"
          id="highcharts_png"
        >
          <v-card-title
            class="text-lg-h5 text-md-h6 justify-space-between pb-0 flex-row"
          >
            <v-col
              class="col-12 col-md-4 text-center text-md-left ml-0 pl-0 pb-0"
              style="word-break: break-word"
            >
              {{ chartTitle }}
            </v-col>
            <v-col
              class="col-12 col-md-8 text-center text-md-right"
              id="chartControls"
              data-html2canvas-ignore
            >
              <v-menu
                offset-y
                open-on-hover
                max-width="300"
                v-if="
                  report !== 'hour' &&
                  visionType === 'graph' &&
                  selectedChannelsIds[0] != 'null'
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    elevation="0"
                    outlined
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    :class="isMobile ? 'mr-2 upTo5' : 'mr-2 upTo2'"
                    style="
                      border-radius: 4px !important;
                      height: 36px;
                      width: 36px;
                    "
                    @click="rotatePeriodChart"
                    ><v-icon>mdi-format-rotate-90</v-icon></v-btn
                  >
                </template>
                <v-card>
                  <v-card-text>Развернуть график</v-card-text>
                </v-card>
              </v-menu>

              <v-btn-toggle
                borderless
                v-model="toggle_graphType"
                mandatory
                dense
              >
                <v-btn
                  elevation="0"
                  class="mh-noactive-toggle"
                  :disabled="this.indicator === 'All'"
                  :active-class="'white black--text mh-active-toggle'"
                >
                  <v-icon
                    dense
                    :color="visionType == 'graph' ? 'purple darken-2' : ''"
                    class="pr-1"
                    >mdi-chart-line</v-icon
                  >График
                </v-btn>

                <v-btn
                  elevation="0"
                  class="mh-noactive-toggle"
                  :active-class="'white black--text mh-active-toggle'"
                >
                  <v-icon
                    dense
                    :color="visionType != 'graph' ? 'green darken-4' : ''"
                    class="pr-1"
                    >mdi-table</v-icon
                  >Таблица
                </v-btn>
              </v-btn-toggle>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="secondary"
                    elevation="0"
                    :class="
                      isMobile
                        ? 'mh-secondary-border ml-2 upTo5'
                        : 'mh-secondary-border ml-2 upTo2'
                    "
                    style="text-transform: none !important"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon small>mdi-download</v-icon>Скачать</v-btn
                  >
                </template>
                <v-list dense>
                  <v-list-item dense link @click="toExcel" v-if="!isAlternate">
                    <v-list-item-title>Таблица (xlsx)</v-list-item-title>
                  </v-list-item>
                  <v-list-item dense link @click="saveChartToPng">
                    <v-list-item-title>Изображение (png)</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-col class="ml-0 pl-0">
              <v-btn
                @click="saveChartToPng()"
                v-if="false && visionType === 'graph'"
                class="mb-3"
                >PNG</v-btn
              >
              <div v-if="indicator === 'AudienceDemo' && isMulti">
                <div class="mh-tabs-div"></div>
                <v-tabs
                  v-model="tab"
                  class="mh-demo-tabs"
                  @change="demoTvChange"
                  style="margin-bottom: 12px"
                >
                  <v-tab
                    v-for="item in tabitems"
                    :key="item.id"
                    class="mh-tab body-2"
                  >
                    {{ item.tab }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item v-for="item in tabitems" :key="item.id">
                    <div :id="'highchartDiv' + item.id">
                      <div
                        v-for="(chart, index) in chartOptions"
                        :key="chart.id"
                      >
                        <div
                          class="chartSubtitle"
                          v-if="report != 'period' && visionType === 'graph'"
                        >
                          {{ drawChartSubtitle(chart) }}
                        </div>
                        <highcharts
                          v-if="
                            chart.series[0].data.length &&
                            visionType === 'graph'
                          "
                          :options="{ ...chart, tvId: item.id }"
                          :ref="'hchart' + index"
                        ></highcharts>
                        <programm-accent
                          class="mb-3"
                          v-show="visionType === 'graph'"
                          :source-id="selectedSourceId"
                          :visionType="visionType"
                          :city="selectedCityId"
                          :chart-index="index"
                          :tab-index="tab"
                          :progs="data.charts[index].programs"
                          :channels-ids="
                            tvForAccent.filter((tv) => tv == item.id)
                          "
                          @programsChange="repaintBands"
                          @changeColor="changeBandColor"
                          v-if="
                            true && aggregate === 'Day' && report === 'hour'
                          "
                        >
                        </programm-accent>
                        <v-divider
                          class="chartDivider"
                          v-if="visionType === 'graph'"
                        />
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
              <div v-else id="highchartDiv">
                <div v-for="(chart, index) in chartOptions" :key="chart.id">
                  <div class="chartTitle" v-if="index == 0">
                    {{ drawChartTitle() }}
                  </div>
                  <div
                    class="chartSubtitle"
                    v-if="report != 'period' && visionType === 'graph'"
                  >
                    {{ drawChartSubtitle(chart) }}
                  </div>
                  <highcharts
                    v-if="chart.series[0].data.length && visionType === 'graph'"
                    :options="chart"
                    :ref="'hchart' + index"
                  ></highcharts>
                  <programm-accent
                    class="mb-3"
                    v-show="visionType === 'graph'"
                    :visionType="visionType"
                    :source-id="selectedSourceId"
                    :city="selectedCityId"
                    :chart-index="index"
                    :progs="data.charts[index].programs"
                    :channels-ids="tvForAccent"
                    @programsChange="repaintBands"
                    @changeColor="changeBandColor"
                    v-if="true && aggregate === 'Day' && report === 'hour'"
                  >
                  </programm-accent>
                  <v-divider
                    class="chartDivider"
                    v-if="report != 'period' && visionType === 'graph'"
                  />
                </div>
              </div>
              <v-btn v-if="false && visionType !== 'graph'" @click="toExcel()"
                >Xlsx</v-btn
              >
              <v-row align="center" data-html2canvas-ignore>
                <v-text-field
                  v-if="visionType !== 'graph'"
                  v-model="tableSearch"
                  label="Поиск по таблице"
                  class="mx-4"
                ></v-text-field>
                <v-tooltip bottom v-if="visionType !== 'graph'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="rounded grey lighten-4"
                      active-class="rounded"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      v-if="visionType !== 'graph'"
                      @click="copyToClipboard('dt-table')"
                      ><v-icon class="rounded">mdi-content-copy</v-icon></v-btn
                    >
                  </template>
                  <span>Скопировать в буфер</span>
                </v-tooltip>

                <v-snackbar
                  v-model="snackbarCopy"
                  :timeout="1000"
                  right
                  color="success"
                  class="mb-16"
                >
                  Данные скопированы
                </v-snackbar>
              </v-row>
              <v-data-table
                id="dt-table"
                v-if="visionType !== 'graph'"
                :headers="tableHeaders"
                :items="tableItems()"
                :hide-default-footer="true"
                multi-sort
                :search="tableSearch"
                disable-pagination
              >
                <template v-slot:item.y="{ item }">
                  <span>{{ makeFixed(item.y, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y1="{ item }">
                  <span>{{ makeFixed(item.y1, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y2="{ item }">
                  <span>{{ makeFixed(item.y2, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y3="{ item }">
                  <span>{{ makeFixed(item.y3, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y4="{ item }">
                  <span>{{ makeFixed(item.y4, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y5="{ item }">
                  <span>{{ makeFixed(item.y5, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.aud="{ item }">
                  <span>{{ makeFixed(item.aud, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.share="{ item }">
                  <span>{{
                    makeFixed(item.share, 3, true).replace(/\./g, ",")
                  }}</span>
                </template>
                <template v-slot:item.tvr="{ item }">
                  <span>{{
                    makeFixed(item.tvr, 3, true).replace(/\./g, ",")
                  }}</span>
                </template>
                <template v-slot:item.reach="{ item }">
                  <span>{{
                    makeFixed(item.reach, 3).replace(/\./g, ",")
                  }}</span>
                </template>
                <template v-slot:item.reachPercent="{ item }">
                  <span>{{
                    makeFixed(item.reachPercent, 3, true).replace(/\./g, ",")
                  }}</span>
                </template>
              </v-data-table>
            </v-col>
            <v-row justify="center" v-if="chart_loading === true">
              <v-col justify="center"
                ><v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular></v-col
            ></v-row>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mt-2"
          :height="heightChart"
          v-else-if="chart_loading === true"
        >
          <base-loader></base-loader>
        </v-card>
        <v-row
          v-else
          justify="center"
          class="mt-12"
          style="margin-left: 0; margin-right: 0"
        >
          <pre-report-mount />
        </v-row>
      </v-col>
    </v-row>
    <script
      defer
      type="application/javascript"
      :src="
        'https://js.onef.pro/static/reg1f_v1.js?1f_pixel_id=bb38ba5b-71c5-463f-b819-bf5757feaad3&event_type=visit&product=TVReport&order_id=' +
        check_cookie_name('_ym_uid')
      "
    ></script>
    <preset-drawer
      report="tvchannels"
      v-if="!$route.params.uuid"
    ></preset-drawer>
    <video-player
      :shown="videoPlayerOn"
      :dt="videoPlayerDt"
      :videoChannelId="videoPlayerChannel"
      :videoChannelName="videoPlayerChannelName"
      :valueName="videoPlayerValueName"
      :values="videoPlayerValues"
      :program="videoPlayerProgram"
      @close="videoPlayerOn = false"
    />
  </v-container>
</template>
<style lang="scss">
.chartTitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 32px;
}
.chartSubtitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 8px;
}
.chartDivider {
  margin-top: 32px;
  margin-bottom: 32px;
  color: rgba(0, 0, 0.12);
}
</style>
<script>
import MainFilters from "@/components/MainFilters.vue";
import axios from "axios";
import { Chart } from "highcharts-vue";
import moment from "moment";
import html2canvas from "html2canvas";
import ProgrammAccent from "@/components/ProgrammAccent.vue";
import uuidv4 from "uuid/v4";
import BaseLoader from "@/components/BaseLoader.vue";
import PresetDrawer from "@/components/PresetDrawer.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import PreReportMount from "@/components/PreReportMount.vue";
import { CONSTS } from "../services/constants.js";
import Utils from "../services/utils";

export default {
  name: "Indicators",
  components: {
    PresetDrawer,
    BaseLoader,
    ProgrammAccent,
    MainFilters,
    highcharts: Chart,
    VideoPlayer,
    PreReportMount,
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  async mounted() {
    if (!this.hasPriv) return this.$router.push("/");
    window.addEventListener("resize", this.handleResize, true);
    if (this.$route.params.uuid) {
      this.chart_loading = true;
      this.chartWithUuid();
    }
  },
  methods: {
    prepareValuesData(categories, series, series2 = null) {
      const values = {};
      for (let i = 0; i < categories.length; i++) {
        const key = moment(categories[i]).utc().format("YYYY-MM-DD HH:mm");
        const newValues = [series.data[i].y];
        if (series2) {
          // приходы-уходы - 2 значения
          newValues.push(series2.data[i].y);
        }
        values[key] = newValues;
      }
      return values;
    },
    playPoint(videoChannelId, dt, channelName, valueName, programs, values) {
      console.log(
        `Play: ${videoChannelId} (${channelName}) time: ${dt} ${this.videoPlayerOn}`
      );
      console.log(programs);
      if (this.videoPlayerOn) {
        this.videoPlayerOn = false;
        return setTimeout(() =>
          this.playPoint(
            videoChannelId,
            dt,
            channelName,
            valueName,
            programs,
            values
          )
        );
      }
      this.videoPlayerChannel = videoChannelId;
      this.videoPlayerChannelName = channelName;
      this.videoPlayerValueName = valueName;
      this.videoPlayerDt = dt;
      this.videoPlayerProgram = programs;
      this.videoPlayerValues = values;
      this.videoPlayerOn = true;
    },
    nextMinutesInterval([h, m]) {
      if (this.detalisation == 1) return "";
      let h1 = parseInt(h);
      let m1 = +m - 1 + +this.detalisation;
      m1 = m1 < 10 ? "0" + m1 : m1;
      h1 = h1 < 10 ? "0" + h1 : h1;
      return " - " + h1 + ":" + m1;
    },
    rotatePeriodChart() {
      if (this.periodChartType == "bar") {
        this.periodChartType = "column";
      } else {
        this.periodChartType = "bar";
      }
    },
    drawChartTitle() {
      if (!this.range.weekdays.length) return this.subtitle;
      let weekdays = [...this.range.weekdays].sort();
      weekdays = weekdays.map((item) => CONSTS.WEEKDAYS[item - 1] ?? null);
      if (weekdays[0] == "вс") {
        weekdays.shift();
        weekdays.push("вс");
      }
      return `${this.subtitle} (${weekdays.join(",")})`;
    },
    drawChartSubtitle(chart) {
      if (this.aggregate == "Period") return "";
      const date = chart.series[0].subtitle;
      const startTime = moment(this.range.start).locale("ru").format("HH:mm");
      const endTime = moment(this.range.end).locale("ru").format("HH:mm");
      const wd = CONSTS.WEEKDAYS[moment(date, "DD.MM.YYYY").day()];
      let str;
      if (this.range.through == true)
        str = `${wd} ${date}, ${startTime} - ${date}, ${endTime}`;
      if (this.range.through == false)
        str = `${wd} ${date} - ${date}, ${startTime} - ${endTime}`;
      return str;
    },
    demoTvChange() {
      for (let i = 0; i < this.chartOptions.length; i++) {
        this.repaintBands({
          programs: [],
          chartIndex: i,
          tvid: this.tabitems[this.tab],
          tabId: this.tab,
        });
      }
    },
    handleResize() {
      this.periodChartWidth =
        document.getElementById("highchartDiv")?.clientWidth;
      if (this.periodChartWidth) {
        this.periodChartWidth = this.periodChartWidth - 20;
      } else this.periodChartWidth = null;
    },
    async chartWithUuid() {
      if (!this.$route.params.uuid) return;
      this.chart_loading = true;
      let res = await axios
        .post(`result/${this.$route.params.uuid}`)
        .catch(function () {
          this.chart_loading = null;
          this.isStoped = true;
          alert("Произошла ошибка");
        });
      if (res.data.message == "not ready") {
        this.reportRequest = res.data.request;
        setTimeout(() => {
          if (!this.isStoped) this.chartWithUuid();
        }, 1000);
        return;
      }

      if (this.isStoped) return;

      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      this.reportUuid = this.$route.params.uuid;
      this.detalisation = res.data.request["detalisation"];
      this.reportRequest = res.data.request;
      this.report = res.data.request["report"];
      this.selectedCityId = res.data.request["geo"];
      this.selectedChannelsIds = res.data.request["tv"]?.split(",");
      //console.log(this.selectedChannelsIds)
      this.indicator = res.data.request["indicator"];
      this.aggregate = res.data.request["aggregate"];
      this.selectedSourceId = res.data.request["source_id"];
      this.helper = res.data.request["helper"];
      this.all_channel = res.data.request["all_channel"] == 'true';
      this.coef = res.data.request["useCoeff"];
      this.manytv = res.data.request["manytv"];
      this.range.through = res.data.request["through"] == 'true';
      this.range.weekdays = res.data.request["weekdays"].split(",");
      this.range.start = `${res.data.request.startDate}T${res.data.request.startTime}:00.000`;
      this.range.end = `${res.data.request.endDate}T${res.data.request.endTime}:00.000`;
      this.chart_loading = false;

      // this.data = res.data.result;
      if (this.selectedChannelsIds.length > 1) {
        this.isMulti = true;

        let multiDada = res.data.result.res;
        let pre_data = multiDada[0];
        for (let i = 1; i < this.selectedChannelsIds.length; i++) {
          for (let j = 0; j < pre_data.charts.length; j++) {
            for (let k = 0; k < multiDada[i].charts[j].series.length; k++) {
              pre_data.charts[j].series.push(multiDada[i].charts[j].series[k]);
              if (pre_data.charts[j].programs) {
                pre_data.charts[j].programs = pre_data.charts[
                  j
                ].programs.concat(multiDada[i].charts[j].programs);
              }
            }
          }
        }
        res.data = pre_data;
        this.data = res.data;
      } else {
        this.data = res.data.result;
      }

      console.log(this.report);
      this.data.load = true;
      setTimeout(() => this.handleResize());
    },
    exportBtnClick(e) {
      switch (e) {
        case "png":
          this.saveChartToPng();
          break;
        case "svg":
          break;

        case "pdf":
          break;

        default:
          //xls
          this.toExcel();
          break;
      }
    },
    makeFixed(val, digits, expandable = false) {
      if (
        this.indicator === "TVR" ||
        this.indicator === "TVRTotal" ||
        this.indicator === "Share" ||
        this.indicator === "ReachPercent"
      ) {
        expandable = true;
      }
      if (expandable) {
        if (val < 0.005 && digits < 6) {
          digits = 6;
        }
      }
      if (val == "") return "";
      if (!val) return "0.0";
      return val.toFixed(digits);
    },
    repaintBands(event) {
      //console.log("repaint");
      const chartId = event.chartIndex;
      const tvId = event.tvid;
      const tabId = event.tabId ?? 0;
      const seriesId = this.selectedChannelsIds.findIndex(
        (item) => item == tvId
      );

      const currentDate = moment(
        this.data.charts[chartId].series[seriesId].subtitle,
        "DD.MM.YYYY"
      ).format("YYYY-MM-DD");
      //console.log(chartId, tvId, seriesId, currentDate);
      let detalisation = Number(this.detalisation);

      let plotBands = [];
      if (!this.data.charts.length) {
        return;
      }
      let categories = this.data.charts[chartId].categories;
      let chartStart = categories[0];

      if (event.programs.length === 0) {
        if (this.data.charts[event.chartIndex].programs) {
          for (let j = 0; j < this.data.charts[chartId].programs.length; j++) {
            if (this.data.charts[chartId].programs[j].tvid == event.tvid) {
              let start_loc_time =
                this.data.charts[chartId].programs[j]["start_loc_time"];
              let stop_loc_time =
                this.data.charts[chartId].programs[j]["stop_loc_time"];
              let title = this.data.charts[chartId].programs[j]["title"];
              let subtitle = this.data.charts[chartId].programs[j]["subtitle"];
              plotBands.push({
                from:
                  moment(
                    this.data.charts[chartId].programs[j]["start_loc"] + ":00Z",
                    "YYYY-MM-DD HH:mm:ssZ"
                  ).diff(moment(categories[0]), "minutes") / detalisation,
                to:
                  moment(
                    this.data.charts[chartId].programs[j]["stop_loc"] + ":00Z",
                    "YYYY-MM-DD HH:mm:ssZ"
                  ).diff(moment(categories[0]), "minutes") / detalisation,

                color: j % 2 ? CONSTS.BAND_ACCENT_COLOR : "#FFFFFF",
                events: {
                  mouseover: function () {
                    const chart = this.axis.chart;
                    chart.myPlotband = [
                      start_loc_time,
                      stop_loc_time,
                      title,
                      subtitle,
                    ];
                    chart.series[seriesId].onMouseOver();
                  },
                  mouseout: function () {
                    const chart = this.axis.chart;
                    chart.myPlotband = null;
                  },
                },
              });
            }
          }
        }
        if (
          this.visionType == "graph" &&
          this.$refs["hchart" + event.chartIndex] &&
          this.$refs["hchart" + event.chartIndex][tabId].chart
        ) {
          this.$refs["hchart" + event.chartIndex][tabId].chart.xAxis[0].update({
            plotBands: plotBands,
          });
        }

        return;
      }

      for (let i = 0; i < event.programs.length; i++) {
        let start_loc_time = event.programs[i]["start"];
        let stop_loc_time = event.programs[i]["stop"];
        const startDate = event.programs[i]["startDate"];
        const stopDate = event.programs[i]["stopDate"];
        if (startDate < currentDate) start_loc_time = "00:00";
        if (stopDate > currentDate) stop_loc_time = "23:59";
        const title = event.programs[i]["program"];
        plotBands.push({
          from:
            moment(
              this.data.charts[chartId].series[0].subtitle +
                " " +
                start_loc_time +
                ":00Z",
              "DD.MM.YYYY HH:mm:ssZ"
            ).diff(moment(chartStart), "minutes") / detalisation,

          to:
            moment(
              this.data.charts[chartId].series[0].subtitle +
                " " +
                stop_loc_time +
                ":00Z",
              "DD.MM.YYYY HH:mm:ssZ"
            ).diff(moment(chartStart), "minutes") / detalisation,
          color: event.programs[i].color,
          id: event.programs[i].id,
          events: {
            mouseover: function () {
              const chart = this.axis.chart;
              chart.myPlotband = [
                `${start_loc_time}`,
                `${stop_loc_time}`,
                `${title}`,
                "",
              ];
            },
            mouseout: function () {
              const chart = this.axis.chart;
              chart.myPlotband = null;
            },
          },
        });
      }
      if (
        this.$refs["hchart" + event.chartIndex] &&
        this.$refs["hchart" + event.chartIndex][tabId].chart
      ) {
        this.$refs["hchart" + event.chartIndex][tabId].chart.xAxis[0].update({
          plotBands: plotBands,
        });
      }
    },
    changeBandColor(event) {
      const tabId = event.tabId ?? 0;
      let bands =
        this.$refs["hchart" + event.chartIndex][tabId].chart.xAxis[0]
          .userOptions.plotBands;
      for (let i = 0; i < bands.length; i++) {
        if (bands[i].id === event.id) {
          bands[i].color = event.color;
          this.$refs["hchart" + event.chartIndex][tabId].chart.xAxis[0].update({
            plotBands: bands,
          });
          break;
        }
      }
    },
    stopCube() {
      this.chart_loading = null;
      this.isStoped = true;
      this.axiosCancel();
    },
    async saveChartToPng() {
      let selector = `#highcharts_png`;
      if (this.indicator === "AudienceDemo") {
        selector += this.tabitems[this.tab].id;
      }
      //const chartsNodes = document.querySelectorAll('.highcharts-container');for (const node of chartsNodes) { node.style.width="1200px" }
      const canvas = await html2canvas(document.querySelector(selector), {
        //width: 1200, // попытка масштабировать график
        //windowWidth: 1200,
        onclone: function (clonedDoc) {
          //const docNode = clonedDoc.querySelector(selector);
          //docNode.style.width = "1200px";
          //docNode.style.minWidth = "1200px";
          //const chartsNodes = clonedDoc.querySelectorAll('.highcharts-container');
          //for (const node of chartsNodes) { node.style.width="120px" }
          const accentNodes = clonedDoc.querySelectorAll(
            ".accentData .v-data-table__wrapper"
          );
          for (const node of accentNodes) {
            node.style.height = "auto";
          }
        },
      });
      Utils.saveAs(canvas.toDataURL(), `${this.filename}.png`);
    },
    async toExcel() {
      const reportName = "Tvchannels";
      const start = moment(this.range.start).locale("ru").format("DD.MM.YYYY");
      const end = moment(this.range.end).locale("ru").format("DD.MM.YYYY");
      const startTime = moment(this.range.start).locale("ru").format("HH:mm");
      const endTime = moment(this.range.end).locale("ru").format("HH:mm");
      const city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });

      const source = this.sources.find((el) => {
        return el.id === this.selectedSourceId;
      });

      const channels = this.channels.filter((el) => {
        return this.selectedChannelsIds.includes(el.id);
      });

      let aggregate = this.aggregations?.find((el) => {
        return el.id === this.aggregate;
      });

      if (!aggregate)
        aggregate = {
          name: Utils.aggregationName(this.aggregate, this.report),
        };
      let headers = [];

      for (let i = 0; i < this.tableHeaders.length; i++) {
        headers.push({ name: this.tableHeaders[i].text });
      }
      let tableItems = this.tableItems();
      let deleteDate = !(this.report === "hour"
        ? this.aggregate === "Day"
        : false);
      for (let i = 0; i < tableItems.length; i++) {
        if (deleteDate) {
          delete tableItems[i].date;
        }
      }
      const params = [
        "Id отчета: " + this.reportUuid,
        "Время: " + this.reportTime,
        "Период: " + `${start} ${startTime} - ${end} ${endTime}`,
        "Дни недели: " + this.range.weekdays.sort(),
        "Вычисление периода: " + this.range.through,
        "Источник: " + source.name,
        "Справочник: " + this.helper,
        "Коэффициенты: " + this.coef,
        "Регион: " + city.name,
      ];

      //console.log(this.report);
      if (aggregate) {
        params.push("Агрегация: " + aggregate.name);
      }

      if (this.report != "period") {
        params.push("Детализация: " + this.detalisation);
      }

      if (channels.length > 1) {
        params.push(
          `Телеканалы: ${channels.map((item) => item.export_name).join(", ")}`
        );
      } else {
        if (channels.length === 1)
          params.push(`Телеканал: ${channels[0].export_name}`);
      }

      //костыль
      tableItems = [...tableItems.map(item=>{
        if(!this.isMulti) {
          delete item.channel_name_multi
        }
        return item
      })];
      console.log(tableItems);
      console.log(headers);

      const req = {
        jsonArray: [[...headers], ...tableItems],
        params,
        reportName: reportName,
        indicator: this.indicator,
      };
      const response = await axios.post("jsonToExcel", req, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${this.filename}.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
    copyToClipboard(containerid) {
      var range = document.createRange();
      let containerNode = document.getElementById(containerid); //// this part
      range.selectNode(containerNode); //// this part
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      this.snackbarCopy = true;
    },
    graphTypeClick(isGraph) {
      this.visionType = isGraph ? "graph" : "table";
    },
    tableItems() {
      let items = [];
      for (let j = 0; j < this.chartOptions.length; j++) {
        let chart = this.chartOptions[j];
        let length = chart.xAxis.categories.length;
        let firstCondition = !this.isMulti;
        if (this.indicator === "All") {
          firstCondition = true;
        }
        if (firstCondition) {
          if (this.indicator === "AudienceDemo") {
            for (let i = 0; i < length; i++) {
              items.push({
                num: j * length + (i + 1),
                date: chart.series[0].subtitle,
                time:
                  this.report === "hour" && this.aggregate === "Day"
                    ? moment(this.data.charts[j].categories[i])
                        .utc()
                        .format("HH:mm")
                    : this.data.charts[j].categories[i],
                y: chart.series[0].data[i].y, //.toString().replace(/\./g, ","),
                y2: chart.series[1].data[i].y, //.toString().replace(/\./g, ","),
              });
            }
          } else {
            if (this.indicator === "All") {
              for (let k = 0; k < chart.series.length; k++) {
                for (let i = 0; i < length; i++) {
                  items.push({
                    num: k * length + (i + 1),
                    channel_name_multi: chart.series[k].name,
                    channel: this.data.charts[j].categories[i],
                    aud: chart.series[k].data[i].aud,
                    // .toString()
                    // .replace(/\./g, ","),
                    share: chart.series[k].data[i].share,
                    // .toString()
                    // .replace(/\./g, ","),
                    tvr: chart.series[k].data[i].tvr,
                    // .toString()
                    // .replace(/\./g, ","),
                    reach: chart.series[k].data[i].reach,
                    // .toString()
                    // .replace(/\./g, ","),
                    reachPercent: chart.series[k].data[i].reachPercent,
                    // .toString()
                    // .replace(/\./g, ","),
                  });
                }
              }
            } else {
              for (let i = 0; i < length; i++) {
                items.push({
                  num: j * length + (i + 1),
                  date: chart.series[0].subtitle,
                  // time: chart.xAxis.categories[i],
                  time:
                    this.report === "hour" && this.aggregate === "Day"
                      ? moment(this.data.charts[j].categories[i])
                          .utc()
                          .format("HH:mm")
                      : this.data.charts[j].categories[i],

                  y: chart.series[0].data[i].y, //.toString().replace(/\./g, ","),
                });
              }
            }
          }
        } else {
          for (let i = 0; i < length; i++) {
            let item = {
              num: j * length + (i + 1),
              date: chart.series[0].subtitle,
              time:
                this.report === "hour" && this.aggregate === "Day"
                  ? moment(this.data.charts[j].categories[i])
                      .utc()
                      .format("HH:mm")
                  : this.data.charts[j].categories[i],
            };
            for (let k = 0; k < chart.series.length; k++) {
              item[`y${k}`] = chart.series[k].data[i].y;
            }
            items.push(item);
          }
        }
      }

      return items;
    },
    async cubeData(req) {
      this.isStoped = false;
      this.data = {};
      this.multiCount = 1;
      this.isMulti = false;
      this.tvForAccent = [req.get("tv")];
      this.all_channel = req.get("all_channel") == 'true';
      this.tvForAccent = this.tvForAccent?.[0]?.split(",");
      this.reportUuid = uuidv4();
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      this.chart_loading = true;

      req.set("requestedReport", this.reportName);
      req.set("uuid", this.reportUuid);
      let cubeObj = {};
      req.forEach(function (value, key) {
        cubeObj[key] = value;
      });
      cubeObj["email"] = this.$store.getters.StateUser.email;

      cubeObj["action"] = "MakeReport";
      // eslint-disable-next-line no-undef
      let reportUniqid = Utils.generateUID();
      cubeObj["uniqid"] = reportUniqid;
      cubeObj["uid"] = this.$store.getters.StateUser.uid;
      cubeObj["ym_uid"] = this.check_cookie_name("_ym_uid");
      // eslint-disable-next-line no-undef
      if (process.env.VUE_APP_ALTERNATE_UI != "true") mh_px(300, cubeObj);
      // eslint-disable-next-line no-unused-vars
      let timeReq = performance.now();

      const CancelToken = axios.CancelToken;
      let that = this;
      let res = await axios
        .post("reports/cubedata", req, {
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            that.axiosCancel = c;
          }),
        })
        .catch(function () {
          this.chart_loading = null;
          this.isStoped = true;
          alert("Произошла ошибка");
        });
      if (res.data.message === "The report has been queued") {
        this.reportRequest = res.data.request;
        // window.location.href = this.$route.path + "/" + res.data.uuid;
        this.$router.push(this.$route.path + "/" + res.data.uuid);
        this.chartWithUuid();
        return;
      }
      if (this.isStoped) return;

      this.detalisation = req.get("detalisation");
      this.report = req.get("report_type");
      this.selectedCityId = req.get("geo");
      this.selectedChannelsIds = req.get("tv")?.split(",");
      this.multiCount = this.selectedChannelsIds.length;
      this.isMulti = this.selectedChannelsIds.length > 1;
      //console.log(this.selectedChannelsIds, this.isMulti);
      this.indicator = req.get("indicator");
      this.aggregate = req.get("aggregate");
      this.selectedSourceId = req.get("source_id");
      this.helper = req.get("helper");
      this.coef = req.get("useCoeff");
      this.manytv = req.get("manytv");
      if (this.selectedChannelsIds.length > 1) {
        let multiDada = res.data.res;
        let pre_data = multiDada[0];
        for (let i = 1; i < this.selectedChannelsIds.length; i++) {
          for (let j = 0; j < pre_data.charts.length; j++) {
            for (let k = 0; k < multiDada[i].charts[j].series.length; k++) {
              pre_data.charts[j].series.push(multiDada[i].charts[j].series[k]);
              if (pre_data.charts[j].programs) {
                pre_data.charts[j].programs = pre_data.charts[
                  j
                ].programs.concat(multiDada[i].charts[j].programs);
              }
            }
          }
        }
        res.data = pre_data;
      }
      setTimeout(() => this.handleResize());
      this.chart_loading = false;
      timeReq = performance.now() - timeReq;
      if (process.env.VUE_APP_ALTERNATE_UI != "true")
        // eslint-disable-next-line no-undef
        mh_px(300, {
          uniqid: reportUniqid,
          action: "DataReport",
          // email: this.$store.getters.StateUser.email,
          uid: this.$store.getters.StateUser.uid,
          msecs: timeReq,
        });

      this.data = res.data;
      this.data.load = true;
    },
    async cubeDataMulti() {},
  },
  data: () => ({
    selectedSourceId: 1,
    all_channel: false,
    reportRequest: {},
    currentSeries: null, // серия подсвеченная при наведении на текущий график
    videoPlayerOn: false, // если true - открыт диалог проигрывания видео
    videoPlayerChannel: null,
    videoPlayerChannelName: null,
    videoPlayerValueName: null, // название отображаемого показтеля
    videoPlayerDt: null,
    videoPlayerProgram: null, // расписание передач
    videoPlayerValues: null, // помнутные значения выбранного показателя
    axiosCancel: null,
    periodChartType: "bar",
    periodChartWidth: null,
    reportName: "tvchannels",
    snackbarCopy: false,
    tab: null,
    progAccentShow: true,
    tvForAccent: [],
    toggle_graphType: 0,
    selectedCityId: null,
    range: {},
    tableSearch: "",
    report: null,
    isMulti: false,
    detalisation: 1,
    aggregate: null,
    multiCount: 1,
    visionType: "graph",
    tvList: [],
    isStoped: false,
    allProgramms: [],
    data: {},
    manytv: false,
    indicator: "",
    chart_loading: null,
    cities: [],
    pageData: {
      title: "Показатели телеканалов",
      type: "tv",
      many_channel: true,
    },
  }),
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    hasPriv() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_tvchannels
      ];
    },
    filename() {
      const city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });

      const channels = this.channels.filter((el) => {
        return this.selectedChannelsIds.includes(el.id);
      });
      const shows = [];
      const filename = Utils.createFilename({
        range: this.range,
        cities: city,
        channels: channels,
        shows: shows,
        indicator: this.chartTitle,
        reportTime: this.reportTime,
      });
      return filename;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    isLg() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    tabitems() {
      let items = [];
      this.tvForAccent.forEach((el) => {
        items.push({
          id: el,
          tab: this.channels.find((tv) => {
            return tv.id == el;
          })[this.isAdmin ? "ns1_name_admin" : "ns1_name_ru"],
          content: el,
        });
      });
      return items;
    },
    subtitle() {
      let start = moment(this.range.start).locale("ru").format("DD MMM YYYY");
      let end = moment(this.range.end).locale("ru").format("DD MMM YYYY");
      let startTime = moment(this.range.start).locale("ru").format("HH:mm");
      let endTime = moment(this.range.end).locale("ru").format("HH:mm");
      let city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });
      return `${city.name}, ${start} - ${end}, ${startTime} - ${endTime}`;
    },
    tableHeaders() {
      if (this.indicator === "All") {
        let items = [
          {
            text: "№",
            value: "num",
            visible: true,
          },
          {
            text: this.all_channel ? "Канал" : "Период",
            value: "channel",
            visible: true,
          },
          {
            text: "Аудитория",
            value: "aud",
            visible: true,
          },
          {
            text: "Доля, %",
            value: "share",
            visible: true,
          },
          {
            text: "Рейтинг",
            value: "tvr",
            visible: true,
          },
          {
            text: "Охват, тыс. телезрителей",
            value: "reach",
            visible: true,
          },
          {
            text: "Охват, %",
            value: "reachPercent",
            visible: true,
          },
        ];
        if (this.isMulti) {
          items.splice(1, 0, {
            text: "Канал",
            value: "channel_name_multi",
            visible: true,
          });
        }
        console.log(items);

        return items;
      }

      if (this.isMulti) {
        let headers = [
          {
            text: "№",
            value: "num",
            visible: true,
          },
          {
            text: "Дата",
            value: "date",
            visible: this.report === "hour" ? this.aggregate === "Day" : false,
          },
          {
            text:
              this.report === "hour"
                ? "Время"
                : this.manytv === "true"
                ? "Канал"
                : "Период",
            value: "time",
            visible: true,
          },
        ];

        if (this.data.charts.length) {
          for (let i = 0; i < this.data.charts[0].series.length; i++) {
            if (this.indicator === "AudienceDemo") {
              if (i > 1) {
                break;
              }
            }
            headers.push({
              text: this.data.charts[0].series[i].name,
              value: "y" + i,
              visible: true,
            });
          }
        }

        return headers.filter((el) => {
          return el.visible;
        });
      }

      let headers = [
        {
          text: "№",
          value: "num",
          visible: true,
        },
        {
          text: "Дата",
          value: "date",
          visible: this.report === "hour" ? this.aggregate === "Day" : false,
        },
        {
          text:
            this.report === "hour"
              ? "Время"
              : this.manytv === "true"
              ? "Канал"
              : "Период",
          value: "time",
          visible: true,
        },
        {
          text: this.chartTitle,
          value: "y",
          visible: true,
        },
      ];
      if (this.indicator === "AudienceDemo") {
        headers[headers.length - 1]["text"] = "Мужчины";
        headers.push({
          text: "Женщины",
          value: "y2",
          visible: true,
        });
      }

      return headers.filter((el) => {
        return el.visible;
      });
    },
    heightChart() {
      let h = 400;
      if (this.report !== "hour") {
        h =
          this.data.charts &&
          this.data.charts[0] &&
          this.data.charts[0].categories.length;
        h = h * 40 + 25;
        if (this.indicator === "AudienceDemo" || this.multiCount > 1) {
          h = h * 2;
        }
        if (this.isMulti) {
          h = h * this.multiCount;
        }
        h = h < 400 ? 400 : h;
      }

      return h;
    },
    periodChartHeight() {
      let h = 400;
      return h;
    },
    chartOptions() {
      if (this.report === "hour") return this.chartOptionsHour;
      return this.chartOptionsPeriod;
    },
    chartOptionsHour() {
      const aggregate = this.aggregate;
      const channelsIds = this.selectedChannelsIds;
      let charts = [];
      let that = this;
      let data = this.data.charts ?? [];

      let mult = 60 / this.detalisation;
      let ticks = 24;
      if (this.isMobile) {
        mult *= 4;
        ticks = 6;
      }
      const tickPositions = new Array(ticks).fill().map((item, i) => i * mult);

      for (let i = 0; i < data.length; i++) {
        let plotBands = [];
        let categories = data[i].categories;
        if (aggregate !== "Period") {
          let chartDate = data[i].series[0].subtitle;
          for (let j = 0; j < categories.length; j++) {
            if (typeof categories[j] === "string")
              //зачем здесь мутация ??!! добавил костыль во избежание двойной мутации
              categories[j] =
                moment(
                  chartDate + " " + categories[j] + ":00Z",
                  "DD.MM.YYYY HH:mm:ssZ"
                ).unix() * 1000;
          }
        }

        let chartMax = 0;
        for (const seriesData of data[i].series) {
          const seriesMax = Math.max(...seriesData.data.map((s) => s.y));
          if (seriesMax > chartMax) chartMax = seriesMax;
        }

        charts.push({
          id: i,
          chart: {
            zoomType: "x",
            marginRight: 15,
            type: data[i].series.length == 1 ? "areaspline" : "spline",
            events: {
              click: function (e) {
                if (that.aggregate == "Period") return;
                if (e.srcElement.firstChild) return; // reset zoom pressed
                const videoChannelId =
                  data[i].series[that.currentSeries].video_channel_id;
                const channelName = data[i].series[that.currentSeries].name;
                const point = parseInt(e.xAxis[0].value);
                const tvId = data[i].series[that.currentSeries].tvid;
                const programs = data[i].programs.filter(
                  (item) => item.tvid == tvId
                );
                const values = that.prepareValuesData(
                  categories,
                  data[i].series[that.currentSeries]
                );
                const dt = moment(data[i].categories[point])
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss");
                that.playPoint(
                  videoChannelId,
                  dt,
                  channelName,
                  that.chartTitle,
                  programs,
                  values
                );
              },
            },
            height: this.heightChart,
          },
          title: { text: "" },
          legend: {
            useHTML: true,
            labelFormatter: function () {
              if (that.aggregate == "Period") return this.name;
              const video_channel_id = this.options.video_channel_id;
              const icon = video_channel_id ? "&#xF0567;" : "&#xF0568;";
              const color = video_channel_id ? this.color : "#BDBDBD";
              const str = `${this.name} <span style='color: ${color}; font-size:1.3em; font-family: "Material Design Icons"'>${icon}</span>`;
              return str;
            },
          },
          colors:
            this.indicator === "AudienceDemo"
              ? CONSTS.CHART_DEMO_COLORS
              : CONSTS.CHART_COLORS,
          //subtitle: { text: data[i].series[0].subtitle ?? "" },
          subtitle: { text: "" },
          exporting: {
            sourceWidth: 1200,
            chartOptions: {
              chart: {
                events: {},
              },
            },
          },
          xAxis: {
            tickPositions,
            tickWidth: 1,
            tickmarkPlacement: "on",
            min: 0.5,
            max: categories.length - 1.5,
            startOnTick: false,
            endOnTick: false,
            minPadding: 0,
            maxPadding: 0,
            align: "left",
            categories,
            plotBands,
            labels: {
              rotation: 0,
              useHTML: true,
              formatter: function () {
                let value = this.value;
                let h, m;
                if (value.toString().length == 5) {
                  h = value.substring(0, 2);
                  m = value.substring(3, 5);
                } else {
                  value = moment(value).utc().format("YYYY-MM-DD HH:mm");
                  h = value.substring(11, 13);
                  m = value.substring(14, 16);
                }

                if (this.isFirst) {
                  return `
                  <div style="padding-top: 5px">
                    <span style="padding-right: 5px; margin-left: -20px; font-size: 1.1em; font-weight: bold;">час\u00a0</span>
                    <b style="font-size: 1.1em;">${h}</b>
                    <br>

                    <span style="padding-right: 6px; margin-left: -20px;">мин\u00a0</span>${m}
                  </div>

                  `;
                }
                return `
                  <div style="padding-top: 4px; margin-left: 10px;">

                  <span style=""><b style="font-size: 1.1em;">${h}</b></span>
                  <br>
                  <span style="">${m}</span>

                  </div>
                `;
              },
            },
          },
          yAxis: {
            min: 0,
            max: chartMax,
            reversedStacks: false,
            title: { text: this.chartTitle },
            plotLines: [
              {
                value: 0,
                width: 1,
                color: "#808080",
              },
            ],
          },
          plotOptions: {
            areaspline: {
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1,
                },
                stops: [
                  [0, CONSTS.CHART_COLORS[0]],
                  [1, CONSTS.CHART_COLORS[0].replace("1)", "0)")],
                ],
              },
              marker: {
                radius: 2,
              },
              lineWidth: 1,
              states: {
                hover: {
                  lineWidth: 1,
                },
              },
              threshold: null,
            },
            series: {
              stickyTracking: false,
              inside: false,
              states: {
                inactive: {
                  opacity: 1,
                },
              },
              events: {
                click: function (event) {
                  if (that.aggregate == "Period") return;
                  const seriesId = event.point.series.index;
                  const videoChannelId =
                    data[i].series[seriesId].video_channel_id;
                  const channelName = data[i].series[seriesId].name;
                  const tvId = data[i].series[seriesId].tvid;
                  const programs = data[i].programs?.filter(
                    (item) => item.tvid == tvId
                  );
                  const values = that.prepareValuesData(
                    categories,
                    data[i].series[seriesId]
                  );
                  const dt = moment(event.point.category)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss");
                  that.playPoint(
                    videoChannelId,
                    dt,
                    channelName,
                    that.chartTitle,
                    programs,
                    values
                  );
                },
              },
              turboThreshold: 3600 * 24,
              marker: {
                radius: 1,
                enabled: false,
              },
            },
          },
          tooltip: {
            useHTML: true,
            formatter(e) {
              function fillProgData(dt, seriesId, chartId) {
                let seriesIndex = seriesId;
                if (that.indicator === "AudienceDemo") {
                  seriesIndex = 0; // костыль для получения tvId в отчете 1 канал с демографией
                }
                let tvId = channelsIds[seriesIndex];
                that.currentSeries = seriesIndex;
                if (e.chart.options.tvId) tvId = e.chart.options.tvId; // а это костыль для нахождения tvId при демографии по нескольким каналам (берем из вкладки)

                const prog = data[chartId]?.programs?.find(
                  (item) =>
                    item.tvid == tvId &&
                    item.start_loc <= dt &&
                    item.stop_loc >= dt
                );
                return prog;
              }

              let hhmm = this.x;
              let prog = null;
              if (aggregate !== "Period") {
                hhmm = moment(this.x).utc().format("HH:mm");
                const dt = moment(this.x).utc().format("YYYY-MM-DD HH:mm:ss");
                prog = fillProgData(
                  dt,
                  this.point.series.index,
                  e.chart.options.id
                );
              }
              if (prog) {
                return `
                  ${hhmm}${that.nextMinutesInterval(hhmm.split(":"))}<br>
                  ${that.chartTitle}: <b>${this.y}</b>
                  <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                  <b> ${this.series.name}</b><br>
                  <b>${prog.title}</b><br>
                  ${prog.subtitle}
                `;
              }
              return `
                  ${hhmm}<br>
                  <b> ${this.series.name}</b>: ${this.y}<br>
                  `;
            },
          },
          credits: { enabled: false },
          series:
            this.indicator === "AudienceDemo"
              ? data[i].series.slice(0 + 2 * this.tab, 2 + 2 * this.tab)
              : data[i].series,
        });
      }
      return charts;
    },
    chartOptionsPeriod() {
      let charts = [];
      let data = this.data.charts ?? [];
      let tvLength = data[0]?.series?.length;
      const datesLength = data[0]?.series?.[0]?.data?.length;
      const dataLines = tvLength * datesLength;
      let periodChartType = this.periodChartType;
      let chartDataWidth = null;
      let chartDataHeight = 400;
      if (dataLines > 200) periodChartType = "bar";

      if (this.indicator === "AudienceDemo" && this.isMulti) tvLength = 2;
      chartDataWidth = 60 + tvLength * 40 * datesLength;
      if (chartDataWidth < 350) chartDataWidth = 350;

      if (periodChartType == "bar") {
        chartDataHeight = chartDataWidth;
        if (chartDataHeight < 400) chartDataHeight = 400;
        chartDataWidth = this.periodChartWidth;
      } else {
        if (
          chartDataWidth > this.periodChartWidth &&
          this.periodChartWidth !== null
        ) {
          chartDataWidth = this.periodChartWidth;
        }
      }

      for (let i = 0; i < data.length; i++) {
        let plotBands = [];
        let categories = data[i].categories;
        charts.push({
          id: i,
          chart: {
            type: periodChartType,
            width: chartDataWidth,
            events: {},
            height: chartDataHeight,
          },
          title: { text: "" },
          colors:
            this.indicator === "AudienceDemo"
              ? CONSTS.CHART_DEMO_COLORS
              : CONSTS.CHART_COLORS,

          subtitle: { text: data[i].series[0].subtitle ?? "" },
          exporting: {
            sourceWidth: 1200,
            chartOptions: {
              chart: {
                events: {},
              },
            },
          },
          xAxis: {
            categories: categories,
            type: "datetime",
            plotBands: plotBands,
            labels: {},
          },
          yAxis: {
            reversedStacks: false,
            title: { text: this.chartTitle },
            plotLines: [
              {
                value: 0,
                width: 1,
                color: "#808080",
              },
            ],
          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: true,
              },
            },
            series: {
              states: {
                inactive: {
                  opacity: 1,
                },
              },
              stacking: false,
              dataLabels: { enabled: false },
              pointWidth: 20,
            },
          },
          tooltip: {},
          credits: { enabled: false },
          series:
            this.indicator === "AudienceDemo"
              ? data[i].series.slice(0 + 2 * this.tab, 2 + 2 * this.tab)
              : data[i].series,
        });
      }
      return charts;
    },
    chartTitle() {
      let title = "Показатель";
      switch (this.indicator) {
        case "Watchtime":
          title = "Время просмотра, с";
          break;
        case "UuidCount":
          title = "Уникальные устройства за час, шт.";
          break;
        case "SanCount":
          title = "Уникальные домохозяйства за час, шт.";
          break;
        case "UuidCountDay":
          title = "Уникальные устройства за сутки, шт.";
          break;
        case "SanCountDay":
          title = "Уникальные домохозяйства за сутки, шт.";
          break;
        case "Audience":
          title = "Аудитория, тыс.";
          break;
        case "All":
          title = "Сводный";
          break;
        case "AudienceDemo":
          title = "Аудитория, тыс.";
          break;
        case "TVR":
          title = "Рейтинг";
          break;
        case "TVRTotal":
          title = "Рейтинг общий";
          break;
        case "Share":
          title = "Доля, %";
          break;
        case "Reach":
          title = "Охват, тыс. чел.";
          break;
        case "ReachPercent":
          title = "Охват, %";
          break;
      }
      return title;
    },
  },
  watch: {
    indicator() {
      if (this.indicator === "All") {
        this.toggle_graphType = 1;
      }
    },
    toggle_graphType() {
      this.visionType = this.toggle_graphType === 0 ? "graph" : "table";
    },
  },
};
</script>

<template>
  <v-card elevation="0">
    <v-card-title
      class="subtitle-1 justify-space-between flex-row pt-2 pr-3 pb-0 ml-0 pl-0 mb-2 mb-sm-0"
    >
      Акцент на передачу
      <v-spacer></v-spacer>
      <v-btn
        v-if="selectProgrammId && selectProgrammId.length > 0"
        color="grey lighten-4"
        elevation="0"
        class="mh-20"
        :id="`clearBand_${tabIndex}_${chartIndex}`"
        :ref="`clearBand_${tabIndex}_${chartIndex}`"
        @click="clearBands"
        data-html2canvas-ignore
        >Очистить</v-btn
      >
    </v-card-title>
    <v-container class="">
      <v-row data-html2canvas-ignore class="">
        <v-col class="col-12 col-sm-6 ml-sm-0 pl-sm-0">
          <v-autocomplete
            :filter="filterAutoComplete"
            dense
            v-model="selectTVId"
            outlined
            :label="'телеканал'"
            mandatory
            :items="tvList"
            no-data-text="Ничего не найдено"
            :item-text="isAdmin ? 'ns1_name_admin' : 'ns1_name_ru'"
            item-value="num"
          >
          </v-autocomplete>
        </v-col>
        <v-col class="col-12 col-sm-6 mt-n8 mt-sm-0">
          <mh-drop-down
            :items="shows"
            v-model="selectProgrammId"
            label="телепередача"
            item-text="name"
            item-value="subprogram_id"
            :hide-details="true"
            :multiple="true"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" class="pa-0">
          <div class="noProgsAccent" v-if="!programs.length">
            акцент не назначен
          </div>
          <div class="d-sm-none ml-2 mr-2">
            <v-data-iterator
              :items="programs"
              item-key="id"
              hide-default-footer
              no-data-text=""
              style="width: 100%"
            >
              <template v-slot:default="props">
                <div
                  v-for="item in props.items"
                  :key="item.id"
                  class="accentCell"
                >
                  <div
                    class="accentCellHeader"
                    :style="`background-color: ${getProgBgColor(item)}`"
                  >
                    <v-menu
                      top
                      offset-y
                      :close-on-content-click="closeOnContentClick"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="accentProgColor"
                          v-bind="attrs"
                          v-on="on"
                          :color="item.color"
                          >mdi-palette</v-icon
                        >
                      </template>
                      <v-card>
                        <v-container>
                          <v-row style="width: 194px">
                            <v-col
                              cols="3"
                              class="pl-1 pb-1 pt-1 pr-1"
                              v-for="color in accentColors"
                              :key="color"
                            >
                              <div
                                :class="
                                  'mh-color-circle-outer' +
                                  (item.color === color
                                    ? ' mh-selected-color-circle'
                                    : '')
                                "
                                @click="changeColor(item.id, color)"
                              >
                                <div
                                  class="mh-color-circle-inner"
                                  :style="'background-color:' + color"
                                ></div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-menu>
                    <div class="accentProgChannel">{{ item.channel }}</div>
                    <div class="accentProgCancel">
                      <v-icon
                        small
                        @click="removeBand(item.id)"
                        data-html2canvas-ignore
                        >mdi-close</v-icon
                      >
                    </div>
                  </div>
                  <div class="accentCellContent">
                    {{ item.program }}
                    <br />
                    <span style="opacity: 0.5"
                      >{{ item.start }} - {{ item.stop }}</span
                    >
                  </div>
                </div>
              </template>
            </v-data-iterator>
          </div>
          <div class="d-none d-sm-flex">
            <v-data-table
              :headers="headers"
              :items="programs"
              :height="tableHeight"
              v-if="programs.length"
              no-data-text="акцент не назначен"
              hide-default-header
              disable-pagination
              hide-default-footer
              class="elevation-0 body-2 accentData"
              style="width: 100%"
            >
              <template v-slot:[`item.program`]="{ item }">
                {{ item.program }}<br />
                <span style="opacity: 0.5"
                  >{{ item.start }} - {{ item.stop }}</span
                >
              </template>
              <template v-slot:[`item.remove`]="{ item }">
                <v-icon
                  small
                  @click="removeBand(item.id)"
                  data-html2canvas-ignore
                  >mdi-close</v-icon
                >
              </template>
              <template v-slot:[`item.color`]="{ item }">
                <v-menu
                  top
                  offset-y
                  :close-on-content-click="closeOnContentClick"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" :color="item.color"
                      >mdi-palette</v-icon
                    >
                  </template>
                  <v-card>
                    <v-container>
                      <v-row style="width: 194px">
                        <v-col
                          cols="3"
                          class="pl-1 pb-1 pt-1 pr-1"
                          v-for="color in accentColors"
                          :key="color"
                        >
                          <div
                            :class="
                              'mh-color-circle-outer' +
                              (item.color === color
                                ? ' mh-selected-color-circle'
                                : '')
                            "
                            @click="changeColor(item.id, color)"
                          >
                            <div
                              class="mh-color-circle-inner"
                              :style="'background-color:' + color"
                            ></div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style scoped>
.accentCell {
  width: 100%;
  display: block;
}
.accentCellHeader {
  display: flex;
  color: rgba(0, 0, 0, 0.87);
}
.accentCellContent {
  padding: 10px 16px 10px 16px;
  font-size: 12px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
}
.accentCellContent span {
  font-size: 12px;
}
.accentProgColor {
  margin: 8px 6px 8px 6px;
}
.accentProgChannel {
  margin: 8px 6px 8px 6px;
}
.accentProgCancel {
  margin: 8px 12px 8px auto;
  order: 2;
}
</style>
<script>
import axios from "axios";
import retypeLangRu from "../services/retypeLangRu";
import retypeLangEn from "../services/retypeLangEn";
import { CONSTS } from "../services/constants.js";
import MhDropDown from "@/components/MhTvDropDown.vue";
export default {
  name: "ProgrammAccent",
  components: { MhDropDown },
  data() {
    return {
      closeOnContentClick: true,
      selectTVId: null,
      selectProgrammId: null,
      all_channels: false,
      tvss: [],
      headers: [
        {
          text: "Цвет",
          align: "start",
          value: "color",
        },
        {
          text: "Канал",
          align: "start",
          value: "channel",
          width: "30%",
        },
        {
          text: "Передача",
          align: "start",
          value: "program",
          width: "60%",
        },
        {
          value: "remove",
        },
      ],
    };
  },
  props: {
    tabIndex: {
      type: Number,
      default: 0,
    },
    sourceId: {
      type: Number,
      default: 1,
    },
    chartIndex: {
      type: Number,
      default: 0,
    },
    city: {
      type: String,
      default: "",
    },
    visionType: {
      type: String,
      default: "",
    },
    channelsIds: {
      type: Array,
      default: function () {
        return [];
      },
    },
    progs: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    getProgBgColor(item) {
      console.log(item.color);
      return item.color;
    },
    filterAutoComplete(item, queryText, itemText) {
      queryText = queryText.toLocaleLowerCase();
      let queryTextRu = retypeLangRu(queryText);
      let queryTextEn = retypeLangEn(queryText);
      itemText = itemText.toLocaleLowerCase();
      return (
        itemText.indexOf(queryTextEn) > -1 || itemText.indexOf(queryTextRu) > -1
      );
    },
    changeColor(id, color) {
      let porgIndex = this.progs.findIndex((item) => item.subprogram_id === id);
      if (this.progs[porgIndex]) {
        this.progs[porgIndex].color = color;
      }
      this.$forceUpdate();
      porgIndex = this.shows.findIndex((item) => item.subprogram_id === id);
      if (this.shows[porgIndex]) {
        this.shows[porgIndex].color = color;
      }
      porgIndex = this.programs.findIndex((item) => item.id === id);
      if (this.programs[porgIndex]) {
        this.programs[porgIndex].color = color;
      }
      this.$emit("changeColor", { chartIndex: this.chartIndex, id, color });
    },
    clearBands() {
      this.selectProgrammId = null;
    },
    removeBand(id) {
      this.programs.splice(
        this.programs.findIndex((item) => item.id === id),
        1
      );
      this.selectProgrammId.splice(this.selectProgrammId.indexOf(id), 1);
    },

    async getTvs() {
      this.selectTVId = null;
      let res = await axios.post("/params/tvs", {
        source_id: this.sourceId,
        helper: "mh",
        city_id: this.city,
      });
      if (res.data) {
        this.tvss = res.data;
        this.selectTVId = this.tvList[0].id;
      }
    },
  },
  watch: {
    visionType() {
      this.$emit("programsChange", {
        chartIndex: this.chartIndex,
        tabId: this.tabIndex,
        programs: this.programs,
        tvid: this.selectTVId,
      });
    },
    selectTVId() {
      if (this.selectTVId == "") {
        this.selectTVId = null;
      }
      this.selectProgrammId = null;
      this.$emit("programsChange", {
        chartIndex: this.chartIndex,
        tabId: this.tabIndex,
        programs: this.programs,
        tvid: this.selectTVId,
      });
    },
    programs() {
      this.$emit("programsChange", {
        chartIndex: this.chartIndex,
        tabId: this.tabIndex,
        programs: this.programs,
        tvid: this.selectTVId,
      });
    },
  },
  computed: {
    accentColors() {
      return CONSTS.PROGRAMM_ACCENT_COLORS;
    },
    tableHeight() {
      console.log(this.programs);
      let maxHeight = 250;
      let height = 48;
      if (this.programs.length > 1) {
        height = height * this.programs.length;
      }
      if (height > maxHeight) {
        height = maxHeight;
      }
      return height;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    programs() {
      const items = [];
      if (!this.selectProgrammId) return items;
      for (let i = 0; i < this.selectProgrammId.length; i++) {
        let prog = this.shows.find((sh) => {
          return sh.subprogram_id === this.selectProgrammId[i];
        });
        let chan = this.tvList.find((el) => {
          return prog.tvid == el.id;
        });

        items.push({
          id: prog.subprogram_id,
          color: prog.color,
          channel: this.isAdmin ? chan.ns1_name_admin : chan.ns1_name_ru,
          stitle: prog.stitle,
          program: prog.name.split(" | ")[0],
          start: prog.start_loc_time,
          stop: prog.stop_loc_time,
          startDate: prog.start_loc.substring(0, 10), // дата нужна для сравнения с текущим днем в bands
          stopDate: prog.stop_loc.substring(0, 10),
          remove: "mdi-close",
        });
      }
      return items;
    },
    shows() {
      let items = [];
      this.progs.forEach((el) => {
        items.push({
          subprogram_id: el.subprogram_id,
          name:
            el.title +
            " " +
            (el.subtitle ?? "") +
            " | " +
            el.start_loc_time +
            "-" +
            el.stop_loc_time,
          tvid: el.tvid,
          stitle: el.subtitle ?? "",
          start_loc_time: el.start_loc_time,
          stop_loc_time: el.stop_loc_time,
          start_loc: el.start_loc,
          stop_loc: el.stop_loc,
          color: el.color ?? "rgba(244, 67, 54, 0.5)",
        });
      });
      items.sort();
      if (this.selectTVId) {
        return items.filter((el) => {
          return this.selectTVId.includes(el.tvid);
        });
      }
      return items;
    },
    tvList() {
      return this.tvss.filter((el) => {
        return this.channelsIds?.includes(el.id);
      });
    },
  },
  mounted() {
    this.getTvs();
  },
};
</script>

<style lang="scss">
.noProgsAccent {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.38);
  margin-top: -12px;
}
.mh-20 {
  max-height: 20px;
}
.mh-selected-color-circle {
  border-radius: 50%;
  border: 2px solid #1976d2;
}
.mh-color-circle-inner {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin: 2px;
}
.mh-color-circle-outer {
  cursor: pointer;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
</style>

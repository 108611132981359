const state = {
  filters: {
    selectedSourceId: null,
    selectCityId: null,
    selectedTVId: null,
    selectProgrammId: null,
  },
  calendarStart: null,
  calendarEnd: null,
  cities: [],
  tvs: [],
  programs: [],
  lastCities: [],
  lastTVs: [],
};
const getters = {
  last5cities: (state) => state.lastCities,
  last5tvs: (state) => state.lastTVs,
  getFilters: (state) => state.filters,
  getCitiesList: (state) => state.cities,
  getTvsList: (state) => state.tvs,
  getProgramsList: (state) => state.programs,
  getCalendarStart:(state) => state.calendarStart,
  getCalendarEnd:(state) => state.calendarEnd,
};
const actions = {
  async storeCalendarStart({ commit }, start) {
    await commit('setCalendarStart', start)

  },
  async storeCalendarEnd({ commit }, end) {
    await commit('setCalendarEnd', end);
  },
  async storeFilters({ commit }, filters) {
    let splitSource = [0, "mh"];
    if (filters.selectedSourceId) {
      splitSource = filters.selectedSourceId.split("%");
    }
    let selTVID = 0;
    if (filters.selectedTVId) {
      if (Array.isArray(filters.selectedTVId)) {
        selTVID = filters.selectedTVId.join(",");
      } else selTVID = filters.selectedTVId;
    }
    let settings = {};
    settings["selected_source_id"] = splitSource[0];
    settings["selected_helper_type"] = splitSource[1];
    settings["selected_geo_id"] = filters.selectCityId ?? 0;
    settings["selected_tv_id"] = selTVID;
    await commit("setFilters", filters);
    await commit("setSettings", settings);
  },
  async storeCities({ commit }, cities) {
    await commit("setCitiesList", cities);
  },
  async storeTvs({ commit }, tvs) {
    await commit("setTvsList", tvs);
  },
  async storePrograms({ commit }, programs) {
    await commit("setProgramsList", programs);
  },
  async storeLastTvs({ commit }, tvs) {
    let settingsTV = {};
    settingsTV["last5_tv_id"] = Array.isArray(tvs) ? tvs.join(",") : tvs;
    await commit("setSettings", settingsTV);
    await commit("setLastTvs", tvs);
  },
  async storeLastCities({ commit }, cities) {
    let settingsCity = {};
    settingsCity["last5_city_id"] = Array.isArray(cities)
      ? cities.join(",")
      : cities;
    await commit("setSettings", settingsCity);
    await commit("setLastCities", cities);
  },
};
const mutations = {
  setCalendarStart(state, start) {
    state.calendarStart=start;
  },
  setCalendarEnd (state, end) {
    state.calendarEnd=end;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setCitiesList(state, cities) {
    state.cities = cities;
  },
  setTvsList(state, tvs) {
    state.tvs = tvs;
  },
  setLastCities(state, cities) {
    state.lastCities = cities;
  },
  setLastTvs(state, tvs) {
    state.lastTVs = tvs;
  },
  setProgramsList(state, programs) {
    state.programs = programs;
  },
  clearFilters(state) {
    state.filters = {
      selectedSourceId: null,
      selectCityId: null,
      selectedTVId: null,
      selectProgrammId: null,
    };
    state.cities = [];
    state.tvs = [];
    state.programs = [];
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-container :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'">
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col">
        <main-filters
          :reportRequest="reportRequest"
          :page="pageData"
          :aggregateVisible="true"
          :loading="filter_loading"
          :show-hourly="false"
          @cubeDataClick="cubeData"
          @graphType="graphTypeClick"
          @calendarRange="(val) => (this.range = val)"
          @cities="(val) => (this.cities = val)"
          @tvList="(val) => (this.channels = val)"
          @shows="(val) => (this.shows = val)"
          @sources="(val) => (this.sources = val)"
          @aggregations="(val) => (this.aggregations = val)"
          @stopCalc="stopCube"
        >
        </main-filters>
        <v-card
          outlined
          class="mt-2"
          v-if="chart_loading === false"
          id="highcharts_png"
        >
          <v-card-title
            class="text-lg-h5 text-md-h6 justify-space-between pb-0 flex-row"
          >
            <v-col
              class="col-12 col-md-4 text-center text-md-left ml-0 pl-0 pb-0"
              style="word-break: break-word"
            >
              {{ chartTitle }}
            </v-col>
            <v-col
              class="col-12 col-md-8 text-center text-md-right"
              id="chartControls"
              data-html2canvas-ignore
            >
              <v-menu
                offset-y
                open-on-hover
                max-width="300"
                v-if="
                  report !== 'hour' &&
                  visionType === 'graph' &&
                  selectedChannelsIds[0] != 'null'
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    elevation="0"
                    outlined
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    :class="isMobile ? 'mr-2 upTo5' : 'mr-2 upTo2'"
                    style="
                      border-radius: 4px !important;
                      height: 36px;
                      width: 36px;
                    "
                    @click="rotateTvshowsChart"
                    ><v-icon>mdi-format-rotate-90</v-icon></v-btn
                  >
                </template>
                <v-card>
                  <v-card-text>{{ rotateButtonMsg }}</v-card-text>
                </v-card>
              </v-menu>
              <v-btn-toggle
                borderless
                v-model="toggle_graphType"
                mandatory
                dense
              >
                <v-btn
                  elevation="0"
                  class="mh-noactive-toggle"
                  :active-class="'white black--text mh-active-toggle'"
                  :disabled="
                    this.indicator === 'All' || this.aggregate !== 'None'
                  "
                >
                  <v-icon
                    dense
                    :color="visionType == 'graph' ? 'purple darken-2' : ''"
                    class="pr-1"
                    >mdi-chart-line</v-icon
                  >График
                </v-btn>
                <v-btn
                  elevation="0"
                  class="mh-noactive-toggle"
                  :active-class="'white black--text mh-active-toggle'"
                >
                  <v-icon
                    dense
                    :color="visionType != 'graph' ? 'green darken-4' : ''"
                    class="pr-1"
                    >mdi-table</v-icon
                  >Таблица
                </v-btn>
              </v-btn-toggle>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="secondary"
                    elevation="0"
                    class="mh-secondary-border ml-2"
                    :class="
                      isMobile
                        ? 'mh-secondary-border ml-2 upTo5'
                        : 'mh-secondary-border ml-2 upTo2'
                    "
                    style="text-transform: none !important"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-download</v-icon>Скачать</v-btn
                  >
                </template>
                <v-list dense>
                  <v-list-item dense link @click="toExcel" v-if="!isAlternate">
                    <v-list-item-title>Таблица (xlsx)</v-list-item-title>
                  </v-list-item>
                  <v-list-item dense link @click="saveChartToPng">
                    <v-list-item-title>Изображение (png)</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-col class="ml-0 pl-0">
              <div class="chartTitle">{{ subtitle }}</div>
              <v-btn
                @click="saveChartToPng()"
                v-if="false && visionType === 'graph'"
                class="mb-3"
                >PNG</v-btn
              >
              <div id="highchartDiv">
                <div v-for="chart in chartOptions" :key="chart.id">
                  <div class="chartSubtitle">
                    {{ chart.series[0].subtitle }}
                  </div>
                  <highcharts
                    v-if="chart.series[0].data.length && visionType === 'graph'"
                    :options="chart"
                  ></highcharts>
                </div>
              </div>
              <v-row align="center" data-html2canvas-ignore>
                <v-text-field
                  v-if="visionType !== 'graph'"
                  v-model="tableSearch"
                  label="Поиск по таблице"
                  class="mx-4"
                ></v-text-field>
                <v-tooltip bottom v-if="visionType !== 'graph'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="rounded grey lighten-4"
                      active-class="rounded"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      v-if="visionType !== 'graph'"
                      @click="copyToClipboard('dt-table')"
                      ><v-icon class="rounded">mdi-content-copy</v-icon></v-btn
                    >
                  </template>
                  <span>Скопировать в буфер</span>
                </v-tooltip>

                <v-snackbar
                  v-model="snackbarCopy"
                  :timeout="1000"
                  right
                  color="success"
                  class="mb-16"
                >
                  Данные скопированы
                </v-snackbar>
              </v-row>
              <v-data-table
                id="dt-table"
                :sort-by="indicator == 'All' ? 'aud' : 'y'"
                :sort-desc="true"
                v-if="visionType !== 'graph'"
                :headers="tableHeaders"
                :items="tableItems()"
                :hide-default-footer="true"
                multi-sort
                :search="tableSearch"
                disable-pagination
              >
                <template v-slot:item.y="{ item }">
                  <span>{{ makeFixed(item.y, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y1="{ item }">
                  <span>{{ makeFixed(item.y1, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y2="{ item }">
                  <span>{{ makeFixed(item.y2, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y3="{ item }">
                  <span>{{ makeFixed(item.y3, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y4="{ item }">
                  <span>{{ makeFixed(item.y4, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.y5="{ item }">
                  <span>{{ makeFixed(item.y5, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.aud="{ item }">
                  <span>{{ makeFixed(item.aud, 3).replace(/\./g, ",") }}</span>
                </template>
                <template v-slot:item.share="{ item }">
                  <span>{{
                    makeFixed(item.share, 3, true).replace(/\./g, ",")
                  }}</span>
                </template>
                <template v-slot:item.tvr="{ item }">
                  <span>{{
                    makeFixed(item.tvr, 3, true).replace(/\./g, ",")
                  }}</span>
                </template>
                <template v-slot:item.reach="{ item }">
                  <span>{{
                    makeFixed(item.reach, 3).replace(/\./g, ",")
                  }}</span>
                </template>
                <template v-slot:item.reachPercent="{ item }">
                  <span>{{
                    makeFixed(item.reachPercent, 3, true).replace(/\./g, ",")
                  }}</span>
                </template>
              </v-data-table>
            </v-col>
            <v-row justify="center" v-if="chart_loading === true">
              <v-col justify="center"
                ><v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular></v-col
            ></v-row>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mt-2"
          height="400"
          v-else-if="chart_loading === true"
        >
          <base-loader></base-loader>
        </v-card>
        <v-row
          v-else
          justify="center"
          class="mt-12"
          style="margin-left: 0; margin-right: 0"
        >
          <pre-report-mount />
        </v-row>
      </v-col>
    </v-row>

    <script
      defer
      type="application/javascript"
      :src="
        'https://js.onef.pro/static/reg1f_v1.js?1f_pixel_id=bb38ba5b-71c5-463f-b819-bf5757feaad3&event_type=visit&product=ShowsReport&order_id=' +
        check_cookie_name('_ym_uid')
      "
    ></script>

    <preset-drawer report="tvshows" v-if="!$route.params.uuid"></preset-drawer>
  </v-container>
</template>
<style lang="scss"></style>
<script>
import MainFilters from "@/components/MainFilters.vue";
import axios from "axios";
import { Chart } from "highcharts-vue";
import moment from "moment";
import html2canvas from "html2canvas";
import uuidv4 from "uuid/v4";
import BaseLoader from "@/components/BaseLoader.vue";
import PresetDrawer from "@/components/PresetDrawer.vue";
import { CONSTS } from "../services/constants.js";
import PreReportMount from "@/components/PreReportMount.vue";
import Utils from "../services/utils";

export default {
  name: "TvShows",
  components: {
    BaseLoader,
    MainFilters,
    highcharts: Chart,
    PresetDrawer,
    PreReportMount,
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    if (!this.hasPriv) return this.$router.push("/");
    window.addEventListener("resize", this.handleResize, true);
    if (this.$route.params.uuid) {
      this.chart_loading = true;
      this.chartWithUuid();
    }
  },
  methods: {
    handleResize() {
      const periodChartWidth =
        document.getElementById("highchartDiv")?.clientWidth;
      this.barLabelWidth = periodChartWidth - 100;
    },
    isMonday(date) {
      return moment(date, "YYYY-MM-DD").day() == 1;
    },
    dateToMonday(date) {
      return moment(date, "YYYY-MM-DD").startOf("isoWeek").format("YYYY-MM-DD");
    },
    dateToMonth(date) {
      return moment(date, "YYYY-MM-DD").lang("ru").format("MMMM YY");
    },
    isFirstDayofMonth(date) {
      return moment(date, "YYYY-MM-DD").format("DD") == "01";
    },
    rotateTvshowsChart() {
      if (this.totalProgs >= this.maxBarProgs) return;
      if (this.tvshowsChartType == "bar") {
        this.tvshowsChartType = "column";
      } else {
        this.tvshowsChartType = "bar";
      }
    },
    async chartWithUuid() {
      if (!this.$route.params.uuid) return;
      this.chart_loading = true;
      let res = await axios
        .post(`result/${this.$route.params.uuid}`)
        .catch(function () {
          this.chart_loading = null;
          this.isStoped = true;
          alert("Произошла ошибка");
        });
      if (res.data.message == "not ready") {
        this.reportRequest = res.data.request;
        setTimeout(() => {
          if (!this.isStoped) this.chartWithUuid();
        }, 1000);
        return;
      }

      if (this.isStoped) return;

      this.filter_loading = false;
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      this.reportUuid = this.$route.params.uuid;
      this.reportRequest = res.data.request;
      console.log(this.reportRequest);
      this.report = res.data.request["report"];
      this.selectedCityId = res.data.request["geo"];
      this.selectedChannelsIds = res.data.request["tv"]?.split(",");
      this.selectedShowsIds = res.data.request["programs"]?.split(",");
      this.selectedSourceId = res.data.request["source_id"];
      this.helper = res.data.request["helper"];
      this.coef = res.data.request["useCoeff"];
      this.indicator = res.data.request["indicator"];
      this.aggregate = res.data.request["aggregate"];
      this.range.through = res.data.request["through"] == true;
      this.range.weekdays = res.data.request["weekdays"].split(",");
      this.range.start = `${res.data.request.startDate}T${res.data.request.startTime}:00.000`;
      this.range.end = `${res.data.request.endDate}T${res.data.request.endTime}:00.000`;
      res.data = res.data.result;
      //console.log(this.reportRequest.programs_obj)
      //console.log(this.selectedShowsIds)
      //this.shows = this.reportRequest.programs_obj?.filter(item => !this.selectedShowsIds.includes(item.program_id));
      this.shows = this.reportRequest.programs_obj;
      if (this.aggregate !== "None") {
        this.toggle_graphType = 1;
        let programs = [];
        let categories = [];
        let programmsIds = [];
        for (let i = 0; i < res.data.charts[0].series[0].data.length; i++) {
          let prog_id = res.data.charts[0].series[0].data[i].prog.program_id;
          let prog_title = res.data.charts[0].series[0].data[i].prog.title;

          switch (this.aggregate) {
            case "ProgramSubprogram":
              prog_id +=
                "_" + res.data.charts[0].series[0].data[i].prog.subtitle ?? 0;

              break;
            case "ProgramTime":
              prog_id +=
                "_" +
                  res.data.charts[0].series[0].data[
                    i
                  ].prog.start_loc_time.replace(/:/g, "") ?? 0;
              break;
            case "ProgramSubprogramTime":
              prog_id +=
                "_" + res.data.charts[0].series[0].data[i].prog.subtitle ??
                0 +
                  "_" +
                  res.data.charts[0].series[0].data[
                    i
                  ].prog.start_loc_time.replace(/:/g, "") ??
                0;
              break;
          }

          if (programs[prog_id]) {
            programs[prog_id].count++;
            programs[prog_id].y += res.data.charts[0].series[0].data[i].y ?? 0;
            programs[prog_id].y2 += res.data.charts[0].series[1]
              ? res.data.charts[0].series[1].data[i].y
              : 0;

            programs[prog_id].aud +=
              res.data.charts[0].series[0].data[i].aud ?? 0;
            programs[prog_id].tvr +=
              res.data.charts[0].series[0].data[i].tvr ?? 0;
            programs[prog_id].share +=
              res.data.charts[0].series[0].data[i].share ?? 0;
            programs[prog_id].reach +=
              res.data.charts[0].series[0].data[i].reach ?? 0;
            programs[prog_id].reachPercent +=
              res.data.charts[0].series[0].data[i].reachPercent ?? 0;
          } else {
            programmsIds.push(prog_id);
            categories.push(`${prog_title} |  | `);
            programs[prog_id] = {
              y: res.data.charts[0].series[0].data[i].y,
              y2: res.data.charts[0].series[1]
                ? res.data.charts[0].series[1].data[i].y
                : 0,
              aud: res.data.charts[0].series[0].data[i].aud ?? 0,
              tvr: res.data.charts[0].series[0].data[i].tvr ?? 0,
              reach: res.data.charts[0].series[0].data[i].reach ?? 0,
              reachPercent:
                res.data.charts[0].series[0].data[i].reachPercent ?? 0,
              share: res.data.charts[0].series[0].data[i].share ?? 0,
              title: res.data.charts[0].series[0].data[i].prog.title ?? "",
              sub: res.data.charts[0].series[0].data[i].prog.subtitle ?? "",
              start: res.data.charts[0].series[0].data[i].prog.start_loc_time,
              count: 1,
            };
          }
        }
        let clearProgs = [];

        for (let ii = 0; ii < programmsIds.length; ii++) {
          programs[programmsIds[ii]].y =
            Math.round(
              (programs[programmsIds[ii]].y /
                programs[programmsIds[ii]].count) *
                1000
            ) / 1000;
          programs[programmsIds[ii]].y2 =
            Math.round(
              (programs[programmsIds[ii]].y2 /
                programs[programmsIds[ii]].count) *
                1000
            ) / 1000;
          programs[programmsIds[ii]].aud =
            Math.round(
              (programs[programmsIds[ii]].aud /
                programs[programmsIds[ii]].count) *
                1000
            ) / 1000;
          programs[programmsIds[ii]].tvr =
            Math.round(
              (programs[programmsIds[ii]].tvr /
                programs[programmsIds[ii]].count) *
                1000
            ) / 1000;
          programs[programmsIds[ii]].share =
            Math.round(
              (programs[programmsIds[ii]].share /
                programs[programmsIds[ii]].count) *
                1000
            ) / 1000;
          programs[programmsIds[ii]].reach =
            Math.round(
              (programs[programmsIds[ii]].reach /
                programs[programmsIds[ii]].count) *
                1000
            ) / 1000;
          programs[programmsIds[ii]].reachPercent =
            Math.round(
              (programs[programmsIds[ii]].reachPercent /
                programs[programmsIds[ii]].count) *
                1000
            ) / 1000;
          clearProgs.push(programs[programmsIds[ii]]);
        }
        res.data.charts[0].series[0].data = clearProgs;
        res.data.charts[0].categories = categories;
      }

      this.data = res.data;
      if (this.totalProgs >= this.maxBarProgs) {
        this.tvshowsChartType = "column";
      }
      this.data.load = true;
      this.chart_loading = false;
      setTimeout(() => window.dispatchEvent(new Event("resize")));
    },
    exportBtnClick(e) {
      switch (e) {
        case "png":
          this.saveChartToPng();
          break;
        case "svg":
          break;

        case "pdf":
          break;

        default:
          //xls
          this.toExcel();
          break;
      }
    },
    makeFixed(val, digits, expandable = false) {
      if (
        this.indicator === "TVR" ||
        this.indicator === "TVRTotal" ||
        this.indicator === "Share" ||
        this.indicator === "ReachPercent"
      ) {
        expandable = true;
      }
      if (expandable) {
        if (val < 0.005 && digits < 6) {
          digits = 6;
        }
      }
      return val.toFixed(digits);
    },
    aggregateItems() {
      let items = [];

      for (let j = 0; j < this.chartOptions.length; j++) {
        let chart = this.chartOptions[j];
        let length = chart.xAxis.bulkCategories.length;
        for (let i = 0; i < length; i++) {
          items.push({
            num: j * length + (i + 1),
            title: chart.series[0].data[i].title, //chart.xAxis.categories[i],
            sub: chart.series[0].data[i].sub,
            start: chart.series[0].data[i].start,
            count: chart.series[0].data[i].count,
            aud: chart.series[0].data[i].aud,
            share: chart.series[0].data[i].share,
            tvr: chart.series[0].data[i].tvr,
            reach: chart.series[0].data[i].reach,
            reachPercent: chart.series[0].data[i].reachPercent,
            y: chart.series[0].data[i].y, //.toString().replace(/\./g, ","),
            y2: chart.series[0].data[i].y2, //.toString().replace(/\./g, ","),
          });
        }
      }
      return items;
    },
    aggregateItemsShow() {
      let items = [];

      for (let j = 0; j < this.chartOptions.length; j++) {
        let chart = this.chartOptions[j];
        let length = chart.bulkCategories.length;
        for (let i = 0; i < length; i++) {
          items.push({
            num: j * length + (i + 1),
            title: chart.series[0].data[i].title, //chart.xAxis.categories[i],
            sub: chart.series[0].data[i].sub,
            start: chart.series[0].data[i].start,
            count: chart.series[0].data[i].count,
            aud: chart.series[0].data[i].aud.toString().replace(/\./g, ","),
            share: chart.series[0].data[i].share.toString().replace(/\./g, ","),
            tvr: chart.series[0].data[i].tvr.toString().replace(/\./g, ","),
            reach: chart.series[0].data[i].reach.toString().replace(/\./g, ","),
            reachPercent: chart.series[0].data[i].reachPercent
              .toString()
              .replace(/\./g, ","),
            y: chart.series[0].data[i].y.toString().replace(/\./g, ","),
            y2: chart.series[0].data[i].y2.toString().replace(/\./g, ","),
          });
        }
      }
      return items;
    },
    async toExcel() {
      const reportName = "Tvshows";
      const start = moment(this.range.start).locale("ru").format("DD.MM.YYYY");
      const end = moment(this.range.end).locale("ru").format("DD.MM.YYYY");
      const startTime = moment(this.range.start).locale("ru").format("HH:mm");
      const endTime = moment(this.range.end).locale("ru").format("HH:mm");

      const city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });

      const source = this.sources.find((el) => {
        return el.id === this.selectedSourceId;
      });

      const channels = this.channels.filter((el) => {
        return this.selectedChannelsIds.includes(el.id);
      });

      const shows = this.shows?.filter((el) => {
        return this.selectedShowsIds.includes(el.id);
      });
      //console.log(this.shows)
      // if (!shows) shows = this.selectedShowsIds;

      let aggregate = this.aggregations?.find((el) => {
        return el.id === this.aggregate;
      });
      if (!aggregate)
        aggregate = {
          name: Utils.aggregationName(this.aggregate, this.report),
        };

      const params = [
        "Id отчета: " + this.reportUuid,
        "Время: " + this.reportTime,
        "Период: " + `${start} ${startTime} - ${end} ${endTime}`,
        "Дни недели: " + this.range.weekdays.sort(),
        "Вычисление периода: " + this.range.through,
        "Источник: " + source.name,
        "Справочник: " + this.helper,
        "Коэффициенты: " + this.coef,
        "Агрегация: " + aggregate.name,
        "Регион: " + city.name,
      ];

      if (channels.length > 1) {
        params.push(
          `Телеканалы: ${channels.map((item) => item.export_name).join(", ")}`
        );
      } else {
        if (channels.length === 1)
          params.push(`Телеканал: ${channels[0].export_name}`);
      }
      if (shows.length > 1) {
        params.push(
          `Телепередачи: ${shows.map((item) => item.name).join(", ")}`
        );
      } else {
        if (shows.length === 1) params.push(`Телепередача: ${shows[0].name}`);
      }
      let headers = [];

      for (let i = 0; i < this.tableHeaders.length; i++) {
        headers.push({ name: this.tableHeaders[i].text });
      }
      let tableItems = this.tableItems();

      if (this.aggregate === "None") {
        let deleteDate = !(this.report === "hour"
          ? this.aggregate === "Day"
          : false);
        for (let i = 0; i < tableItems.length; i++) {
          if (deleteDate) {
            delete tableItems[i].date;
          }
        }
      } else {
        let noDeleteY2 = this.indicator === "AudienceDemo";
        let noDeleteSub =
          this.aggregate === "ProgramSubprogram" ||
          this.aggregate === "ProgramSubprogramTime";
        let noDeleteStart =
          this.aggregate === "ProgramTime" ||
          this.aggregate === "ProgramSubprogramTime";
        let deleteY = this.indicator === "All";

        for (let i = 0; i < tableItems.length; i++) {
          if (!noDeleteY2) {
            delete tableItems[i].y2;
          }
          if (!noDeleteSub) {
            delete tableItems[i].sub;
          }
          if (!noDeleteStart) {
            delete tableItems[i].start;
          }
          if (deleteY) {
            delete tableItems[i].y;
          } else {
            delete tableItems[i].aud;
            delete tableItems[i].share;
            delete tableItems[i].tvr;
            delete tableItems[i].reach;
            delete tableItems[i].reachPercent;
          }
        }
      }

      const req = {
        jsonArray: [[...headers], ...tableItems],
        params,
        reportName: reportName,
        indicator: this.indicator,
      };
      const response = await axios.post("jsonToExcel", req, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${this.filename}.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
    async saveChartToPng() {
      const canvas = await html2canvas(
        document.querySelector(`#highcharts_png`)
      );
      Utils.saveAs(canvas.toDataURL(), `${this.filename}.png`);
    },
    stopCube() {
      this.chart_loading = null;
      this.isStoped = true;
      this.axiosCancel();
    },
    copyToClipboard(containerid) {
      var range = document.createRange();
      let containerNode = document.getElementById(containerid); //// this part
      range.selectNode(containerNode); //// this part
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      this.snackbarCopy = true;
    },
    graphTypeClick(isGraph) {
      this.visionType = isGraph ? "graph" : "table";
    },
    tableItems() {
      let items = [];
      if (this.aggregate !== "None") {
        return this.aggregateItems();
      }
      for (let j = 0; j < this.chartOptions.length; j++) {
        let chart = this.chartOptions[j];
        let length = chart.xAxis.bulkCategories.length;

        if (this.indicator === "AudienceDemo") {
          for (let i = 0; i < length; i++) {
            let el = chart.xAxis.bulkCategories[i].split(" | ");
            let subtitle = "";
            let title = "";
            if (el.length > 2) {
              title = el[0];
              subtitle = el[1];
              el = el[2].split(" - ");
            } else {
              title = el[0];
              el = el[1].split(" - ");
            }
            let begin = moment(el[0]);
            let end = moment(el[1]);

            items.push({
              num: j * length + (i + 1),
              title: title, //chart.xAxis.categories[i],
              sub: subtitle,
              beginDate: begin.format("DD.MM.YYYY"),
              beginTime: begin.format("HH:mm"),
              endDate: end.format("DD.MM.YYYY"),
              endTime: end.format("HH:mm"),
              y: chart.series[0].data[i].y, //.toString().replace(/\./g, ","),
              y2: chart.series[1].data[i].y, //.toString().replace(/\./g, ","),
            });
          }
        } else {
          if (this.indicator === "All") {
            for (let i = 0; i < length; i++) {
              let el = chart.xAxis.bulkCategories[i].split(" | ");
              let subtitle = "";
              let title = "";
              if (el.length > 2) {
                title = el[0];
                subtitle = el[1];
                el = el[2].split(" - ");
              } else {
                title = el[0];
                el = el[1].split(" - ");
              }
              let begin = moment(el[0]);
              let end = moment(el[1]);
              items.push({
                num: j * length + (i + 1),
                title: title, //chart.xAxis.categories[i],
                sub: subtitle,
                beginDate: begin.format("DD.MM.YYYY"),
                beginTime: begin.format("HH:mm"),
                endDate: end.format("DD.MM.YYYY"),
                endTime: end.format("HH:mm"),
                aud: chart.series[0].data[i].aud,
                // .toString()
                // .replace(/\./g, ","),
                share: chart.series[0].data[i].share,
                // .toString()
                // .replace(/\./g, ","),
                tvr: chart.series[0].data[i].tvr,
                reach: chart.series[0].data[i].reach,
                reachPercent: chart.series[0].data[i].reachPercent,
                // .toString()
                // .replace(/\./g, ","),
              });
            }
          } else {
            for (let i = 0; i < length; i++) {
              let el = chart.xAxis.bulkCategories[i].split(" | ");
              let subtitle = "";
              let title = "";
              if (el.length > 2) {
                title = el[0];
                subtitle = el[1];
                el = el[2].split(" - ");
              } else {
                title = el[0];
                el = el[1].split(" - ");
              }
              let begin = moment(el[0]);
              let end = moment(el[1]);
              items.push({
                num: j * length + (i + 1),
                title: title, //chart.xAxis.categories[i],
                sub: subtitle,
                beginDate: begin.format("DD.MM.YYYY"),
                beginTime: begin.format("HH:mm"),
                endDate: end.format("DD.MM.YYYY"),
                endTime: end.format("HH:mm"),
                y: chart.series[0].data[i].y, //.toString().replace(/\./g, ","),
              });
            }
          }
        }
      }
      return items;
    },
    tableItemsShow() {
      let items = [];
      if (this.aggregate !== "None") {
        return this.aggregateItemsShow();
      }
      for (let j = 0; j < this.chartOptions.length; j++) {
        let chart = this.chartOptions[j];
        let length = chart.bulkCategories.length;

        if (this.indicator === "AudienceDemo") {
          for (let i = 0; i < length; i++) {
            let el = chart.xAxis.bulkCategories[i].split(" | ");
            let subtitle = "";
            let title = "";
            if (el.length > 2) {
              title = el[0];
              subtitle = el[1];
              el = el[2].split(" - ");
            } else {
              title = el[0];
              el = el[1].split(" - ");
            }
            let begin = moment(el[0]);
            let end = moment(el[1]);

            items.push({
              num: j * length + (i + 1),
              title: title, //chart.xAxis.categories[i],
              sub: subtitle,
              beginDate: begin.format("DD.MM.YYYY"),
              beginTime: begin.format("HH:mm"),
              endDate: end.format("DD.MM.YYYY"),
              endTime: end.format("HH:mm"),
              y: chart.series[0].data[i].y.toString().replace(/\./g, ","),
              y2: chart.series[1].data[i].y.toString().replace(/\./g, ","),
            });
          }
        } else {
          if (this.indicator === "All") {
            for (let i = 0; i < length; i++) {
              let el = chart.xAxis.bulkCategories[i].split(" | ");
              let subtitle = "";
              let title = "";
              if (el.length > 2) {
                title = el[0];
                subtitle = el[1];
                el = el[2].split(" - ");
              } else {
                title = el[0];
                el = el[1].split(" - ");
              }
              let begin = moment(el[0]);
              let end = moment(el[1]);
              items.push({
                num: j * length + (i + 1),
                title: title, //chart.xAxis.categories[i],
                sub: subtitle,
                beginDate: begin.format("DD.MM.YYYY"),
                beginTime: begin.format("HH:mm"),
                endDate: end.format("DD.MM.YYYY"),
                endTime: end.format("HH:mm"),
                aud: chart.series[0].data[i].aud.toString().replace(/\./g, ","),
                share: chart.series[0].data[i].share
                  .toString()
                  .replace(/\./g, ","),
                tvr: chart.series[0].data[i].tvr.toString().replace(/\./g, ","),
                reach: chart.series[0].data[i].reach
                  .toString()
                  .replace(/\./g, ","),
                reachPercent: chart.series[0].data[i].reachPercent
                  .toString()
                  .replace(/\./g, ","),
              });
            }
          } else {
            for (let i = 0; i < length; i++) {
              let el = chart.bulkCategories[i].split(" | ");
              let subtitle = "";
              let title = "";
              if (el.length > 2) {
                title = el[0];
                subtitle = el[1];
                el = el[2].split(" - ");
              } else {
                title = el[0];
                el = el[1].split(" - ");
              }
              let begin = moment(el[0]);
              let end = moment(el[1]);
              items.push({
                num: j * length + (i + 1),
                title: title, //chart.xAxis.categories[i],
                sub: subtitle,
                beginDate: begin.format("DD.MM.YYYY"),
                beginTime: begin.format("HH:mm"),
                endDate: end.format("DD.MM.YYYY"),
                endTime: end.format("HH:mm"),
                y: chart.series[0].data[i].y.toString().replace(/\./g, ","),
              });
            }
          }
        }
      }
      return items;
    },

    async cubeData(req) {
      // this.filter_loading = true;
      this.chart_loading = true;
      this.isStoped = false;
      this.reportUuid = uuidv4();
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");

      let cubeObj = {};
      req.set("requestedReport", this.reportName);
      req.set("uuid", this.reportUuid);
      req.forEach(function (value, key) {
        cubeObj[key] = value;
      });
      cubeObj["email"] = this.$store.getters.StateUser.email;

      cubeObj["action"] = "MakeReport";
      // eslint-disable-next-line no-undef
      // eslint-disable-next-line no-undef
      let reportUniqid = Utils.generateUID();
      cubeObj["uniqid"] = reportUniqid;
      cubeObj["uid"] = this.$store.getters.StateUser.uid;
      cubeObj["ym_uid"] = this.check_cookie_name("_ym_uid");
      // eslint-disable-next-line no-undef
      if (process.env.VUE_APP_ALTERNATE_UI != "true") mh_px(300, cubeObj);
      // eslint-disable-next-line no-unused-vars
      let timeReq = performance.now();
      const CancelToken = axios.CancelToken;
      let that = this;
      let res = await axios
        .post("reports/cubedata", req, {
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            that.axiosCancel = c;
          }),
        })
        .catch(function () {
          // this.filter_loading = false;
          this.chart_loading = null;
          alert("Произошла ошибка");
          return;
        });
      if (res.data.message === "The report has been queued") {
        this.reportRequest = res.data.request;
        // window.location.href = this.$route.path + "/" + res.data.uuid;
        this.$router.push(this.$route.path + "/" + res.data.uuid);
        this.chartWithUuid();
        return;
      }
      if (!this.isStoped) {
        // this.filter_loading = false;
        this.report = req.get("report_type");
        this.selectedCityId = req.get("geo");
        this.selectedChannelsIds = req.get("tv")?.split(",");
        this.selectedShowsIds = req.get("programIds")?.split(",");
        this.selectedSourceId = req.get("source_id");
        this.aggregateId = req.get("aggregate");
        this.helper = req.get("helper");
        this.coef = req.get("useCoeff");

        this.aggregate = req.get("aggregate");
        if (this.aggregate !== "None") {
          this.toggle_graphType = 1;
          let programs = [];
          let categories = [];
          let programmsIds = [];
          for (let i = 0; i < res.data.charts[0].series[0].data.length; i++) {
            let prog_id = res.data.charts[0].series[0].data[i].prog.program_id;
            let prog_title = res.data.charts[0].series[0].data[i].prog.title;

            switch (this.aggregate) {
              case "ProgramSubprogram":
                prog_id +=
                  "_" + res.data.charts[0].series[0].data[i].prog.subtitle ?? 0;

                break;
              case "ProgramTime":
                prog_id +=
                  "_" +
                    res.data.charts[0].series[0].data[
                      i
                    ].prog.start_loc_time.replace(/:/g, "") ?? 0;
                break;
              case "ProgramSubprogramTime":
                prog_id +=
                  "_" + res.data.charts[0].series[0].data[i].prog.subtitle ??
                  0 +
                    "_" +
                    res.data.charts[0].series[0].data[
                      i
                    ].prog.start_loc_time.replace(/:/g, "") ??
                  0;
                break;
            }

            if (programs[prog_id]) {
              programs[prog_id].count++;
              programs[prog_id].y +=
                res.data.charts[0].series[0].data[i].y ?? 0;
              programs[prog_id].y2 += res.data.charts[0].series[1]
                ? res.data.charts[0].series[1].data[i].y
                : 0;

              programs[prog_id].aud +=
                res.data.charts[0].series[0].data[i].aud ?? 0;
              programs[prog_id].tvr +=
                res.data.charts[0].series[0].data[i].tvr ?? 0;
              programs[prog_id].share +=
                res.data.charts[0].series[0].data[i].share ?? 0;
              programs[prog_id].reach +=
                res.data.charts[0].series[0].data[i].reach ?? 0;
              programs[prog_id].reachPercent +=
                res.data.charts[0].series[0].data[i].reachPercent ?? 0;
            } else {
              programmsIds.push(prog_id);
              categories.push(`${prog_title} |  | `);
              programs[prog_id] = {
                y: res.data.charts[0].series[0].data[i].y,
                y2: res.data.charts[0].series[1]
                  ? res.data.charts[0].series[1].data[i].y
                  : 0,
                aud: res.data.charts[0].series[0].data[i].aud ?? 0,
                tvr: res.data.charts[0].series[0].data[i].tvr ?? 0,
                reach: res.data.charts[0].series[0].data[i].reach ?? 0,
                reachPercent:
                  res.data.charts[0].series[0].data[i].reachPercent ?? 0,
                share: res.data.charts[0].series[0].data[i].share ?? 0,
                title: res.data.charts[0].series[0].data[i].prog.title ?? "",
                sub: res.data.charts[0].series[0].data[i].prog.subtitle ?? "",
                start: res.data.charts[0].series[0].data[i].prog.start_loc_time,
                count: 1,
              };
            }
          }
          let clearProgs = [];

          for (let ii = 0; ii < programmsIds.length; ii++) {
            programs[programmsIds[ii]].y =
              Math.round(
                (programs[programmsIds[ii]].y /
                  programs[programmsIds[ii]].count) *
                  1000
              ) / 1000;
            programs[programmsIds[ii]].y2 =
              Math.round(
                (programs[programmsIds[ii]].y2 /
                  programs[programmsIds[ii]].count) *
                  1000
              ) / 1000;
            programs[programmsIds[ii]].aud =
              Math.round(
                (programs[programmsIds[ii]].aud /
                  programs[programmsIds[ii]].count) *
                  1000
              ) / 1000;
            programs[programmsIds[ii]].tvr =
              Math.round(
                (programs[programmsIds[ii]].tvr /
                  programs[programmsIds[ii]].count) *
                  1000
              ) / 1000;
            programs[programmsIds[ii]].share =
              Math.round(
                (programs[programmsIds[ii]].share /
                  programs[programmsIds[ii]].count) *
                  1000
              ) / 1000;
            programs[programmsIds[ii]].reach =
              Math.round(
                (programs[programmsIds[ii]].reach /
                  programs[programmsIds[ii]].count) *
                  1000
              ) / 1000;
            programs[programmsIds[ii]].reachPercent =
              Math.round(
                (programs[programmsIds[ii]].reachPercent /
                  programs[programmsIds[ii]].count) *
                  1000
              ) / 1000;
            clearProgs.push(programs[programmsIds[ii]]);
          }
          res.data.charts[0].series[0].data = clearProgs;
          res.data.charts[0].categories = categories;
        }
      }
      this.chart_loading = false;
      timeReq = performance.now() - timeReq;
      if (process.env.VUE_APP_ALTERNATE_UI != "true")
        // eslint-disable-next-line no-undef
        mh_px(300, {
          ym_uid: this.check_cookie_name("_ym_uid"),
          uniqid: reportUniqid,
          action: "DataReport",
          uid: this.$store.getters.StateUser.uid,
          msecs: timeReq,
        });

      this.indicator = req.get("indicator");
      this.data = res.data;
      if (this.totalProgs >= this.maxBarProgs) {
        this.tvshowsChartType = "column";
      }
      this.data.load = true;
      setTimeout(() => window.dispatchEvent(new Event("resize")));
    },
  },
  data: () => ({
    reportRequest: {},
    maxBarProgs: 2000, // Максимальное число программ в горизонтальном отображении
    barLabelWidth: 700, // вычисляемая величина размера меток графика в горизонтальном формате
    reportName: "tvshows",
    tvshowsChartType: "bar",
    axiosCancel: null,
    snackbarCopy: false,
    range: {},
    cities: [],
    aggregate: "None",
    toggle_graphType: 0,
    report: null,
    selectedCityId: null,
    tableSearch: null,
    isStoped: false,
    visionType: "graph",
    filter_loading: false,
    data: {},
    indicator: "",
    chart_loading: null,
    pageData: {
      title: "Показатели телепередач",
      type: "show",
      many_channel: false,
    },
  }),
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    hasPriv() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_tvshows
      ];
    },
    filename() {
      const city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });

      const channels = this.channels.filter((el) => {
        return this.selectedChannelsIds.includes(el.id);
      });
      console.log(this.shows);
      const shows = this.shows?.filter((el) => {
        return this.selectedShowsIds.includes(el.id);
      });
      const filename = Utils.createFilename({
        range: this.range,
        cities: city,
        channels: channels,
        shows: shows,
        indicator: this.chartTitle,
        reportTime: this.reportTime,
      });
      return filename;
    },
    rotateButtonMsg() {
      if (this.totalProgs >= this.maxBarProgs)
        return `Возможность разворота графика отключена, так как выбрано более ${this.maxBarProgs} передач`;
      return `Развернуть график`;
    },
    totalProgs() {
      return this.data.charts[0].categories.length;
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    subtitle() {
      let start = moment(this.range.start).locale("ru").format("DD MMM YYYY");
      let end = moment(this.range.end).locale("ru").format("DD MMM YYYY");
      let startTime = moment(this.range.start).locale("ru").format("HH:mm");
      let endTime = moment(this.range.end).locale("ru").format("HH:mm");
      let city = this.cities.find((el) => {
        return el.id === this.selectedCityId;
      });
      return `${city.name}, ${start} - ${end}, ${startTime} - ${endTime}`;
    },
    tableHeaders() {
      if (this.aggregate !== "None") {
        return this.tableHeadersAgregate;
      }
      let headers = [
        {
          text: "№",
          value: "num",
          visible: true,
        },
        {
          text: "Передача",
          value: "title",
          visible: true,
        },
        {
          text: "Серия",
          value: "sub",
          visible: true,
        },
        {
          text: "Начало, дата",
          value: "beginDate",
          visible: true,
        },
        {
          text: "Начало, время",
          value: "beginTime",
          visible: true,
        },
        {
          text: "Окончание, дата",
          value: "endDate",
          visible: true,
        },
        {
          text: "Окончание, время",
          value: "endTime",
          visible: true,
        },
        {
          text: this.chartTitle,
          value: "y",
          visible: true,
        },
      ];

      if (this.indicator === "All") {
        return [
          {
            text: "№",
            value: "num",
            visible: true,
          },
          {
            text: "Передача",
            value: "title",
            visible: true,
          },
          {
            text: "Серия",
            value: "sub",
            visible: true,
          },
          {
            text: "Начало, дата",
            value: "beginDate",
            visible: true,
          },
          {
            text: "Начало, время",
            value: "beginTime",
            visible: true,
          },
          {
            text: "Окончание, дата",
            value: "endDate",
            visible: true,
          },
          {
            text: "Окончание, время",
            value: "endTime",
            visible: true,
          },
          {
            text: this.chartTitle,
            value: "aud",
            visible: true,
          },
          {
            text: "Доля, %",
            value: "share",
            visible: true,
          },
          {
            text: "Рейтинг",
            value: "tvr",
            visible: true,
          },
          {
            text: "Охват, тыс. телезрителей",
            value: "reach",
            visible: true,
          },
          {
            text: "Охват, %",
            value: "reachPercent",
            visible: true,
          },
        ];
      }

      if (this.indicator === "AudienceDemo") {
        headers[headers.length - 1]["text"] = "Мужчины";
        headers.push({
          text: "Женщины",
          value: "y2",
          visible: true,
        });
      }
      return headers.filter((el) => {
        return el.visible;
      });
    },

    tableHeadersAgregate() {
      let headers = [
        {
          text: "№",
          value: "num",
          visible: true,
        },
        {
          text: "Передача",
          value: "title",
          visible: true,
        },
        {
          text: "Серия",
          value: "sub",
          visible:
            this.aggregate === "ProgramSubprogram" ||
            this.aggregate === "ProgramSubprogramTime",
        },
        {
          text: "Время выхода",
          value: "start",
          visible:
            this.aggregate === "ProgramTime" ||
            this.aggregate === "ProgramSubprogramTime",
        },
        {
          text: "Количество выходов",
          value: "count",
          visible: true,
        },
        {
          text: this.chartTitle,
          value: "y",
          visible: true,
        },
      ];
      if (this.indicator === "All") {
        return [
          {
            text: "№",
            value: "num",
            visible: true,
          },
          {
            text: "Передача",
            value: "title",
            visible: true,
          },
          {
            text: "Серия",
            value: "sub",
            visible:
              this.aggregate === "ProgramSubprogram" ||
              this.aggregate === "ProgramSubprogramTime",
          },
          {
            text: "Время выхода",
            value: "start",
            visible:
              this.aggregate === "ProgramTime" ||
              this.aggregate === "ProgramSubprogramTime",
          },
          {
            text: "Количество выходов",
            value: "count",
            visible: true,
          },
          {
            text: this.chartTitle,
            value: "aud",
            visible: true,
          },
          {
            text: "Доля, %",
            value: "share",
            visible: true,
          },
          {
            text: "Рейтинг",
            value: "tvr",
            visible: true,
          },
          {
            text: "Охват, тыс. телезрителей",
            value: "reach",
            visible: true,
          },
          {
            text: "Охват, %",
            value: "reachPercent",
            visible: true,
          },
        ].filter((el) => {
          return el.visible;
        });
      }
      if (this.indicator === "AudienceDemo") {
        headers[headers.length - 1]["text"] = "Мужчины";
        headers.push({
          text: "Женщины",
          value: "y2",
          visible: true,
        });
      }

      return headers.filter((el) => {
        return el.visible;
      });
    },
    chartOptions() {
      if (this.tvshowsChartType == "column") {
        if (this.totalProgs >= CONSTS.TVSHOWS_MAX_LABELED_LINES) {
          return this.chartOptionsColumnDate;
        } else {
          return this.chartOptionsColumnProg;
        }
      }
      //if (this.tvshowsChartType=="bar")
      return this.chartOptionsBar;
    },

    chartOptionsBar() {
      const that = this;
      let chartHeight = 400;
      let chartMax = 0;
      let charts = [];
      let data = this.data.charts ?? [];
      for (let chartId = 0; chartId < data.length; chartId++) {
        const categories = data[chartId].categories.map((item) =>
          item.replace(" |  | ", " | ")
        );
        for (const seriesItem of data[chartId].series) {
          const seriesMax = Math.max(...seriesItem.data.map((s) => s.y));
          if (seriesMax > chartMax) chartMax = seriesMax;
          if (this.indicator === "AudienceDemo") {
            if (!seriesItem.dmName) seriesItem.dmName = seriesItem.name;
            seriesItem.name = `${this.chartTitle} ${seriesItem.dmName}`;
          } else {
            seriesItem.name = this.chartTitle;
            seriesItem.color = {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
              },
              stops: [
                [0, CONSTS.CHART_BAR_COLORS[1]],
                [1, CONSTS.CHART_BAR_COLORS[0]],
              ],
            };
          }
        }

        let mult = 1;
        if (this.indicator === "AudienceDemo") mult = 2;
        chartHeight = 120 + categories.length * mult * (30 + 18);
        // console.log(chartHeight);
        charts.push({
          id: chartId,
          animation: false,
          chart: {
            marginLeft: 50,
            animation: false,
            type: this.tvshowsChartType,
            height: chartHeight,
            events: {
              //load: function() {},
              //redraw: function() {},
            },
          },
          title: { text: "" },
          colors:
            this.indicator === "AudienceDemo"
              ? CONSTS.CHART_DEMO_COLORS
              : CONSTS.CHART_COLORS,
          subtitle: { text: "" },
          exporting: {
            sourceWidth: 1200,
            chartOptions: {
              chart: {
                events: {},
              },
            },
          },
          xAxis: {
            lineColor: null,
            tickColor: null,
            crosshair: true,
            tickPositions: null,
            min: 0,
            max: categories.length - 1,
            startOnTick: null,
            endOnTick: null,
            categories,
            bulkCategories: categories, // костыль для таблиц
            labels: {
              enabled: true,
              useHTML: false,
              align: "left",
              rotation: 0,
              x: -46,
              y: 4,
              style: {
                color: "#000",
                fontSize: "14px",
                width: this.barLabelWidth + "px",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
              formatter: function () {
                const data = this.value.split("|");
                const dt = (data.length == 2 ? data[1] : data[2]).trim();
                return `${dt.substring(11, 16)}&nbsp;&nbsp;${data[0]}`;
              },
              events: {
                mouseover: function () {
                  this.axis.chart.series[0].onMouseOver();
                },
              },
            },
          },
          yAxis: {
            min: 0,
            max: chartMax,
            opposite: true,
            reversedStacks: false,
            title: { text: this.chartTitle },
          },
          tooltip: {
            useHTML: true,
            formatter() {
              that;
              let [prog, subprog, dt] = this.x.split("|");
              if (!dt) {
                // костыль с разбором формата данных
                dt = subprog;
                subprog = "";
              }
              const [sdt, edt] = dt.split(" - ");
              const sdate = sdt.substring(1, 11);
              const edate = edt.substring(0, 10);
              let dateStr = "";
              if (sdate == edate) {
                dateStr = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
                )} - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
              } else {
                dateStr = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
                )} - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
                )}`;
              }
              let str = `
                  ${dateStr}<br>
                  ${this.series.name}: <b>${this.y}</b>
                  <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                  <b>${prog}</b><br>
                  ${subprog}
                  `;
              return str;
            },
          },
          plotOptions: {
            bar: {
              pointPadding: 0,
              borderWidth: 0,
              dataLabels: {
                enabled: false,
              },
              //animation: {duration: 0}
            },
            series: {
              turboThreshold: 0,
              states: {
                inactive: {
                  opacity: 1,
                },
              },
              stacking: this.indicator === "AudienceDemo" ? false : "normal",
              dataLabels: { enabled: false },
            },
          },
          series: data[chartId].series,
        });
      }
      return charts;
    },
    chartOptionsColumnDate() {
      const that = this;
      let chartHeight = 400;
      let chartMax = 0;
      let charts = [];
      let tickPositions = null;
      let data = this.data.charts ?? [];
      let dateInterval = "day";
      for (let chartId = 0; chartId < data.length; chartId++) {
        const categories = data[chartId].categories.map((item) =>
          item.replace(" |  | ", " | ")
        );
        for (const seriesItem of data[chartId].series) {
          const seriesMax = Math.max(...seriesItem.data.map((s) => s.y));
          if (seriesMax > chartMax) chartMax = seriesMax;
          if (this.indicator === "AudienceDemo") {
            if (!seriesItem.dmName) seriesItem.dmName = seriesItem.name;
            seriesItem.name = `${this.chartTitle} ${seriesItem.dmName}`;
          } else {
            seriesItem.name = this.chartTitle;
            seriesItem.color = CONSTS.CHART_COLORS[0];
          }

          tickPositions = [];
          let prevDt = null;
          let i = 0;
          let totalDays = 0;
          // первый проход считаем дни
          for (const cat of categories) {
            const data = cat.split("|");
            const dt = (data.length == 2 ? data[1] : data[2]).trim();
            let showDt = dt.substring(0, 11);
            if (i == 0) showDt = dt.substring(22, 33);
            //let showDt = dt.substring(22, 33);
            //if (i == categories.length - 1) showDt = dt.substring(0, 11);

            if (showDt != prevDt) {
              prevDt = showDt;
              totalDays++;
            }
            i++;
          }
          if (totalDays > 7) dateInterval = "week";
          if (totalDays > 30) dateInterval = "month";

          prevDt = null;
          i = 0;

          for (const cat of categories) {
            const data = cat.split("|");
            const dt = (data.length == 2 ? data[1] : data[2]).trim();
            let showDt = dt.substring(22, 32);
            if (i == categories.length - 1) showDt = dt.substring(0, 10);

            if (showDt != prevDt) {
              if (dateInterval == "day") {
                tickPositions.push(i);
                prevDt = showDt;
              }
              if (dateInterval == "week") {
                if (i == 0 || this.isMonday(showDt)) {
                  // если интервал построения неделя, вставляем первый день и понедельники
                  tickPositions.push(i);
                  prevDt = showDt;
                }
              }
              if (dateInterval == "month") {
                if (i == 0 || this.isFirstDayofMonth(showDt)) {
                  // если интервал построения месяц, вставляем первый день и первые числа месяца
                  tickPositions.push(i);
                  prevDt = showDt;
                }
              }
            }
            i++;
          }
          //console.log(totalDays, dateInterval, tickPositions);
          tickPositions.push(i - 1); // для крайней справа отметки графика
        }
        const fixLabels = function () {
          //console.log("FIX")
          if (categories.length < CONSTS.TVSHOWS_MAX_LABELED_LINES) return;
          const labels = document.querySelectorAll(
            "div.highcharts-xaxis-labels span"
          );
          for (let i = 0; i < labels.length - 1; i++) {
            if (i < labels.length) {
              labels[i].style.width =
                parseInt(labels[i + 1].style.left) -
                parseInt(labels[i].style.left) +
                "px";
              labels[i].style.textAlign = "center";
            }
          }
        };
        charts.push({
          id: chartId,
          animation: false,
          chart: {
            marginLeft: null,
            animation: false,
            type: this.tvshowsChartType,
            height: chartHeight,
            events: {
              load: fixLabels,
              redraw: fixLabels,
            },
          },
          title: { text: "" },
          colors:
            this.indicator === "AudienceDemo"
              ? CONSTS.CHART_DEMO_COLORS
              : CONSTS.CHART_COLORS,
          subtitle: { text: "" },
          exporting: {
            sourceWidth: 1200,
            chartOptions: {
              chart: {
                events: {},
              },
            },
          },
          xAxis: {
            // min: 0.5,
            //  max: categories.length - 1.5,
            startOnTick: false,
            endOnTick: false,
            tickPositions,
            categories: null,
            lineColor: "black",
            tickColor: "black",
            tickLength: 12,
            bulkCategories: categories, // костыль для таблиц
            labels: {
              y: 20,
              x: 0,
              align: "left",
              useHTML: true,
              rotation: null,
              style: { fontSize: "12px" },
              formatter: function () {
                //console.log(this.value);
                if (this.pos == categories.length - 1) return "";
                const data = categories[this.value].split("|");
                const dt = (data.length == 2 ? data[1] : data[2]).trim();
                let showDt = dt.substring(22, 32);
                if (this.pos == categories.length - 1)
                  showDt = dt.substring(0, 10);
                if (this.pos == 0 && dateInterval == "week")
                  showDt = that.dateToMonday(showDt);
                if (dateInterval == "month") return that.dateToMonth(showDt);
                return moment(showDt, "YYYY-MM-DD").format("DD.MM.YYYY");
              },
            },
          },
          yAxis: {
            min: 0,
            max: chartMax,
            opposite: false,
            reversedStacks: false,
            title: { text: this.chartTitle },
          },
          tooltip: {
            useHTML: true,
            formatter() {
              that;
              let [prog, subprog, dt] = categories[this.x].split("|");
              if (!dt) {
                // костыль с разбором формата данных
                dt = subprog;
                subprog = "";
              }
              const [sdt, edt] = dt.split(" - ");
              const sdate = sdt.substring(1, 11);
              const edate = edt.substring(0, 10);
              let dateStr = "";
              if (sdate == edate) {
                dateStr = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
                )} - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
              } else {
                dateStr = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
                )} - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
                )}`;
              }
              let str = `
                  ${dateStr}<br>
                  ${this.series.name}: <b>${this.y}</b>
                  <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                  <b>${prog}</b><br>
                  ${subprog}
                  `;
              return str;
            },
          },
          plotOptions: {
            bar: {
              pointPadding: 0,
              borderWidth: 0,
              dataLabels: {
                enabled: false,
              },
              //animation: {duration: 0}
            },
            series: {
              turboThreshold: Infinity,
              states: {
                inactive: {
                  opacity: 1,
                },
              },
              stacking: this.indicator === "AudienceDemo" ? false : "normal",
              dataLabels: { enabled: false },
            },
          },
          series: data[chartId].series,
        });
      }
      return charts;
    },
    chartOptionsColumnProg() {
      const that = this;
      let chartHeight = 400;
      let chartMax = 0;
      let charts = [];
      let tickPositions = null;
      let data = this.data.charts ?? [];
      for (let chartId = 0; chartId < data.length; chartId++) {
        const categories = data[chartId].categories.map((item) =>
          item.replace(" |  | ", " | ")
        );
        for (const seriesItem of data[chartId].series) {
          const seriesMax = Math.max(...seriesItem.data.map((s) => s.y));
          if (seriesMax > chartMax) chartMax = seriesMax;
          if (this.indicator === "AudienceDemo") {
            if (!seriesItem.dmName) seriesItem.dmName = seriesItem.name;
            seriesItem.name = `${this.chartTitle} ${seriesItem.dmName}`;
          } else {
            seriesItem.name = this.chartTitle;
            seriesItem.color = CONSTS.CHART_COLORS[0];
          }
        }

        charts.push({
          id: chartId,
          animation: false,
          chart: {
            marginLeft: null,
            animation: false,
            type: this.tvshowsChartType,
            height: chartHeight,
            events: {
              redraw: function () {
                //charts[chartId].animation = false;
              },
            },
          },
          title: { text: "" },
          colors:
            this.indicator === "AudienceDemo"
              ? CONSTS.CHART_DEMO_COLORS
              : CONSTS.CHART_COLORS,
          subtitle: { text: "" },
          exporting: {
            sourceWidth: 1200,
            chartOptions: {
              chart: {
                events: {},
              },
            },
          },
          xAxis: {
            crosshair: true,
            tickPositions,
            categories,
            bulkCategories: categories, // костыль для таблиц
            labels: {
              y: 20,
              x: 0,
              align: null,
              rotation: null,
              style: { fontSize: "12px" },
              formatter: function () {
                const data = this.value.split("|");
                return data[0];
              },
            },
          },
          yAxis: {
            min: 0,
            max: chartMax,
            opposite: false,
            reversedStacks: false,
            title: { text: this.chartTitle },
          },
          tooltip: {
            useHTML: true,
            formatter() {
              that;
              let [prog, subprog, dt] = this.x.split("|");
              if (!dt) {
                // костыль с разбором формата данных
                dt = subprog;
                subprog = "";
              }
              const [sdt, edt] = dt.split(" - ");
              const sdate = sdt.substring(1, 11);
              const edate = edt.substring(0, 10);
              let dateStr = "";
              if (sdate == edate) {
                dateStr = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
                )} - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
              } else {
                dateStr = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
                )} - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                  "DD.MM.YYYY HH:mm"
                )}`;
              }
              let str = `
                  ${dateStr}<br>
                  ${this.series.name}: <b>${this.y}</b>
                  <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                  <b>${prog}</b><br>
                  ${subprog}
                  `;
              return str;
            },
          },
          plotOptions: {
            bar: {
              pointPadding: 0,
              borderWidth: 0,
              dataLabels: {
                enabled: false,
              },
              //animation: {duration: 0}
            },
            series: {
              turboThreshold: Infinity,
              states: {
                inactive: {
                  opacity: 1,
                },
              },
              stacking: this.indicator === "AudienceDemo" ? false : "normal",
              dataLabels: { enabled: false },
            },
          },
          series: data[chartId].series,
        });
      }
      return charts;
    },

    chartTitle() {
      return Utils.indicatorName(this.indicator);
    },
  },
  watch: {
    indicator() {
      if (this.indicator === "All") {
        this.toggle_graphType = 1;
      }
    },
    toggle_graphType() {
      this.visionType = this.toggle_graphType === 0 ? "graph" : "table";
    },
  },
};
</script>

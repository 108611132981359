const STD_CONSTS = Object.freeze({
  WEEKDAYS: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
  TOP20_PREOPEN_CHANNEL_INDEX: 2, // индекс открытого по умолчанию канала
  TOP20_CHANNELS_COLORS: "rgba(255, 153, 0, 1)", // цвета каналов топ20 , 1) - в конце
  TOP20_OPEN_CHANNEL_COLOR: "rgba(255, 153, 0, 1)", // цвет открытого канала
  TOP20_OPEN_CHANNEL_BG_COLOR: "rgba(150, 150, 150, .1)", // цвет фона открытого канала
  TOP20_CHART_INOUT_COLORS: ["rgba(84, 179, 89, 1)", "rgba(247, 159, 96, 1)"], // цвета приходов-уходов (только топ20)
  TV_COMPARE_SLIDER_RANGES: [-30, -11, 11, 30], // значения для слайдера в Анализе сетки вещания
  SPECIAL_INTERNET_COLORS:[
    "rgba(247, 163, 92, 1)",
    "rgba(228, 100, 100, 1)",
    "rgba(71, 145, 219, 1)",
  ],
  CHART_COLORS: [
    // формат только rgba c 1 в конце!
    "rgba(25, 118, 210, 1)",
    "rgba(220, 57, 18, 1)",
    "rgba(255, 153, 0, 1)",
    "rgba(16, 150, 24, 1)",
    "rgba(153, 0, 153, 1)",
    "rgba(205, 220, 57, 1)",
    "rgba(125, 223, 0, 1)",
    "rgba(221, 14, 255, 1)",
    "rgba(0, 255, 209, 1)",
    "rgba(0, 188, 212, 1)",
    "rgba(12, 36, 246, 1)",
    "rgba(255, 128, 171, 1)",
  ],
  CHART_BAR_COLORS: [
    // для графика передач (градиент)
    "rgba(25, 118, 210, .2)",
    "rgba(25, 118, 210, 1)",
  ],
  CHART_DEMO_COLORS: [
    "#00BFA5",
    "#E91E63",
    "#3366cc",
    "#dc3912",
    "#ff9900",
    "#109618",
    "#990099",
  ],
  CHART_INOUT_COLORS: ["rgba(16, 150, 24, 1)", "rgba(245, 135, 60, 1)"],
  TV_COMPARE_PROG_COLORS: [
    ["#9BCDFB", "#4895DC"],
    ["#AFF2BE", "#51CC7B"],
    ["#DFCFF5", "#B994E8"],
    ["#F1E8F2", "#CCC3CD"],
  ],
  TV_COMPARE_SLIDER_COLORS: [
    "rgba(241, 0, 0, .5)",
    "rgba(245, 135, 60, .5)",
    "rgba(251, 227, 149, .5);",
    "rgba(185, 210, 68, .5);",
    "rgba(54, 175, 75, .5)",
  ],
  PROGRAMM_ACCENT_COLORS: [
    "rgba(244, 67, 54, 0.5)",
    "rgba(255, 152, 0, 0.5)",
    "rgba(255, 193, 7, 0.5)",
    "rgba(255, 235, 59, 0.5)",
    "rgba(205, 220, 57, 0.5)",
    "rgba(76, 175, 80, 0.5)",
    "rgba(0, 150, 136, 0.5)",
    "rgba(0, 188, 212, 0.5)",
    "rgba(33, 150, 243, 0.5)",
    "rgba(156, 39, 176, 0.5)",
    "rgba(103, 58, 183, 0.5)",
    "rgba(0, 0, 0, 0.5)",
  ],
  // Привязанные к телеканалам цвета для специфических отчетов
  //[0 - цвет на графике, 1 - bg выделения, 2 - bg "мой канал" ]
  TV_SPECIAL_COLORS: {
    2709: ["rgb(16,150,24)", "rgba(150, 150, 150, .1)", "rgb(16,150,24,.1)"], // НТВ
    2704: ["rgb(25,118,210)", "rgba(150, 150, 150, .1)", "rgb(25,118,210,.1)"], // Первый канал
    390: ["rgb(153,0,153)", "rgba(150, 150, 150, .1)", "rgb(153,0,153,.1)"], // Россия 1
  },
  SECTION_GTRK_COLORS: [
    "rgba(205, 205, 205, 0.5)",
    "rgba(241, 0, 0, 0.5)",
    "rgba(245, 135, 60, 0.5)",
    "rgba(251, 227, 149, 0.5)",
    "rgba(185, 210, 68, 0.5)",
    "rgba(54, 175, 75, 0.5)",
  ],
  SECTION_GTRK_COLORS_2: [
    "rgba(205, 205, 205, 0.5)",
    "rgba(225, 250, 226, 0.5)",
    "rgba(143, 220, 156, 0.5)",
    "rgba(108, 189, 122, 0.5)",
    "rgba(61, 160, 78, 0.5)",
    "rgba(22, 135, 42, 0.5)",
  ],
  CONTENT_PRODUCERS_EXTRATIME: 30, // сколько минут добавляем слева и справа на 2 и 3 графике
  CONTENT_PRODUCERS_SHOW_COLOR: "#FFFFFF", // цвет фона выбранной передачи
  CONTENT_PRODUCERS_EXTRATIME_COLOR: "#eeeeee", // цвет фона дополнительного времени
  BAND_ACCENT_COLOR: "#ecf6fb", // цвет фона акцента на передачу по умолчанию
  MAJOR_VERSION: 4,
  PRIVS_KEYS: {
    source_id: "1",
    all_channels_report_limit: "2",
    audience: "3",
    tvr: "4",
    share: "5",
    reach: "6",
    reach_percent: "7",
    watch_time: "8",
    dev_hour: "9",
    dev_day: "10",
    hh_hour: "11",
    hh_day: "12",
    sdem_gender: "13",
    sdem_male_age: "14",
    sdem_female_age: "15",
    tvr_total: "16",
    indicator_all: "17",
    sdem_api_bbdo: "18",
    users_admin: "19",
    my_reports_section: "20",
    presets_sections: "21",
    section_content_producers: "22",
    content_producers_myProg: "23",
    section_queues: "24",
    section_gtrk: "25",
    section_tvchannels: "26",
    section_compare_tv: "27",
    section_tvshows: "28",
    section_incomes: "29",
    section_compare_tv_period: "30",
    section_views_devices: "31",
    section_views_ratio: "32",
    section_online: "33",
    section_about: "34",
    all_geo_access: "35",
    demo_age: "36",
    demo_group: "37",
    section_amedia: "38",
    section_multilandia: "39",
    section_accounts: "40",
    show_banner: "41",
    section_21button: "42",
    section_22button: "43",
  },
  TVSHOWS_MAX_LABELED_LINES: 40, //кол-во строк в отчете телеканалов после которого скрываются метки и ставятся даты
});

const ALERNATE_CONSTS = Object.freeze({
  WEEKDAYS: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
  TOP20_PREOPEN_CHANNEL_INDEX: 2, // индекс открытого по умолчанию канала
  TOP20_CHANNELS_COLORS: "rgba(127,29,103,1)", // цвета каналов топ20 , 1) - в конце
  TOP20_OPEN_CHANNEL_COLOR: "rgba(127,29,103,1)", // цвет открытого канала
  TOP20_OPEN_CHANNEL_BG_COLOR: "rgba(150, 150, 150, .1)", // цвет фона открытого канала
  TOP20_CHART_INOUT_COLORS: ["rgba(1, 201, 214, 1)", "rgba(128, 29, 103, 1)"], // цвета приходов-уходов (только топ20)
  TV_COMPARE_SLIDER_RANGES: [-30, -11, 11, 30], // значения для слайдера в Анализе сетки вещания
  CHART_COLORS: [
    // формат только rgba c 1 в конце!
    "rgba(128, 29, 103, 1)",
    "rgba(144, 28, 235, 1)",
    "rgba(71, 89, 251, 1)",
    "rgba(1, 201, 214, 1)",
    "rgba(19, 191, 78, 1)",
    "rgba(161, 218, 0, 1)",
    "rgba(219, 184, 0, 1)",
    "rgba(255, 115, 14, 1)",
    "rgba(228, 41, 0, 1)",
    "rgba(188, 9, 74, 1)",
    "rgba(12, 36, 246, 1)",
    "rgba(255, 128, 171, 1)",
  ],
  CHART_BAR_COLORS: [
    // для графика передач (градиент)
    "rgba(255, 115, 14, 0.1)",
    "rgba(127, 29, 103, 0.6)",
  ],
  CHART_DEMO_COLORS: [
    "#00BFA5",
    "#E91E63",
    "#3366cc",
    "#dc3912",
    "#ff9900",
    "#109618",
    "#990099",
  ],
  CHART_INOUT_COLORS: ["rgba(1, 201, 214, 1)", "rgba(128, 29, 103, 1)"],
  TV_COMPARE_PROG_COLORS: [
    ["#FCD033", "#FB8C00"],
    ["#AAE262", "#66BB6A"],
    ["#E96DBE", "#E8A394"],
    ["#E8EEF2", "#CCC3CD"],
  ],
  TV_COMPARE_SLIDER_COLORS: [
    "rgba(241, 0, 0, .5)",
    "rgba(245, 135, 60, .5)",
    "rgba(251, 227, 149, .5);",
    "rgba(185, 210, 68, .5);",
    "rgba(54, 175, 75, .5)",
  ],
  PROGRAMM_ACCENT_COLORS: [
    "rgba(244, 67, 54, 0.5)",
    "rgba(255, 152, 0, 0.5)",
    "rgba(255, 193, 7, 0.5)",
    "rgba(255, 235, 59, 0.5)",
    "rgba(205, 220, 57, 0.5)",
    "rgba(76, 175, 80, 0.5)",
    "rgba(0, 150, 136, 0.5)",
    "rgba(0, 188, 212, 0.5)",
    "rgba(33, 150, 243, 0.5)",
    "rgba(156, 39, 176, 0.5)",
    "rgba(103, 58, 183, 0.5)",
    "rgba(0, 0, 0, 0.5)",
  ],
  // Привязанные к телеканалам цвета для специфических отчетов
  //[0 - цвет на графике, 1 - bg выделения, 2 - bg "мой канал" ]
  TV_SPECIAL_COLORS: {
    2709: ["rgb(16,150,24)", "rgba(150, 150, 150, .1)", "rgb(16,150,24,.1)"], // НТВ
    2704: ["rgb(25,118,210)", "rgba(150, 150, 150, .1)", "rgb(25,118,210,.1)"], // Первый канал
    390: ["rgb(153,0,153)", "rgba(150, 150, 150, .1)", "rgb(153,0,153,.1)"], // Россия 1
  },
  CONTENT_PRODUCERS_EXTRATIME: 30, // сколько минут добавляем слева и справа на 2 и 3 графике
  CONTENT_PRODUCERS_SHOW_COLOR: "#FFFFFF", // цвет фона выбранной передачи
  CONTENT_PRODUCERS_EXTRATIME_COLOR: "#ecf6fb", // цвет фона дополнительного времени
  BAND_ACCENT_COLOR: "#fff9f4", // цвет фона акцента на передачу по умолчанию
  MAJOR_VERSION: 4,
  PRIVS_KEYS: {
    source_id: "1",
    all_channels_report_limit: "2",
    audience: "3",
    tvr: "4",
    share: "5",
    reach: "6",
    reach_percent: "7",
    watch_time: "8",
    dev_hour: "9",
    dev_day: "10",
    hh_hour: "11",
    hh_day: "12",
    sdem_gender: "13",
    sdem_male_age: "14",
    sdem_female_age: "15",
    tvr_total: "16",
    indicator_all: "17",
    sdem_api_bbdo: "18",
    users_admin: "19",
    my_reports_section: "20",
    presets_sections: "21",
    section_content_producers: "22",
    content_producers_myProg: "23",
    section_queues: "24",
    section_gtrk: "25",
    section_tvchannels: "26",
    section_compare_tv: "27",
    section_tvshows: "28",
    section_incomes: "29",
    section_compare_tv_period: "30",
    section_views_devices: "31",
    section_views_ratio: "32",
    section_online: "33",
    section_about: "34",
    all_geo_access: "35",
    demo_age: "36",
    demo_group: "37",
    section_amedia: "38",
    section_multilandia: "39",
    section_accounts: "40",
    show_banner: "41",
    section_21button: "42",
    section_22button: "43",
  },
});
export const CONSTS = process.env.VUE_APP_ALTERNATE_UI=='true' ?  ALERNATE_CONSTS : STD_CONSTS

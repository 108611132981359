<template>
  <div
    :class="
      $vuetify.breakpoint.mdAndDown ? 'loginFormBlock_sm' : 'loginFormBlock_lg'
    "
  >
    <validation-observer ref="observer" v-slot="{}">
      <v-row
        class="mt-0 pt-0"
        v-if="$vuetify.breakpoint.mdAndDown && !isAlternate"
      >
        <v-col>
          <v-img
            class="mx-auto"
            height="102"
            width="149"
            :src="require('@/assets/login_logo.svg')"
          />
        </v-col>
      </v-row>
      <v-row class="mb-1" id="loginFormAnchor">
        <v-col>
          <h4 class="text-left">Личный кабинет</h4>
        </v-col>
      </v-row>
      <form @submit.prevent="submit" class="loginForm">
        <validation-provider
          v-slot="{ errors }"
          name="username"
          rules="required"
        >
          <v-text-field
            id="login_input"
            v-model="username"
            autofocus="true"
            :error-messages="errors"
            label="логин"
            persistent-placeholder
            required
          ></v-text-field>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="username"
          rules="required"
        >
          <v-text-field
            id="pass_input"
            v-model="password"
            type="password"
            :error-messages="errors"
            label="пароль"
            persistent-placeholder
            required
          ></v-text-field>
        </validation-provider>
        <p v-if="showError" id="error">Username or Password is incorrect</p>
        <v-btn
          color="primary"
          block
          class="mt-0 mh-primary-border"
          type="submit"
        >
          Войти</v-btn
        >
      </form>
      <v-row class="text-center mt-2 mb-2">
        <v-col class="infoText">
          <a :href="oldUrl">Войти в старую версию</a>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { mapActions } from "vuex";
import router from "@/router";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");
extend("required", {
  ...required,
  message: "поле не может быть пустым",
});

export default {
  name: "LoginForm",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    password: "",
    username: "",
    showError: false,
  }),
  mounted() {},
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    oldUrl() {
      return process.env.VUE_APP_OLD_URL;
    },
  },
  methods: {
    ...mapActions([
      "LogIn",
      "LoginDebug",
      "storeCities",
      "storeTvs",
      "storeFilters",
      "storeLastCities",
      "storeLastTvs",
    ]),
    async submit() {
      await this.$refs.observer.validate();
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.showError = true;
      }
      const User = new FormData();
      User.append("login", this.username);
      User.append("passwd", this.password);
      try {
        await this.LogIn(User);
        await this.storeCities([]);
        await this.storeTvs([]);
        let filters = {
          selectCityId: null,
          selectedTVId: null,
          selectProgrammId: null,
          selectedSourceId: null,
        };
        if (this.$store.getters.StateUser.settings) {
          let settings = this.$store.getters.StateUser.settings;
          if (settings["selected_source_id"] != 0) {
            filters.selectedSourceId = settings["selected_source_id"] + "%";
            filters.selectedSourceId +=
              settings["selected_helper_type"] == "operator"
                ? "operator"
                : "mh";
          }
          if (settings["selected_geo_id"] != 0) {
            filters.selectCityId = settings["selected_geo_id"];
          }
          if (settings["selected_tv_id"] != 0) {
            filters.selectedTVId = settings["selected_tv_id"];
            if (filters.selectedTVId) {
              if (
                !Array.isArray(filters.selectedTVId) &&
                filters.selectedTVId.includes(",")
              ) {
                filters.selectedTVId = filters.selectedTVId.split(",");
              }
            }
          }
          if (settings["last5_city_id"] != 0) {
            await this.storeLastCities(settings["last5_city_id"].split(","));
          }
          if (settings["last5_tv_id"] != 0) {
            await this.storeLastTvs(settings["last5_tv_id"].split(","));
          }
        }
        await this.storeFilters(filters);
        await router.push({ name: "indicators" });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loginFormBlock_sm {
  width: 280px;
  /*   height: 122px; */
  margin-top: 72px;
}
.loginFormBlock_lg {
  width: 280px;
  display: flex;
  align-items: center;
  margin-top: -40px;
  margin-left: 60px;
}

h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
}

.formName {
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.appName {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 47px;
  color: #000000;
}
</style>

<template>
  <div>
    <view-report-header
      v-if="isPreviewHeader"
      :indicator="indicatorText"
      :aggregation="aggregationText"
      :shows="showsText"
      :detalisation="detalisationText"
      :report="reportText"
      :city="cityText"
      :period="periodText"
      :channels="channelsText"
      :page="page"
      @newReportFromUuid="newReportFromUuid"
    ></view-report-header>
    <v-card v-if="!isPreviewHeader" outlined class="mh-main-filter">
      <v-card-title
        class="text-lg-h4 mh-page-title text-md-h5 justify-space-between pb-2 flex-row"
      >
        {{
          typeof $route.params.uuid == "string" && $route.params.uuid.length > 0
            ? "Просмотр отчета "
            : ""
        }}{{ page.title }}
        <br v-if="$vuetify.breakpoint.smAndDown" />
        <p
          class="mh-clear-btn"
          v-if="$vuetify.breakpoint.smAndDown"
          :disabled="disableField"
          @click="clearAllFilters"
        >
          Сбросить все
        </p>
        <help-btn :new-version="true"></help-btn>
        <v-spacer></v-spacer>
        <v-spacer v-if="!isMobile" style="height: 48px"></v-spacer>
        <p
          class="mh-clear-btn"
          v-if="!$vuetify.breakpoint.smAndDown"
          :disabled="disableField"
          @click="clearAllFilters"
        >
          Сбросить все
        </p>
      </v-card-title>
      <v-row align="center">
        <v-col cols="auto" class="ml-4 pa-0 pl-2 pt-4 pb-4"
          ><p class="body-2 black38 mb-0">Исходные данные</p></v-col
        >
        <v-col class="pr-7"> <v-divider></v-divider> </v-col>
      </v-row>
      <v-row class="ma-1 pr-2 pl-2">
        <v-col :cols="defaultCols3" class="pb-0 pt-0 pr-1 pl-1">
          <mh-drop-down
            :items="cities"
            v-model="selectedCityId"
            :disabled="disableField"
            label="город"
            :item-text="isAdmin ? 'name_admin' : 'name'"
            item-value="num"
            :hide-details="true"
            :multiple="false"
            :is-city="true"
            item-group="name_head2"
          >
          </mh-drop-down>
        </v-col>
        <v-col :cols="defaultCols3" class="pb-0 pt-0 pr-1 pl-1">
          <mh-drop-down
            :items="tvs"
            ref="tvSel"
            :is-t-v="true"
            v-model="selectTVId"
            :disabled="!selectedCityId || disableField"
            :label="
              page.type === 'tv' || page.type === 'compare-tv'
                ? 'телеканалы'
                : 'телеканал'
            "
            :item-text="isAdmin ? 'ns1_name_admin' : 'ns1_name_ru'"
            item-value="num"
            :multiple="page.many_channel"
            :item-group="isAdmin ? 'ns2_name_admin' : 'ns2_name_ru'"
            @input="checkTvCount"
            :show-select-all="showSelectAll()"
            :select-all-to-null="true"
            @all="allChannel"
          >
          </mh-drop-down>
        </v-col>
        <v-col
          :cols="defaultCols3"
          :style="isXl ? 'padding-top: 16px!important;' : ''"
          class="text-center pb-0 pt-0 pr-1 pl-1"
        >
          <main-calendar
            ref="mcalendar"
            :disabledThrough="disabledThrough"
            :disabled="disableField"
            @calendarok="calendarHandler"
            @ok="calendarOk"
            :with-range="withRange"
          ></main-calendar>
        </v-col>
        <v-col
          :cols="defaultCols3"
          :style="
            isXl && !$vuetify.breakpoint.smAndDown
              ? 'padding-top: 16px!important;'
              : ''
          "
          class="pb-0 pt-0 pr-1 pl-1"
        >
          <v-menu offset-y v-model="opened" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Источник"
                :hide-details="isMobile ? false : 'auto'"
                outlined
                class="mh-dropdown-slot"
                dense
                append-icon="mdi-tune"
                v-on:click:append="opened = true"
                :disabled="disableField"
                :readonly="!disableField"
                :value="sourceName"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-card outlined elevation="0">
              <v-card-text>
                <v-autocomplete
                  :filter="filterAutoComplete"
                  dense
                  :disabled="disableField"
                  :readonly="!disableField"
                  v-model="selectedSourceId"
                  outlined
                  class="mh-dropdown-slot"
                  menu-props="openOnClick, closeOnClick, closeOnContentClick"
                  hide-details="auto"
                  label="Источник"
                  :items="sources"
                  item-text="name"
                  no-data-text="Ничего не найдено"
                  item-value="id"
                >
                </v-autocomplete>
                <v-autocomplete
                  v-if="
                    selectedSourceId == 2 ||
                    selectedSourceId == 6 ||
                    selectedSourceId == 10
                  "
                  :filter="filterAutoComplete"
                  dense
                  class="pt-4 mh-dropdown-slot"
                  menu-props="openOnClick, closeOnClick, closeOnContentClick"
                  hide-details="auto"
                  :disabled="disableField"
                  :readonly="!disableField"
                  v-model="radioHelpersType"
                  outlined
                  label="Справочник"
                  :items="[
                    { id: 'mh', name: sourceNameStd },
                    selectedSourceId == 2 || selectedSourceId == 10
                      ? { id: 'operator', name: 'Оператор данных' }
                      : null,
                  ]"
                  item-text="name"
                  no-data-text="Ничего не найдено"
                  item-value="id"
                >
                </v-autocomplete>

                <v-col
                  v-if="
                    isAdmin &&
                    radioHelpersType == 'mh' &&
                    ((indicator != 'Audience' &&
                      indicator != 'AudienceDemo' &&
                      indicator != 'TVR' &&
                      indicator != 'TVRTotal' &&
                      indicator != 'Share' &&
                      indicator != 'Reach' &&
                      indicator != 'ReachPercent' &&
                      indicator != 'All') ||
                      selectedSourceId == 6 ||
                      selectedSourceId == 7 ||
                      selectedSourceId == 9)
                  "
                  class="mt-2"
                >
                  <v-checkbox
                    :disabled="disableField"
                    class="pa-0 mt-0 pl-3 pr-3"
                    v-model="useCoeffArr"
                    label="Коэффициент часа"
                    value="hour"
                  ></v-checkbox>
                  <v-checkbox
                    :disabled="disableField"
                    class="pt-0 mt-0 align-start pl-3 pr-3"
                    v-model="useCoeffArr"
                    label="Коэффициент экстраполяции"
                    value="extra"
                  ></v-checkbox>
                </v-col>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-row align="center" class="mt-n8 mt-md-0" v-if="page.type != 'incomes'">
        <v-col cols="auto" class="ml-4 pa-0 pl-2 pt-4 pb-4"
          ><p class="body-2 black38 mb-0">Целевая аудитория</p></v-col
        >
        <v-col class="pr-7"> <v-divider></v-divider> </v-col>
      </v-row>
      <v-row class="ma-1 pr-2 pl-2 mb-4" v-if="page.type != 'incomes'">
        <v-col class="col-12 col-md-auto pl-0">
          <v-btn-toggle
            borderless
            v-model="toggle_demo"
            mandatory
            dense
            :disabled="isDemoDisabled || !hasDemoGroupPriv"
          >
            <v-btn
              elevation="0"
              :disabled="isDemoDisabled || !hasDemoGroupPriv"
              class="mh-noactive-toggle"
              :active-class="'white black--text mh-active-toggle'"
            >
              Мужчины
            </v-btn>

            <v-btn
              elevation="0"
              :disabled="isDemoDisabled || !hasDemoGroupPriv"
              class="mh-noactive-toggle"
              :active-class="'white black--text mh-active-toggle'"
            >
              Женщины
            </v-btn>
            <v-btn
              elevation="0"
              :disabled="isDemoDisabled || !hasDemoGroupPriv"
              class="mh-noactive-toggle"
              :active-class="'white black--text mh-active-toggle'"
            >
              Дети
            </v-btn>
            <v-btn
              elevation="0"
              class="mh-noactive-toggle"
              :active-class="'white black--text mh-active-toggle'"
            >
              Все
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col style="position: relative">
          <v-subheader
            class="mh-age-title ma-0 ml-n4 pa-0 mb-n3 caption"
            style="height: 20px"
          >
            <v-icon class="mr-2" small>{{ ageIcon }}</v-icon
            >Возрастная группа
          </v-subheader>
          <v-row>
            <v-col
              cols="auto"
              class="pl-0 pr-0"
              :style="isMobile ? fixCols : ''"
            >
              <span class="caption mh-range-caption">от</span>
              <v-text-field
                @input="fromAgeDemoInput"
                :readonly="isDemoDisabled || !hasDemoAgePriv"
                :disabled="isDemoDisabled || !hasDemoAgePriv"
                :value="fromAgeDemo"
                filled
                outlined
                dense
                hide-details
                single-line
                class="mh_age_helper"
              >
              </v-text-field>
            </v-col>
            <v-col
              :style="
                isMobile
                  ? '    flex-basis: 0!important; flex-grow: 1 !important;'
                  : ''
              "
            >
              <v-range-slider
                class="mt-2"
                color="secondary"
                ticks="always"
                :tick-labels="ageTicks"
                tick-size="0"
                thumb-color="secondary"
                track-color="rgba(0, 0, 0, 0.18)"
                :disabled="isDemoDisabled || !hasDemoAgePriv"
                v-model="rangeAge"
                step="1"
                :min="minAge"
                :max="maxAge"
                hide-details
              >
              </v-range-slider>
            </v-col>
            <v-col
              cols="auto"
              class="pl-0 pr-0"
              :style="isMobile ? fixCols : ''"
            >
              <span class="caption mh-range-caption">до</span>
              <v-text-field
                :readonly="isDemoDisabled || !hasDemoAgePriv"
                :disabled="isDemoDisabled || !hasDemoAgePriv"
                :value="untilAgeDemo"
                @input="untilAgeDemoInput"
                filled
                outlined
                dense
                hide-details
                single-line
                class="mh_age_helper"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        align="center"
        :class="
          page.type == 'incomes'
            ? 'row mt-n8 mt-md-0 mb-0'
            : 'mt-0 mt-md-n8 mb-0'
        "
      >
        <v-col cols="auto" class="ml-4 pa-0 pl-2 pt-4 pb-4"
          ><p class="body-2 black38 mb-0">Построение отчета</p></v-col
        >
        <v-col class="pr-7"> <v-divider></v-divider> </v-col>
      </v-row>

      <v-row :class="'ma-1 pr-2 pl-2 mb-4'">
        <v-col
          :cols="defaultCols2"
          v-if="page.type !== 'incomes'"
          class="pb-0 pt-0 pr-1 pl-1"
        >
          <v-autocomplete
            class="mh-dropdown-slot"
            menu-props="openOnClick, closeOnClick, closeOnContentClick"
            :filter="filterAutoComplete"
            dense
            :disabled="disableField"
            :readonly="!disableField"
            v-model="indicator"
            outlined
            :hide-details="isMobile ? false : 'auto'"
            label="показатель"
            no-data-text="Ничего не найдено"
            :items="indicatorsList"
            item-text="name"
            item-disabled="disabled"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col
          :cols="defaultCols2"
          class="pb-0 pt-0 pr-1 pl-1"
          v-if="
            (all_channel === false || indicator === 'TVRTotal') &&
            aggregateVisible
          "
        >
          <v-autocomplete
            :filter="filterAutoComplete"
            dense
            class="mh-dropdown-slot"
            :readonly="!disabledAggregation"
            menu-props="openOnClick, closeOnClick, closeOnContentClick"
            :hide-details="isMobile ? false : 'auto'"
            no-data-text="Ничего не найдено"
            :disabled="disabledAggregation"
            v-model="aggregate"
            outlined
            :label="report === 'hour' ? 'вывод' : 'агрегация'"
            :items="aggregationList"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col
          :cols="defaultCols2"
          class="pb-0 pt-0 pr-1 pl-1"
          v-if="
            false &&
            indicator === 'Audience' &&
            radioHelpersType == 'mh' &&
            this.page.type !== 'incomes' &&
            this.page.type !== 'compare-tv' &&
            this.page.type !== 'compare-tv-period'
          "
        >
          <v-autocomplete
            :filter="filterAutoComplete"
            dense
            class="mh-dropdown-slot"
            :disabled="disableField"
            :readonly="!disableField"
            menu-props="openOnClick, closeOnClick, closeOnContentClick"
            :hide-details="isMobile ? false : 'auto'"
            no-data-text="Ничего не найдено"
            v-model="demography"
            outlined
            :label="'демография'"
            :items="demographyList"
            item-disabled="disabled"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col
          :cols="defaultCols3"
          class="pb-0 pt-1 pr-1 pl-1 mh-detalisation-row"
          v-if="page.type == 'tv'"
        >
          <v-subheader class="ma-0 pa-0 mb-n3 caption" style="height: 20px">
            <v-icon class="mr-2" small>mdi-clock-outline</v-icon>Детализация
          </v-subheader>

          <v-slider
            color="rgba(0, 0, 0, 0.18)"
            thumb-color="secondary"
            track-color="rgba(0, 0, 0, 0.18)"
            :disabled="disableField"
            v-model="detalisationStep"
            :tick-labels="isReachOrSan ? tickLabelsReach : tickLabels"
            step="1"
            :min="isReachOrSan ? 6 : 1"
            max="10"
            hide-details
            tick-size="0"
          >
          </v-slider>
        </v-col>
        <v-col
          :cols="2 * defaultCols2"
          class="pb-0 pt-0 pr-1 pl-1"
          :style="
            isXl && page.type === 'show' && !isMobile
              ? 'padding-top: 16px !important'
              : ''
          "
          v-if="page.type === 'show' || page.type === 'incomes'"
        >
          <mh-drop-down
            :items="shows"
            v-if="page.type === 'show'"
            :disabled="!selectedCityId || !selectTVId || disableField"
            v-model="selectProgrammId"
            :label="
              selectProgrammId && selectProgrammId.length > 1
                ? 'передачи'
                : 'передача'
            "
            item-text="name"
            item-value="id"
            :multiple="page.type === 'show'"
            @input="selectProgram"
            :show-select-all="page.type === 'show'"
            @all="selectAllProgram"
          >
          </mh-drop-down>

          <v-autocomplete
            class="mh-dropdown-slot"
            :filter="filterAutoComplete"
            dense
            v-if="page.type === 'incomes'"
            :disabled="!selectedCityId || !selectTVId || disableField"
            v-model="selectProgrammId"
            outlined
            :hide-details="isMobile ? false : 'auto'"
            clearable
            :chips="page.type === 'show'"
            :deletable-chips="page.type === 'show'"
            :label="'телеперадача'"
            :items="shows"
            no-data-text="Ничего не найдено"
            item-text="name"
            item-value="id"
            :multiple="page.type === 'show'"
            :show-select-all="page.type === 'show'"
            :select-all-to-null="false"
            @input="selectProgram"
          >
            <template v-slot:item="data" v-if="page.type === 'incomes'">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <span class="subtitle-1">
                    {{ data.item.name }}
                    <span class="body-2" style="opacity: 0.5"
                      >{{ data.item.start_loc_time }} -
                      {{ data.item.stop_loc_time }}</span
                    >
                  </span>
                </v-list-item-content>
              </template>
            </template>
            <template v-slot:selection="data" v-if="page.type === 'incomes'">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <span class="subtitle-1">
                  {{ data.item.name }}
                  <span class="body-2" style="opacity: 0.5"
                    >{{ data.item.start_loc_time }} -
                    {{ data.item.stop_loc_time }}</span
                  >
                </span>
              </template>
            </template>
            <template v-slot:prepend-item v-if="page.type === 'show'">
              <v-list-item
                ripple
                @mousedown.prevent
                @click="selectAllProgram()"
              >
                <v-list-item-action>
                  <v-icon>
                    {{
                      all_programs
                        ? "mdi-checkbox-marked"
                        : "mdi-checkbox-blank-outline"
                    }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ all_programs ? "Очистить" : "Выбрать все" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-divider
        :class="
          'ml-4 mr-4 ' +
          ($vuetify.breakpoint.mdAndDown ? 'mt-0 mb-4 ' : 'mt-3 mb-3 ')
        "
      ></v-divider>
      <v-row :class="'ma-1 pr-2 pl-2 mb-4'">
        <v-col
          cols="0"
          class="text-right justify-end pb-0 pt-0 mh-btn-row"
          :style="
            isXl &&
            page.type !== 'compare-tv-period' &&
            page.type !== 'compare-tv' &&
            page.type !== 'incomes' &&
            !isMobile
              ? 'padding-top: 16px !important'
              : ''
          "
        >
          <v-btn
            color="primary"
            class="mh-primary-border"
            v-if="
              typeof $route.params.uuid == 'string' &&
              $route.params.uuid.length > 0
            "
            elevation="0"
            @click="newReportFromUuid"
          >
            Новый отчет
          </v-btn>
          <v-btn
            v-else-if="loading === true"
            color="grey lighten-4"
            elevation="0"
            @click="stopCalc"
          >
            Остановить
          </v-btn>
          <v-btn
            color="primary"
            :class="
              (typeof $route.params.uuid == 'string' &&
                $route.params.uuid.length > 0) ||
              (page.type === 'show'
                ? !selectProgrammId
                : !selectedCityId || (!selectTVId && !all_channel)) ||
              !selectedSourceId
                ? ''
                : 'mh-primary-border'
            "
            v-else
            elevation="0"
            @click="cubeData"
            :disabled="
              (typeof $route.params.uuid == 'string' &&
                $route.params.uuid.length > 0) ||
              (page.type === 'show'
                ? !selectProgrammId
                : !selectedCityId || (!selectTVId && !all_channel)) ||
              !selectedSourceId
            "
          >
            Построить
          </v-btn>
        </v-col>
      </v-row>
      <v-expand-transition>
        <div v-show="expanded">
          <v-sheet color="white" height="400px"></v-sheet>
        </div>
      </v-expand-transition>
      <v-dialog v-model="dialogChangeCity" persistent max-width="350">
        <v-card>
          <v-card-title class="text-h5">
            Телеканалы не найдены в данном городе
          </v-card-title>
          <v-card-text class="body-2 black--text"
            >Некоторые из заданных ранее телеканалов отсутствуют в выбираемом
            городе. Пожалуйста, проверьте список выбранных каналов</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="black--text"
              outlined
              @click="dialogChangeCity = false"
            >
              Понятно
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogRtTatToday" persistent max-width="350">
        <v-card>
          <v-card-title class="text-h5"> Внимание </v-card-title>
          <v-card-text class="body-2 black--text">
            Данные за текущие сутки находятся в обработке. Для построения
            отчета, пожалуйста, измените период (исключите текущую дату).
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="black--text"
              outlined
              @click="dialogRtTatToday = false"
            >
              Понятно
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<style lang="scss">
.mh-clear-btn {
  margin-right: 40px;
  @media (max-width: 600px) {
    margin-right: 10px;
    margin-left: 10px;
  }
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-top: 10px;
  letter-spacing: 0.25px;
  cursor: pointer;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.58);
}
.mh-dropdown-slot {
  .v-input__slot,
  .v-input__control,
  input {
    cursor: pointer !important;
  }
}
.v-slide-group__prev {
  display: none !important;
}
.v-slide-group__next {
  display: none !important;
}
.mh-280 {
  min-width: 216px !important;
}
.periodTypes {
  max-width: 540px;
  .v-tabs-bar__content {
    div {
      padding: 0;
    }
  }
}
</style>

<script>
import axios from "axios";
import MainCalendar from "@/components/MainCalendar.vue";
import moment from "moment";
import Utils from "../services/utils";
import MhDropDown from "@/components/MhTvDropDown.vue";
import HelpBtn from "@/components/HelpBtn.vue";
import { mapActions } from "vuex";
import { CONSTS } from "../services/constants";
import ViewReportHeader from "@/components/ViewReportHeader.vue";

export default {
  name: "MainFilters",
  components: { ViewReportHeader, HelpBtn, MhDropDown, MainCalendar },
  beforeDestroy() {
    this.$eventHub.$off("loadPreset");
  },
  props: {
    reportRequest: {
      type: Object,
      default: () => {
        return {};
      },
    },
    maxChannels: {
      type: Number,
      default: 12,
    },
    startIndicator: {
      type: String,
      default: "Audience",
    },
    page: {
      type: Object,
      // Для объектов или массивов значения по умолчанию
      // должны возвращаться из функции
      default: function () {
        return {
          title: "Показатель",
          type: "default",
          many_channel: false,
        };
      },
    },
    disabledThrough: {
      type: Boolean,
      default: false,
    },
    withRange: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    aggregateVisible: {
      type: Boolean,
      default: true,
    },
    showHourly: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    toggle_demo: 3,
    dialogRtTatToday: false,
    dialogChangeCity: false,
    init: true,
    opened: false,
    tvSearch: "",
    menu2: false,
    range_str: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    all_programs: true,
    selectedSourceId: -1,
    selectedCityId: null,
    selectProgrammId: null,
    selectTVId: null,
    expanded: false,
    all_channel: false,
    visionType: "graph",
    tickLabelsLong: ["1", "5", "10", "15", "30", "60", "дд", "нед", "мм", "гг"],
    tickLabelsShort: ["1", "5", "10", "15", "30", "60", "д", "н", "м", "г"],
    tickLabelsReachLong: ["60", "дд", "нед", "мм", "гг"],
    tickLabelsReachShort: ["60", "д", "н", "м", "г"],
    chart_loading: null,
    report: "period",
    data: {},
    manytv: false,
    sql: "",
    progsNotFound: false,
    useCoeffArr: [],
    useCoeff: "no",
    radioHelpersType: "mh",
    indicator: "Audience",
    aggregate: "Day",
    demography: "",
    detalisation: 60,
    detalisationStep: 7,

    rangeAge: [4, 80],
    cities: [],
    tvss: [],
    range: null,
    shows: [],
    sourceName: "",
    selectedTV: {
      id: 1,
      name: "",
    },
    selectedCity: {
      id: 1,
      name: "",
    },
    selectedSource: {
      id: 1,
      name: "",
    },
    pageData: {
      title: "Аудиторные показатели",
    },
    reportHeader: "",
    sources: [],
  }),
  async mounted() {
    this.sources = this.$store.getters.StateUser.sources;
    if (this.page.type == "tv") {
      this.detalisationStep = 2;
    }
    if (this.page.type === "show") {
      this.aggregate = "None";
    }
    this.$eventHub.$on("loadPreset", this.loadFiltersFromPreset);
    if (
      this.page.type === "show" ||
      this.page.type === "incomes" ||
      this.page.type === "compare-tv-period"
    ) {
      if (Array.isArray(this.storedFilters.selectedTVId)) {
        // if(this.tvss.map(el=>el.num).includes(this.storedFilters.selectedTVId[0])) {
        this.selectTVId = this.storedFilters.selectedTVId[0];

        // }
      } else {
        // if(this.tvss.map(el=>el.num).includes(this.storedFilters.selectedTVId)) {
        this.selectTVId = this.storedFilters.selectedTVId;
        //  }
      }
    } else {
      if (Array.isArray(this.storedFilters.selectedTVId)) {
        // let selTVS=[];
        // let tvIds=this.tvss.map(el=>el.num);
        // this.storedFilters.selectedTVId.foreach(el=> {
        //   if(tvIds.includes(el)) {
        //     selTVS.push(el);
        //   }
        // })
        this.selectTVId = this.storedFilters.selectedTVId; //selTVS;
      } else {
        // if(this.tvss.map(el=>el.num).includes(this.storedFilters.selectedTVId)) {
        this.selectTVId = [this.storedFilters.selectedTVId];
        //}
      }
    }

    //if(this.cities.map(el=>el.num).includes(this.storedFilters.selectCityId)) {
    this.selectedCityId = this.storedFilters.selectCityId;
    //}

    this.indicator = this.startIndicator;
    let startItem = null;
    for (let j = 0; j < this.indicatorsList.length; j++) {
      if (this.indicatorsList[j].visible && !this.indicatorsList[j].disabled) {
        startItem = this.indicatorsList[j];
        break;
      }
    }

    // = this.indicatorsList.find(el=> el.visible && !el.disabled);
    if (startItem) {
      this.indicator = startItem.id;
    } else {
      this.indicator = null;
    }
    this.demography = "";

    await this.loadSource();
    // await this.getSources();
    this.init = false;
    if (this.page.type === "show" || this.page.type === "incomes") {
      this.getPrograms();
      if (this.page.type === "show") {
        this.aggregate = "None";
      }
    }

    console.log("end of mounted");
    if (!this.cities.map((el) => el.num).includes(this.selectedCityId)) {
      this.selectedCityId = null;
    }
  },
  computed: {
    sourceNameStd() {
      return process.env.VUE_APP_SOURCE_NAME;
    },
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    fixCols() {
      return "flex: 0 0 auto !important; width: auto!important; max-width: 100%!important";
    },
    demoGroup() {
      switch (this.toggle_demo) {
        case 0:
          return "male";
        case 1:
          return "female";
        case 2:
          return "child";
      }
      return "all";
    },
    isDemoDisabled() {
      let isBadIndicator =
        this.indicator === "Reach" ||
        this.indicator === "ReachPercent" ||
        this.indicator === "UuidCount" ||
        this.indicator === "SanCount" ||
        this.indicator === "UuidCountDay" ||
        this.indicator === "SanCountDay" ||
        this.indicator === "All";
      if (isBadIndicator) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.toggle_demo = 3; // сбрасываем демографические настройки
      }
      let isDisabled =
        isBadIndicator &&
        (this.radioHelpersType != "mh" ||
          this.page.type == "incomes" ||
          !this.hasDemoGroupPriv ||
          !this.hasDemoAgePriv);
      return isDisabled;
    },
    hasDemoAgePriv() {
      return !!this.$store.getters.StateUser.privs[CONSTS.PRIVS_KEYS.demo_age];
    },
    hasDemoGroupPriv() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.demo_group
      ];
    },
    minAge() {
      if (this.toggle_demo == 2) {
        return 2;
      }
      return 4;
    },
    maxAge() {
      if (this.toggle_demo == 2) {
        return 17;
      }
      return 80;
    },
    ageIcon() {
      switch (this.toggle_demo) {
        case 0:
          return "mdi-human-male";
        case 1:
          return "mdi-human-female";

        case 2:
          return "mdi-human-female-dance";

        default:
          return "mdi-human-male-female-child";
      }
    },
    ageTicks() {
      let ticks = [this.minAge];
      for (let i = this.minAge + 1; i < this.maxAge; i++) {
        if (i % (this.toggle_demo == 2 ? 6 : 16) == 0) {
          ticks.push(i);
        } else {
          ticks.push("");
        }
      }
      if (this.maxAge == 80) {
        ticks.push("80+");
      } else {
        ticks.push(this.maxAge);
      }
      return ticks;
    },
    fromAgeDemo() {
      let num = this.rangeAge[0];
      if (num == 80) {
        num = `80+`;
      }
      return num;
    },
    untilAgeDemo() {
      let num = this.rangeAge[1];
      if (num == 80) {
        num = `80+`;
      }
      return num;
    },
    indicatorText() {
      // eslint-disable-next-line no-unused-vars
      let req = {
        report: "period",
        indicator: "All",
        geo: "178",
        startDate: "2022-01-01",
        endDate: "2022-10-07",
        startTime: "00:00",
        endTime: "23:59",
        tv: "303",
        detalisation: "60",
        aggregate: "None",
        through: "true",
        weekdays: "2,3,4,5,6,7,1",
        all_channel: "false",
        geo_obj: {
          id: "178",
          num: "178",
          name_ru: "Москва",
          name_en: "Moscow",
          name_translit: null,
          name_admin: "[num:178 ЦФО Мск sdem:626 Москва]",
          name: "[num:178 ЦФО Мск sdem:626 Москва]",
          name_head: "Город федерального значения Москва",
          name_head2: "ЦФО",
        },
        tv_obj: [
          {
            id: "303",
            num: "303",
            ns1_name_ru: "Матч ТВ",
            ns1_name_en: "Match TV",
            ns1_name_admin: "[303 | 9996] Матч ТВ",
            video_channel_id: "14",
            ns2_name_ru: "1ый мультиплекс",
            ns2_name_en: "Pervyj multipleks",
            ns2_name_admin: "1ый мультиплекс",
            ns3_name_ru: null,
            ns3_name_en: null,
            ns3_name_admin: null,
            order_pos: "1",
            parent: "9996",
            reg: null,
            mplex: "mplex1",
          },
        ],
        programs_obj: [
          {
            subprogram_id: "62757449",
            start_utc0: "2021-12-31 20:55:00",
            stop_utc0: "2021-12-31 21:05:00",
            start_loc: "2021-12-31 23:55:00",
            start_loc_time: "23:55",
            stop_loc_time: "00:04",
            stop_loc: "2022-01-01 00:04:00",
            category_id: "7",
            program_id: "1341353",
            title:
              "Новогоднее обращение Президента Российской Федерации Владимира Владимировича Путина",
            subtitle: "",
            short_text:
              "По традиции в новогоднюю ночь, за 5 минут до боя курантов, президент Российской Федерации Владимир Владимирович Путин поздравит россиян с Новым годом, стоя у стен Кремля",
            id: "1341353",
            name: "Новогоднее обращение Президента Российской Федерации Владимира Владимировича Путина",
            tvid: "303",
          },
        ],
      };
      switch (this.reportRequest.indicator) {
        case "All":
          return "Сводный";
        case "Audience":
          return "Аудитория";
        case "TVR":
          return "Рейтинг";
        case "TVRTotal":
          return "Рейтинг общий";
        case "Share":
          return "Доля";
        case "Reach":
          return "Охват";
        case "ReachPercent":
          return "Охват %";
        case "Watchtime":
          return "Время просмотра";
        case "UuidCount":
          return "Кол-во приставок за час";
        case "SanCount":
          return "Кол-во домохозяйств за час";
        case "UuidCountDay":
          return "Кол-во приставок за сутки";
        case "SanCountDay":
          return "Кол-во домохозяйств за сутки";
      }
      return "";
    },
    aggregationText() {
      return Utils.aggregationName(
        this.reportRequest.aggregate,
        this.reportRequest.report
      );
    },
    showsText() {
      if (
        this.reportRequest.programs_obj &&
        this.reportRequest.programs_obj != "0"
      ) {
        return [
          ...new Set(
            this.reportRequest.programs_obj.map((el) => {
              return (
                el.title +
                " (" +
                el.start_loc_time +
                "-" +
                el.stop_loc_time +
                ")"
              );
            })
          ),
        ].join("|");
      }
      return null;
    },
    detalisationText() {
      if (this.reportRequest.report == "hour") {
        return this.reportRequest.detalisation + " мин";
      }
      return "";
    },
    reportText() {
      return this.page.title;
    },
    cityText() {
      return this.reportRequest.geo_obj.name ?? "";
    },
    periodText() {
      let start = moment(this.reportRequest.startDate, "YYYY-MM-DD")
        .locale("ru")
        .format("DD MMM YYYY");
      let end = moment(this.reportRequest.endDate, "YYYY-MM-DD")
        .locale("ru")
        .format("DD MMM YYYY");

      let period = `${start} - ${end}, ${this.reportRequest.startTime} - ${this.reportRequest.endTime}`;
      let type =
        this.reportRequest.through == "true" ? "(сквозной)" : "(посуточный)";
      let weekdaysStr = "";
      const repWeeks = this.reportRequest.weekdays.split(",");
      if (repWeeks.length) {
        let weekdays = [...repWeeks].sort();
        weekdays = weekdays.map((item) => CONSTS.WEEKDAYS[item - 1] ?? null);
        if (weekdays[0] == "вс") {
          weekdays.shift();
          weekdays.push("вс");
        }
        weekdaysStr = weekdays.join(", ");
      }

      return `${period} ${weekdaysStr} ${type}`;
    },
    channelsText() {
      if (this.reportRequest.all_channel == "true") {
        return "Все телеканалы";
      }

      return this.reportRequest.tv_obj
        .map((el) => {
          return el.ns1_name_ru;
        })
        .join(", ");
    },
    tickLabels() {
      if (!this.$vuetify.breakpoint.xl) {
        return this.tickLabelsShort;
      } else return this.tickLabelsLong;
    },
    tickLabelsReach() {
      if (!this.$vuetify.breakpoint.xl) {
        return this.tickLabelsReachShort;
      } else return this.tickLabelsReachLong;
    },
    disableField() {
      return (
        this.loading ||
        (typeof this.$route.params.uuid == "string" &&
          this.$route.params.uuid.length > 0)
      );
    },
    disabledAggregation() {
      return (
        this.loading ||
        (typeof this.$route.params.uuid == "string" &&
          this.$route.params.uuid.length > 0) ||
        (this.detalisationStep > 6 && this.page.type == "tv")
      );
    },
    isReachOrSan() {
      return (
        this.indicator === "Reach" ||
        this.indicator === "ReachPercent" ||
        this.indicator === "UuidCount" ||
        this.indicator === "SanCount"
      );
    },
    isXl() {
      return this.$vuetify.breakpoint.lgAndDown;
    },
    defaultCols3() {
      if (this.isXl) {
        return 6;
      }
      return 3;
    },
    isPreviewHeader() {
      return (
        typeof this.$route.params.uuid == "string" &&
        this.$route.params.uuid.length > 0
      );
    },
    defaultCols2() {
      if (this.isXl) {
        return 4;
      }
      return 2;
    },
    storedFilters() {
      return this.$store.getters.getFilters;
    },
    isMobile() {
      return !this.$vuetify.breakpoint.lgAndUp;
    },
    tvs() {
      if (this.all_channel) {
        return [];
      }
      let items = this.tvss;
      if (
        (this.page.type === "show" ||
          this.page.type === "compare-tv" ||
          this.page.type === "compare-tv-period") &&
        this.selectedCityId == 1143
      ) {
        items = items.filter((el) => {
          return el.mplex === null && el.reg === null;
        });
      }
      return items;
    },
    selectTvCount() {
      if (typeof this.selectTVId !== "string") {
        if (this.selectTVId === null) {
          return 0;
        }
        return this.selectTVId.length;
      }
      return 1;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    indicatorsList() {
      const page = Object.assign({}, this.page);
      page.all_channel = this.all_channel;
      return Utils.indicatorsList(
        this.radioHelpersType,
        page,
        this.$store.getters.StateUser.indicators,
        this.report
      );
    },
    demographyList() {
      let list = [
        {
          id: "",
          name: "Без группировки",
          disabled: false,
          visible: true,
        },
        {
          id: "Demo",
          name: "По полу",
          priv_id: CONSTS.PRIVS_KEYS.sdem_gender,
          disabled: false,
          visible: true,
        },
        {
          id: "DemoF",
          name: "Женщины по возрасту",
          disabled: true,
          visible: true,
          priv_id: CONSTS.PRIVS_KEYS.sdem_female_age,
        },
        {
          id: "DemoM",
          name: "Мужчины по возрасту",
          disabled: true,
          visible: true,
          priv_id: CONSTS.PRIVS_KEYS.sdem_male_age,
        },
      ];
      for (let ind in this.$store.getters.StateUser.indicators) {
        let el_index = list.findIndex((el) => el.priv_id == ind);
        if (el_index != -1) {
          switch (this.$store.getters.StateUser.indicators[ind]) {
            case 0:
              list[el_index].visible = false;
              break;
            case 1:
              list[el_index].visible = true;
              break;
            default:
              list[el_index].visible = true;
              list[el_index].disabled = true;
              break;
          }
        }
      }
      list = list.filter((el) => {
        return el.visible;
      });
      return list;
    },
    aggregationList() {
      let list = [
        {
          id: "Day",
          name: this.report === "hour" ? "По дням" : "День",
        },
        {
          id: "Week",
          name: "Неделя",
        },
        {
          id: "Month",
          name: "Месяц",
        },
        {
          id: "Year",
          name: "Год",
        },
        {
          id: "Period",
          name: "Период",
        },
      ];

      if (this.page.type === "show") {
        const data = [
          {
            id: "None",
            name: "Без группировки",
          },
          {
            id: "Program",
            name: "По передаче",
          },
          {
            id: "ProgramTime",
            name: "По передаче и времени выхода",
          },
          {
            id: "ProgramSubprogram",
            name: "По передаче и серии",
          },
          {
            id: "ProgramSubprogramTime",
            name: "По передаче, серии и времени выхода",
          },
        ];
        this.$emit("aggregations", data);
        return data;
      }
      if (this.report === "hour") {
        const data = list.filter((el) => {
          return el.id === "Day" || el.id === "Period";
        });
        this.$emit("aggregations", data);
        return data;
      }

      const data = list.filter((el) => {
        return el.id !== "Period";
      });
      this.$emit("aggregations", data);
      return data;
    },
    startTime() {
      try {
        return moment(this.range.start).format("HH:mm");
      } catch (e) {
        return "";
      }
    },
    endTime() {
      try {
        return moment(this.range.end).format("HH:mm");
      } catch (e) {
        return "";
      }
    },
  },
  watch: {
    toggle_demo() {
      this.rangeAge = [this.minAge, this.maxAge];
    },
    selectProgrammId() {
      if (this.page.type === "show" || this.page.type === "incomes") {
        if (this.selectProgrammId == "") {
          this.selectProgrammId = null;
        }
      }
      this.saveFilters();
    },
    radioHelpersType() {
      this.useCoeff = "no";
      this.indicator = "Watchtime";
      this.getCities();
      this.saveFilters();
      // this.getTvs();
    },
    indicator() {
      if (
        this.indicator === "UuidCountDay" ||
        this.indicator === "SanCountDay"
      ) {
        this.report = "period";
      }
      if (
        this.indicator === "Reach" ||
        this.indicator === "ReachPercent" ||
        this.indicator === "UuidCount" ||
        this.indicator === "SanCount"
      ) {
        if (this.page.type == "tv") {
          this.detalisationStep = 6;
        }
      }
      if (this.indicator !== "Audience") {
        this.demography = "";
      }
    },
    async selectedCityId(val, oldval) {
      console.log("watch city");
      let parent = null;
      let selElements = [];
      let fullLast5tvs = [];
      let elementsNotFound = [];
      let last5tvs = this.$store.getters.last5tvs;
      if (val == null && oldval !== null && !this.init) {
        this.selectTVId = null;
        this.storeLastTvs([]);
      }
      if (val !== oldval && !this.init) {
        if (
          (this.page.type === "tv" || this.page.type === "compare-tv") &&
          Array.isArray(this.storedFilters.selectedTVId)
        ) {
          // this.selectTVId = null;
          selElements = this.tvss.filter((el) =>
            this.selectTVId.includes(el.id)
          );
          if (selElements) {
            parent = selElements.map((el) => {
              return el.parent;
            });
          } else {
            this.selectTVId = null;
          }
        } else {
          let filterEL = this.tvss.find((el) => el.id == this.selectTVId);
          if (filterEL) {
            parent = filterEL.parent;
          } else {
            this.selectTVId = null;
          }
        }

        fullLast5tvs = this.tvss.filter((el) => last5tvs.includes(el.id));
        if (fullLast5tvs.length === 0) {
          last5tvs = [];
        }
      }
      await this.getTvs();
      if (parent) {
        if (
          (this.page.type === "tv" || this.page.type === "compare-tv") &&
          Array.isArray(this.storedFilters.selectedTVId)
        ) {
          let selElementsNew = this.tvss.filter((el) =>
            parent.includes(el.parent)
          );
          if (selElementsNew) {
            this.selectTVId = selElementsNew.map((el) => {
              return el.id;
            });
          } else {
            this.selectTVId = null;
          }
          if (selElements.length != selElementsNew.length) {
            elementsNotFound = selElements
              .map((el) => {
                return el.parent;
              })
              .filter(
                (el) =>
                  !selElementsNew
                    .map((elIn) => {
                      return elIn.parent;
                    })
                    .includes(el)
              );
            elementsNotFound = selElements.filter((el) =>
              elementsNotFound.includes(el.parent)
            );
            this.dialogChangeCity = true;
          }
        } else {
          let filterElnew = this.tvss.find((el) => el.parent == parent);
          if (filterElnew) {
            this.selectTVId = [filterElnew.id];
          } else {
            this.dialogChangeCity = true;
            this.selectTVId = null;
          }
        }
      }
      if (fullLast5tvs.length > 0) {
        fullLast5tvs = fullLast5tvs.map((el) => {
          return el.parent;
        });
        last5tvs = this.tvss.filter((el) => fullLast5tvs.includes(el.parent));
        last5tvs = last5tvs.map((el) => {
          return el.id;
        });
      }
      this.$eventHub.$emit("invalidTVSbyCity", elementsNotFound);
      await this.storeLastTvs(last5tvs);

      if (this.page.type === "show" || this.page.type === "incomes") {
        this.getPrograms();
      }
      this.saveFilters();
    },

    selectTVId() {
      console.log("watch tv");
      // this.all_programs = false;
      if (this.selectTVId == "") {
        this.selectTVId = null;
        if (this.indicator === "TVRTotal") {
          this.indicator = "TVR";
        }
      }
      if (typeof this.selectTVId === "string") {
        this.manytv = false;
      }
      if (this.page.type === "show" || this.page.type === "incomes") {
        this.getPrograms();
        this.manytv = false;
      }
      this.saveFilters();
    },
    all_channel() {
      if (this.all_channel) {
        this.manytv = true;
      } else {
        this.manytv = false;
      }
    },
    useCoeffArr() {
      if (this.useCoeffArr.length === 0) {
        this.useCoeff = "no";
      } else if (this.useCoeffArr.length > 1) {
        this.useCoeff = "extrahour";
      } else {
        this.useCoeff = this.useCoeffArr[0];
      }
    },
    detalisationStep() {
      if (this.detalisationStep > 6) {
        this.report = "period";
        this.detalisation = 60;
        switch (this.detalisationStep) {
          case 7:
            this.aggregate = "Day";
            break;
          case 8:
            this.aggregate = "Week";
            break;
          case 9:
            this.aggregate = "Month";
            break;
          case 10:
            this.aggregate = "Year";
            break;
        }
      } else {
        this.report = "hour";
        if (this.aggregate != "Day" && this.aggregate != "Period") {
          this.aggregate = "Day";
        }
        this.detalisation = parseInt(
          this.tickLabels[this.detalisationStep - 1]
        );
        if (this.all_channel) {
          this.$refs.tvSel.selectAll();
        }
      }
    },

    report() {
      switch (this.detalisationStep) {
        case 7:
          this.aggregate = "Day";
          break;
        case 8:
          this.aggregate = "Week";
          break;
        case 9:
          this.aggregate = "Month";
          break;
        case 10:
          this.aggregate = "Year";
          break;
      }
      if (this.report !== "hour") {
        //this.detalisation = 60;
        //  this.detalisationStep = 6;
      } else {
        //this.detalisation = 5;
        //this.detalisationStep = 2;
        if (this.indicator === "All" || this.indicator === "TVRTotal") {
          this.indicator = "Audience";
        }
      }
    },
    selectedSourceId(val, oldval) {
      if (val != oldval) {
        if (oldval != -1) {
          this.selectedCityId = null;
          this.selectTVId = null;
          this.selectProgrammId = null;
        }
        this.getCities();
        let source = this.sources.find((s) => {
          return s.id == this.selectedSourceId;
        });
        if (source) {
          this.sourceName = source.name;
        } else {
          this.sourceName = "";
        }
      }
      this.saveFilters();
    },
  },
  methods: {
    ...mapActions([
      "storeFilters",
      "storeCities",
      "storeTvs",
      "storePrograms",
      "storeLastTvs",
    ]),

    fromAgeDemoInput(val) {
      let num = 0;
      if (val != "" && val != null) {
        num = parseInt(val);
      }
      if (num == 0) {
        num = this.minAge;
      } else if (num < this.minAge) {
        num = this.minAge;
      } else if (num > this.maxAge) {
        num = this.maxAge;
      }
      this.rangeAge = [num, this.rangeAge[1]];
    },
    untilAgeDemoInput(val) {
      let num = 0;
      if (val != "" && val != null) {
        num = parseInt(val);
      }
      if (num == 0) {
        num = this.maxAge;
      } else if (num < this.minAge) {
        num = this.minAge;
      } else if (num > this.maxAge) {
        num = this.maxAge;
      }
      this.rangeAge = [this.rangeAge[0], num];
    },
    async loadSource() {
      this.$emit("sources", this.sources);
      if (this.storedFilters.selectedSourceId) {
        let splits = this.storedFilters.selectedSourceId.split("%");
        this.selectedSourceId = splits[0];
        if (this.selectedSourceId == 2 || this.selectedSourceId == 10) {
          this.radioHelpersType = splits[1];
        } else {
          this.radioHelpersType = "mh";
        }
        let source = this.sources.find((s) => {
          return s.id == this.selectedSourceId;
        });

        if (source) {
          this.sourceName = source.name;
        } else {
          this.selectedSourceId = this.sources[0].id;
          this.radioHelpersType = "mh";
        }
      } else {
        this.selectedSourceId = this.sources[0].id;
        this.radioHelpersType = "mh";
      }
      this.cities = this.$store.getters.getCitiesList;
      if (this.cities.length == 0) {
        await this.getCities();
      }
      this.$emit("cities", this.cities);
      this.tvss = this.$store.getters.getTvsList;
      if (this.tvss.length == 0 && this.selectedCityId) {
        await this.getTvs();
      }
      this.$emit("tvList", this.tvss);
    },
    async clearAllFilters() {
      await this.$refs.mcalendar.initCalendar(true);
      this.all_programs = true;
      this.selectedCityId = null;
      this.selectProgrammId = null;
      this.selectTVId = null;
      this.loading = false;
      this.manytv = false;
      this.progsNotFound = false;
      this.useCoeffArr = [];
      this.useCoeff = "no";
      this.radioHelpersType = "mh";
      this.loadSource();

      this.indicator = this.startIndicator;
      let startItem = null;
      for (let j = 0; j < this.indicatorsList.length; j++) {
        if (
          this.indicatorsList[j].visible &&
          !this.indicatorsList[j].disabled
        ) {
          startItem = this.indicatorsList[j];
          break;
        }
      }
      this.indicator = startItem.id;
      this.demography = "";
      this.init = false;
      this.aggregate = "Day";
      if (this.page.type == "tv") {
        this.detalisationStep = 2;
      }
      this.all_channel = false;
      this.visionType = "graph";
      this.data = {};
      // this.getSources();
      if (this.page.type === "show" || this.page.type === "incomes") {
        this.getPrograms();
        if (this.page.type === "show") {
          this.aggregate = "None";
        }
      }
    },
    loadFiltersFromPreset(json) {
      console.log(json);
    },

    async saveFilters() {
      await this.storeFilters({
        selectCityId: this.selectedCityId,
        selectedTVId: this.selectTVId,
        selectProgrammId: this.selectProgrammId,
        selectedSourceId: this.selectedSourceId + "%" + this.radioHelpersType,
      });
    },
    showSelectAll() {
      return (
        this.page.type !== "show" &&
        this.page.type !== "compare-tv" &&
        this.page.type !== "compare-tv-period" &&
        this.page.type !== "incomes"
      );
    },
    allChannel(e) {
      this.all_channel = e;
      this.selectTVId = null;
      if (e) {
        if (this.detalisationStep <= 6) {
          this.report = "period";
          this.detalisationStep = 10;
          this.detalisation = 60;
        }
      }
    },
    filterAutoComplete(item, queryText, itemText) {
      return Utils.filterAutoComplete(item, queryText, itemText);
    },
    selectProgram() {
      if (this.page.type === "incomes") {
        let prog = this.shows.find(
          (value) => value.id === this.selectProgrammId
        );
        this.$emit("programSelected", prog);
      }
    },
    stopCalc() {
      this.loading = false;
      this.$emit("stopCalc");
      this.$forceUpdate();
    },
    newReportFromUuid() {
      const url = this.$route.path.substring(
        0,
        this.$route.path.lastIndexOf("/")
      );
      this.stopCalc();
      this.$router.push(url);
    },
    async checkTvCount() {
      if (typeof this.selectTVId !== "string") {
        if (this.selectTVId !== null) {
          if (this.selectTVId.length > this.maxChannels)
            this.selectTVId = this.selectTVId.slice(0, this.maxChannels);
        }
      }
    },
    async selectAllProgram(e) {
      this.all_programs = e; //!this.all_programs;

      if (this.all_programs) {
        this.selectProgrammId = [];
        this.shows.forEach((sh) => {
          this.selectProgrammId.push(sh.id);
        });
      } else {
        this.selectProgrammId = [];
      }
    },
    async cubeData() {
      // исключаем возможность построить отчет по РТ+Тат за текущие сутки
      if (
        moment(this.range.end).format("YYYY-MM-DD") ===
          moment().format("YYYY-MM-DD") &&
        this.selectedSourceId == 12
      ) {
        this.dialogRtTatToday = true;
        return;
      }
      // this.chart_loading = true;
      let reqData = new FormData();
      reqData.append("helper", this.radioHelpersType);
      reqData.append(
        "manytv",
        this.indicator !== "TVRTotal" ? this.manytv : false
      );
      reqData.append("source_id", this.selectedSourceId);
      let demo = "";
      if (this.indicator === "Audience") {
        demo = this.demography;
      }
      reqData.append("indicator", this.indicator + demo);
      reqData.append("geo", this.selectedCityId);
      reqData.append("tv", this.selectTVId);
      reqData.append("detalisation", this.detalisation);
      reqData.append("demoGroup", this.demoGroup);
      reqData.append("demoFrom", this.rangeAge[0] ?? 4);
      reqData.append("demoTo", this.rangeAge[1] ?? 80);
      reqData.append("detalisation", this.detalisation);
      reqData.append(
        "aggregate",
        (this.all_channel && this.indicator !== "TVRTotal") ||
          !this.aggregateVisible
          ? "Period"
          : this.aggregate
      );
      reqData.append(
        "startDate",
        moment(this.range.start).format("YYYY-MM-DD")
      );
      reqData.append("endDate", moment(this.range.end).format("YYYY-MM-DD"));
      if (
        this.indicator === "UuidCountDay" ||
        this.indicator === "SanCountDay"
      ) {
        reqData.append("startTime", "00:00");
        reqData.append("endTime", "00:01");
        reqData.append("through", false);
      } else {
        reqData.append("startTime", moment(this.range.start).format("HH:mm"));
        reqData.append("endTime", moment(this.range.end).format("HH:mm"));
        reqData.append("through", this.range.through);
      }

      reqData.append("weekdays", this.range.weekdays);
      reqData.append("report_type", this.report);
      reqData.append("all_channel", this.all_channel);
      reqData.append("useCoeff", this.useCoeff);
      reqData.append("programIds", this.selectProgrammId ?? 0);
      // let res = await axios.post("reports/cubedata", reqData);
      // if (this.selectTvCount > 1 && this.$route.name !== "compare-tv") {
      //   this.$emit("cubeDataMultiClick", reqData);
      // } else {
      this.$emit("cubeDataClick", reqData);
      // }

      // this.data = res.data;
      // this.chart_loading = false;
    },
    calendarHandler(range) {
      this.range = range;
      this.$emit("calendarRange", range);
      // this.all_programs = false;
      if (this.page.type === "show" || this.page.type === "incomes") {
        this.getPrograms();
      }
    },
    calendarOk() {
      this.menu2 = false;
    },
    async getPrograms() {
      this.shows = [];
      this.selectProgrammId = null;

      let api_url = "/params/programs";
      if (this.page.type === "incomes") {
        api_url = "/params/fullprograms";
      }
      if (this.selectTVId) {
        if (Array.isArray(this.selectTVId)) {
          this.selectTVId = this.selectTVId[0];
        }
        let res = await axios.post(api_url, {
          city_id: this.selectedCityId,
          tv_id: this.selectTVId,
          startDate: moment(this.range.start).format("YYYY-MM-DD"),
          startTime: moment(this.range.start).format("HH:mm"),
          endDate: moment(this.range.end).format("YYYY-MM-DD"),
          endTime: moment(this.range.end).format("HH:mm"),
        });
        if (res.data && Array.isArray(res.data)) {
          this.shows = [...res.data];
          this.$emit("shows", this.shows);
          // await this.storePrograms(this.shows);
          if (this.all_programs && this.page.type !== "incomes") {
            await this.selectAllProgram(this.all_programs);
          }
        }
      }
    },
    async getTvs() {
      //this.selectTVId = null;
      let res = await axios.post("/params/tvs", {
        source_id: this.selectedSourceId,
        helper: this.radioHelpersType,
        city_id: this.selectedCityId,
      });
      if (res.data && Array.isArray(res.data)) {
        this.tvss = [...res.data];
        for (const item of this.tvss) {
          item.export_name = this.isAdmin
            ? item.ns1_name_admin
            : item.ns1_name_ru;
        }
        this.$emit("tvList", this.tvss);
        await this.storeTvs(this.tvss);
      }
    },
    async getCities() {
      let res = await axios.post("/params/cities", {
        source_id: this.selectedSourceId,
        helper: this.radioHelpersType,
      });
      if (res.data && Array.isArray(res.data)) {
        this.cities = [...res.data];
        this.$emit("cities", this.cities);
        await this.storeCities(this.cities);
      }
    },
    // async getSources() {
    //   this.cities = this.$store.getters.getCitiesList;
    //   if (this.cities.length == 0) {
    //     await this.getCities();
    //   }
    //   this.$emit("cities", this.cities);
    //   this.tvss = this.$store.getters.getTvsList;
    //   if (this.tvss.length == 0 && this.selectedCityId) {
    //     await this.getTvs();
    //   }
    //   this.$emit("tvList", this.tvss);
    //   let res = await axios.post("/params/sources");
    //   if (res.data) {
    //     this.sources = [...res.data];
    //     this.$emit("sources", this.sources);
    //     if (this.storedFilters.selectedSourceId) {
    //       let splits = this.storedFilters.selectedSourceId.split("%");
    //       this.selectedSourceId = splits[0];
    //       if (this.selectedSourceId == 2 || this.selectedSourceId == 10) {
    //         this.radioHelpersType = splits[1];
    //       } else {
    //         this.radioHelpersType = "mh";
    //       }
    //       let source = this.sources.find((s) => {
    //         return s.id == this.selectedSourceId;
    //       });
    //
    //       if (source) {
    //         this.sourceName = source.name;
    //       } else {
    //         this.selectedSourceId = this.sources[0].id;
    //         this.radioHelpersType = "mh";
    //       }
    //     } else {
    //       this.selectedSourceId = this.sources[0].id;
    //       this.radioHelpersType = "mh";
    //     }
    //   }
    // },
  },
};
</script>

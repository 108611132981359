<template>
  <v-app
    :style="
      isAuth
        ? isAlternate
          ? 'background: #D2D2D2;'
          : 'background: #EEEEEE;'
        : ''
    "
    :class="'mh-app '"
  >
    <v-app-bar
      v-if="isAuth"
      clipped-left
      clipped-right
      :prominent="isShowBanner"
      app
      :color="isAlternate ? 'secondary' : 'primary'"
      flat
      :class="'pl-0 '"
    >
      <v-system-bar
        style="position: absolute; width: 100%; left: 0; top: 0"
        v-if="isShowBanner"
        height="64"
        color="#FFF3E0"
      >
        <p class="banner-text ma-0">
          Уважаемые пользователи! По причине технического сбоя у поставщиков данных в ряде регионов статистика поступает с задержкой. Ожидаемое время  возвращения к работе в реальном времени - 23 ноября.
        </p>
      </v-system-bar>

      <v-row
        align="center"
        :class="'inner-v-app-banner ' + (isShowBanner ? 'mt-14' : '')"
      >
        <div class="mh-menu-btn" v-if="isAuth && isMobile">
          <v-btn icon color="white" @click="drawer = !drawer"
            ><v-icon> mdi-menu </v-icon></v-btn
          >
        </div>
        <v-app-bar-nav-icon v-if="!isAlternate" :to="!isMobile ? '/' : ''">
          <svg
            width="34"
            height="33"
            viewBox="0 0 34 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.4167 1.00286C17.6649 0.351231 16.5485 0.351233 15.7968 1.00286L1.17231 13.6793C-0.226637 14.8919 0.630945 17.1906 2.48228 17.1906H4.50781V31C4.50781 32.1045 5.40324 33 6.50781 33H27.7624C28.867 33 29.7624 32.1045 29.7624 31V17.1906H31.7312C33.5825 17.1906 34.4401 14.8919 33.0411 13.6793L18.4167 1.00286ZM19.0554 7.71503C18.551 7.22882 17.7568 7.11593 17.1282 7.47876C16.6026 7.78396 16.3195 8.34925 16.3375 8.92279L15.2982 18.9356L15.1536 18.7118C14.6679 17.9801 13.6719 17.7649 12.9279 18.2383C12.5781 18.4677 12.3463 18.815 12.2515 19.1972L10.4026 24.3926L9.71301 23.157C9.27648 22.3885 8.29275 22.1118 7.5242 22.5361C6.75566 22.9664 6.47898 23.9502 6.90322 24.7187L9.32567 29.0594C9.7622 29.8341 10.7459 30.1108 11.5145 29.6804C11.8714 29.4805 12.1236 29.1613 12.2482 28.7996C12.2495 28.7961 12.2508 28.7925 12.252 28.789L14.2316 23.2154L15.0676 24.5097C15.5533 25.2475 16.5555 25.4626 17.2933 24.9831C17.4732 24.8671 17.6218 24.7205 17.737 24.5545C17.8925 24.3371 17.9955 24.0786 18.0253 23.7965L19.0468 13.9549L20.27 16.0495C20.7189 16.8119 21.7087 17.0702 22.4711 16.6275C22.7889 16.4404 23.019 16.1605 23.1474 15.8419L23.8583 14.4622L25.4519 16.3385C26.0237 17.0087 27.0443 17.0947 27.7145 16.5229C28.3908 15.9511 28.4707 14.9305 27.9051 14.2542L24.751 10.5406C24.1792 9.87658 23.1586 9.79051 22.4823 10.3562C22.2873 10.524 22.1422 10.7305 22.0488 10.9557L21.5656 11.8902L19.4234 8.22797C19.3356 8.03459 19.2101 7.86008 19.0554 7.71503Z"
              fill="white"
            />
          </svg>
        </v-app-bar-nav-icon>
        <v-app-bar-title
          v-if="!isMobile && !isAlternate"
          class="white--text pl-2 text-no-wrap"
          style="width: 200px"
          >Личный кабинет
        </v-app-bar-title>
        <p
          v-if="!isMobile && isAlternate"
          :class="'pl-0 ml-n4  mb-0 ' + (isShowBanner ? 'mt-1' : 'mt-0')"
          style="width: 290px"
        >
          <router-link to="/"
            ><v-img :src="require('@/assets/statlogo.svg')" height="64"
          /></router-link>
        </p>
        <v-spacer></v-spacer>
        <v-btn
          :x-small="isMobile"
          style="pointer-events: none; text-transform: lowercase !important"
          elevation="0"
          color="grey lighten-4"
          ><v-icon v-if="!isMobile && false">mdi-cog</v-icon>{{ email }}</v-btn
        >
        <v-spacer v-if="isMobile"></v-spacer>
        <v-btn
          elevation="0"
          :tile="$vuetify.breakpoint.lgAndDown"
          :icon="$vuetify.breakpoint.lgAndDown"
          text
          @click="menuActionClick('logout')"
          color="white"
          :class="!$vuetify.breakpoint.lgAndDown ? 'ml-6 ' : 'ml-2 '"
          ><v-icon>mdi-logout</v-icon
          >{{ !$vuetify.breakpoint.lgAndDown ? "Выход" : "" }}</v-btn
        >
      </v-row>
    </v-app-bar>
    <app-navigation
      :drawer="drawer"
      :mini="mini"
      :menuActionClick="menuActionClick"
    />

    <v-main class="mb-0">
      <preloader />
      <v-dialog persistent v-model="dialogError" max-width="590">
        <v-card class="pb-2">
          <v-card-text class="body-2 black--text pt-6 pb-1">
            <v-row class="justify-start justify-sm-space-around">
              <v-col
                v-if="$vuetify.breakpoint.width > 450"
                cols="auto"
                class="pr-1 pl-1"
              >
                <v-icon large color="error">mdi-information</v-icon>
              </v-col>
              <v-col cols="auto" class="pr-1 pl-1"> </v-col>
              <v-col cols="auto" class="pr-1 pl-1">
                <p>
                  При построении отчета возникла ошибка.<br />
                  Пожалуйста, обратитесь в службу поддержки
                </p>
              </v-col>
              <v-col cols="auto">
                <v-btn class="black--text" outlined @click="onErrorHandler">
                  Понятно
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <router-view :key="$route.fullPath" />
      <v-snackbar
        v-model="snackbarNewReport"
        :timeout="2000"
        right
        color="success"
        class="mb-16"
      >
        Отчет построен
      </v-snackbar>
    </v-main>
  </v-app>
</template>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab");
.inner-v-app-banner {
  width: 100%;
  height: 64px;
  margin: 0 !important;
  padding: 0 !important;
}
.show_banner {
  margin-top: 64px !important;
}
.banner-text {
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: rgba(230, 81, 0, 1)!important;
  @media (max-width: 300px) {
    font-size: 10px;
    line-height: 14px;
  }
  @media (max-width: 500px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media (max-width: 800px) {
    font-size: 12px;
    line-height: 16px;
  }

  /* identical to box height, or 167% */

  letter-spacing: 0.4px;

  /* black/0.87 */

  color: rgba(0, 0, 0, 0.87);
}
.mh-special-title-row {
  margin-top: 0;
}
.mh-age-title {
  position: absolute;
  left: 90px;
}
.mh-range-caption {
  color: rgba(0, 0, 0, 0.38);
  position: absolute;
  margin-top: 10px;
  margin-left: 5px;
}
.mh_age_helper {
  max-width: 60px;
  input {
    text-align: right;
  }
}
.black38 {
  color: rgba(0, 0, 0, 0.38);
}
.mh-border-block {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
body,
div,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  word-break: break-word !important;
}
.w100-28 {
  width: calc(100% - 28px);
  background: white;
}
.upTo5 {
  margin-top: -4px;
}
.upTo4 {
  margin-top: -4px;
}
.upTo2 {
  margin-top: -2px;
}
$mh-mobile-bp: 600px;
.v-slider__tick-label {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height, or 120% */

  letter-spacing: 0.4px;

  color: #000000;
}
.pageTitle {
  font-weight: bold;
  color: black;
}
.mh-elevation-4 {
  box-shadow: 0 2px 4px -9px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
  z-index: 2;
  margin-right: 1px;
}
.chartHelper {
  padding: 4px 16px 4px 8px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 16px;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  &.help-btn {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
  }
}

.grey-lighten-4 {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
}
.mh-cards-export {
  .mh-card-subtitle {
    font-size: 15px !important;
    line-height: 1rem !important;
    height: 2rem !important;
  }
}
.v-application .v-btn.mh-primary-border {
  border: 1px solid #0d47a1 !important;
  @if $VUE_APP_ALTERNATE_UI == true {
    border: 1px solid #d50000 !important;
  }
}
.v-application .v-btn.mh-secondary-border {
  border: 1px solid #4cae4c !important;
  @if $VUE_APP_ALTERNATE_UI == true {
    border: 1px solid #64004b !important;
  }
}
.v-application .v-btn.mh-tertiary-border {
  border: 1px solid rgba(0, 0, 0, 0.87) !important;
}
.v-application .v-btn {
  text-transform: capitalize !important;
}
html,
.mh-app.v-application,
.mh-app.v-application .v-btn,
.mh-app.v-application .v-card__text,
.mh-app.v-application .v-list-item,
.mh-app.v-application .v-tab,
.v-application .body-2 {
  letter-spacing: 0 !important;
}
.mh-app.v-application .v-card__title,
.mh-app.v-application .v-btn {
  font-weight: 600 !important;
}
.mh-app {
  .v-autocomplete.v-select.v-input--is-focused input {
    min-width: 40px;
  }
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group)
  .v-btn.v-btn.mh-noactive-toggle {
  border-color: rgba(0, 0, 0, 1) !important;
}
.v-btn__content {
  line-height: unset !important;
}
.v-btn-toggle {
  .mh-noactive-toggle {
    color: black !important;
    background: white !important;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
    &.v-ripple__container {
      opacity: 0;
    }
    &.v-btn {
      border-color: rgba(0, 0, 0, 1) !important;
      &.theme--light,
      &.v-btn-toggle,
      &:not(.v-btn-toggle--group) {
        border-color: rgba(0, 0, 0, 1) !important;
      }
    }
    &:not(.mh-active-toggle) {
      .v-btn {
        border: 1px solid black !important;
      }
    }
  }
  .mh-active-toggle {
    background: #81c784 !important;
    color: black !important;
    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.5) !important;
    &:before {
      background-color: unset !important;
      border: 1px solid black !important;
    }
    &.v-btn {
      border: 0px solid rgba(0, 0, 0, 1) !important;
    }
    @if $VUE_APP_ALTERNATE_UI == true {
      background: #80deea !important;
    }
  }
}
.mh-tabs-div {
  position: absolute;
  margin-top: 32px;
  height: 1px;
  width: 100%;
  left: 0;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.mh-demo-tabs {
  .v-tabs-slider-wrapper {
    height: 0 !important;
  }
  .mh-tab {
    position: relative;
    box-sizing: border-box;
    //border-top: thin solid rgba(0, 0, 0, 0.12);
    //border-left: thin solid rgba(0, 0, 0, 0.12);
    //border-right: thin solid rgba(0, 0, 0, 0.12);
    margin-right: 2px;
    margin-left: unset !important;
    background: transparent !important;
    padding-left: 16px;
    font-weight: 400 !important;
    padding-right: 30px;
    text-transform: none;
    &:hover {
      background: transparent !important;
    }
    .v-ripple__container {
      display: none !important;
    }
  }
  .mh-tab.v-tab--active {
    background-color: white !important;
  }
  .mh-tab.preset {
    max-width: 110px;
  }
  .v-tabs-bar {
    height: 33px;
  }

  .mh-tab:before {
    content: "";
    position: absolute;
    height: calc(100% + 2px);
    width: calc(100% - 13px);
    top: 0;
    opacity: 1;
    background-color: transparent !important;

    left: 0;
    border-top: thin solid rgba(0, 0, 0, 0.12);
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  .mh-tab:after {
    content: "";
    position: absolute;
    height: 35px;
    width: 14px;
    top: 0px;
    right: 0px;
    //border-top: 1px solid white;
    //border-right: 1px solid white;
    background: url("~@/assets/line.svg");
  }
  .mh-tab.v-tab--active {
    z-index: 1;
  }
  .mh-tab.v-tab--active.v-tab:not(:focus)::before {
    opacity: 0.12;
  }
  .mh-tab.v-tab:hover {
    opacity: 1 !important;
  }
  .mh-tab.v-tab:hover:before {
    content: "";
    position: absolute;
    opacity: 1 !important;
    height: calc(100% + 2px);
    width: calc(100% - 13px);
    top: 0;
    left: 0;
    //border-top: thin solid rgba(0, 0, 0, 1) !important;;
    //border-left: thin solid rgba(0, 0, 0, 1) !important;
  }
  .mh-tab.v-tab--active::before {
    content: "";
    background-color: transparent !important;
    position: absolute;
    height: calc(100% + 2px);
    width: calc(100% - 13px);
    top: 0;
    opacity: 1 !important;
    left: 0;
    //border-top: thin solid rgba(0, 0, 0, 1);
    //border-bottom: 2px solid rgba(255,0, 0, 1);
    //border-left: thin solid rgba(0, 0, 0, 1);
  }
  .v-tabs-bar {
    background-color: transparent !important;
  }
  .mh-tab.v-tab--active::after {
    content: "";
    position: absolute;
    height: 35px;
    width: 14px;
    top: 0;
    right: 0;
    z-index: 1;
    //border-top: 1px solid white;
    //border-right: 1px solid white;
    background: url("~@/assets/line.svg");
  }
}
.mh-format-card {
  cursor: pointer;
  display: flex;
  width: 86px;
  height: 106px;
  left: 20px;
  top: 95px;

  /* shades/white */

  background: #ffffff;
  /* black/0.38 */

  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 4px;
}
.v-navigation-drawer {
  .v-list-item__icon {
    margin-right: 24px;
  }
}
.mh-drawer {
  z-index: 6 !important;
}
.v-app-bar-title__content {
  width: 200px !important;
}
.sublevel1 {
}
.mh-28 {
  max-height: 28px;
}
.mh-page-title {
  @media (max-width: $mh-mobile-bp) {
    //padding-left: 32px;
  }
}
#app {
  .v-main {
    .container {
      @media (max-width: $mh-mobile-bp) {
        padding: 0;
        .mh-main-row {
          margin-left: 0;
          margin-right: 0;
          .mh-main-col {
            padding-left: 0;
            padding-right: 0;
          }
        }
        .v-card__text {
          padding: 0;
        }
        .v-card {
          .row {
            &.ma-1 {
              display: block;
              .col {
                width: 100% !important;
                max-width: 100% !important;
                flex: unset !important;
              }
            }
          }
          .mh-btn-row {
            padding-bottom: 24px !important;
          }
          .mh-detalisation-row {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>

<script>
import router from "@/router";
import axios from "axios";
import AppNavigation from "@/components/AppNavigation.vue";
import Preloader from "@/components/Preloader";
import { CONSTS } from "./services/constants";
export default {
  name: "App",
  components: { AppNavigation, Preloader },
  watch: {
    isError() {
      this.dialogError = this.isError;
    },
    isMobile() {
      this.mini = false;
    },
    $route: {
      immediate: true,
      handler(to) {
        this.$store.commit("hideLoader");
        document.title = to.meta.title || process.env.VUE_APP_APP_NAME;
        this.valuesCheckboxState = false;
      },
    },
  },

  methods: {
    async checkUnreadingReports() {
      if (this.$store.getters.isAuthenticated) {
        let res = await axios.post(`/unreading`);
        if (res.data || res.data == 0) {
          const count = res.data;
          if (count > this.$store.getters.unreadingCount) {
            this.snackbarNewReport = true;
          }
          await this.$store.dispatch("saveUnreadingCount", count);
        }
      }
    },
    async timeOutUnreadingReports() {
      await this.checkUnreadingReports();
      if (this.$store.getters.isAuthenticated) {
        setTimeout(() => {
          this.timeOutUnreadingReports();
        }, 5000);
      }
    },
    async onErrorHandler() {
      await this.$store.dispatch("resetError");
    },
    async menuActionClick(action) {
      switch (action) {
        case "hide":
          if (this.isMobile) {
            this.drawer = false;
          } else {
            this.mini = !this.mini;
          }
          break;
        case "logout":
          this.mini = false;
          await this.$store.dispatch("LogOut");
          await router.push("/login");
          break;
        default:
          break;
      }
    },
  },
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    isShowBanner() {
      if (this.isAuth) {
        return !!this.$store.getters.StateUser.privs[
          CONSTS.PRIVS_KEYS.show_banner
        ];
      }
      return false;
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    email() {
      return this.$store.getters.StateUser.email;
    },
    isAuth() {
      return this.$store.getters.isAuthenticated;
    },
    isError() {
      return this.$store.getters.isError;
    },
  },

  mounted() {
    this.$store.commit("hideLoader");
    this.onErrorHandler();
    this.valuesCheckboxState = false;
    this.$eventHub.$on("errorEvent", this.onErrorHandler);
    if (this.$vuetify.breakpoint.md) {
      this.mini = true && !this.isAlternate;
    }
    if (this.isMobile) {
      this.drawer = false;
    }
    if (this.isAuth) {
      this.timeOutUnreadingReports();
    }
  },
  data: () => ({
    snackbarNewReport: false,
    valuesCheckboxState: false,
    dialogError: false,
    drawer: false,
    mini: false,
  }),
};
</script>

import moment from "moment";
import retypeLangRu from "./retypeLangRu";
import retypeLangEn from "./retypeLangEn";
import { CONSTS } from "./constants.js";

class Utils {
  createFilename(params) {
    console.log(params);
    const city = params.cities;
    const channels = params.channels;
    const shows = params.shows;
    const start = moment(params.range.start).locale("ru").format("DD.MM.YYYY");
    const end = moment(params.range.end).locale("ru").format("DD.MM.YYYY");
    const startTime = moment(params.range.start).locale("ru").format("HH:mm");
    const endTime = moment(params.range.end).locale("ru").format("HH:mm");
    const weekdays = params.range.weekdays;
    const indicator = params.indicator;
    const reportTime = moment(params.reportTime, "DD.MM.YYYY HH:mm:ss").format("DDMMYYYYHHmmss");

    const wdArray = weekdays.sort();
    if (wdArray[0] == 1) wdArray.push(wdArray.shift());
    let indicatorStr="";
    if (indicator) {
      indicatorStr = indicator.split(", ").join("_").split(" ").join("_").split(".").join("");
    };
    const wdStr = wdArray.length < 7 ? "_"+wdArray.map(item=>CONSTS.WEEKDAYS[item-1]).join("_"):"";
    const showsStr = shows?.length? `_Телепередачи-${shows.length}`: "";
    //const channelsStr = `_${channels.map((item) => item.export_name).join("--")}`
    const channelsStr = channels.length ? `_Телеканалы-${channels.length}` : "";
    const dtRange = `${start.split('.').join('')}-${end.split('.').join('')}_${startTime.replace(':','')}-${endTime.replace(':','')}`;
    const filename = `${city.name}_${indicatorStr}_${dtRange}${channelsStr}${showsStr}${wdStr}_${reportTime}`;
    console.log(filename);
    return filename;
  }

  saveAs(uri, filename) {
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = uri;
      link.download = filename;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  }

  filterAutoComplete(item, queryText, itemText) {
    if (item.header) {
      return true;
    }
    queryText = queryText.toLocaleLowerCase();
    let queryTextRu = retypeLangRu(queryText);
    let queryTextEn = retypeLangEn(queryText);
    itemText = itemText.toLocaleLowerCase();
    return (
      itemText.indexOf(queryTextEn) > -1 || itemText.indexOf(queryTextRu) > -1
    );
  }

  indicatorsList(radioHelpersType, page, indicators, reportType) {
    let list = [
      {
        id: "Audience",
        name: "Аудитория",
        disabled: radioHelpersType !== "mh",
        visible: true,
        visibleNoAdmin: true,
        priv_id: CONSTS.PRIVS_KEYS.audience,
      },
      // {
      //   id: "AudienceDemo",
      //   name: "Аудитория с демографией",
      //   disabled: this.radioHelpersType !== "mh",
      //   visible: true,
      //   visibleNoAdmin: true,
      // },
      {
        id: "TVR",
        name: "Рейтинг",
        disabled: radioHelpersType !== "mh",
        visible: true,
        visibleNoAdmin: true,
        priv_id: CONSTS.PRIVS_KEYS.tvr,
      },
      {
        id: "TVRTotal",
        name: "Рейтинг общий",
        disabled: radioHelpersType !== "mh" || !page.all_channel,
        visible: page.type === "tv",
        visibleNoAdmin: true,
        priv_id: CONSTS.PRIVS_KEYS.tvr_total,
      },
      {
        id: "Share",
        name: "Доля",
        disabled: radioHelpersType !== "mh",
        visible: true,
        visibleNoAdmin: true,
        priv_id: CONSTS.PRIVS_KEYS.share,
      },
      {
        id: "Reach",
        name: "Охват",
        disabled:
          radioHelpersType !== "mh" || page.type === "incomes",
        visible:
          page.type !== "compare-tv-period" &&
          page.type !== "compare-tv",
        visibleNoAdmin: true,
        priv_id: CONSTS.PRIVS_KEYS.reach,
      },
      {
        id: "ReachPercent",
        name: "Охват %",
        disabled:
          radioHelpersType !== "mh" || page.type === "incomes",
        visible:
          page.type !== "compare-tv-period" &&
          page.type !== "compare-tv",
        visibleNoAdmin: true,
        priv_id: CONSTS.PRIVS_KEYS.reach_percent,
      },
      {
        id: "All",
        name: "Сводный",
        disabled: radioHelpersType !== "mh" || reportType === "hour",
        visible:
          (page.type === "tv") ||
          page.type === "show" ||
          page.type === "compare-tv-period",
        visibleNoAdmin: true,
        priv_id: CONSTS.PRIVS_KEYS.indicator_all,
      },
      {
        id: "Watchtime",
        name: "Время просмотра",
        visible:
          page.type !== "compare-tv-period" &&
          page.type !== "compare-tv",
        visibleNoAdmin: false,
        priv_id: CONSTS.PRIVS_KEYS.watch_time,
      },
      {
        id: "UuidCount",
        name: "Кол-во приставок за час",
        visible:
          page.type !== "compare-tv-period" &&
          page.type !== "compare-tv",
        visibleNoAdmin: false,
        priv_id: CONSTS.PRIVS_KEYS.dev_hour,
      },
      {
        id: "SanCount",
        name: "Кол-во домохозяйств за час",
        visible:
          page.type !== "compare-tv-period" &&
          page.type !== "compare-tv",
        visibleNoAdmin: false,
        priv_id: CONSTS.PRIVS_KEYS.hh_hour,
      },
      {
        id: "UuidCountDay",
        name: "Кол-во приставок за сутки",
        visible:
          page.type !== "compare-tv-period" &&
          page.type !== "compare-tv",
        visibleNoAdmin: false,
        priv_id: CONSTS.PRIVS_KEYS.dev_day,
      },
      {
        id: "SanCountDay",
        name: "Кол-во домохозяйств за сутки",
        visible:
          page.type !== "compare-tv-period" &&
          page.type !== "compare-tv",
        visibleNoAdmin: false,
        priv_id: CONSTS.PRIVS_KEYS.hh_day,
      },
    ];
    list = list.filter((el) => {
      return el.visible;
    });

    for (const ind in indicators) {
      let el_index = list.findIndex((el) => el.priv_id == ind);
      if (el_index != -1) {
        switch (indicators[ind]) {
          case 0:
            list[el_index].visible = false;
            break;
          case 1:
            list[el_index].visible = true;
            break;
          default:
            list[el_index].visible = true;
            list[el_index].disabled = true;
            break;
        }
      }
    }
    list = list.filter((el) => {
      return el.visible;
    });
    return list;
  }

  indicatorName(indicator) {
    let title = "Показатель";
    switch (indicator) {
      case "Watchtime":
        title = "Время просмотра, с";
        break;
      case "UuidCount":
        title = "Уникальные устройства за час";
        break;
      case "SanCount":
        title = "Уникальные домохозяйства за час";
        break;
      case "UuidCountDay":
        title = "Уникальные устройства за сутки";
        break;
      case "SanCountDay":
        title = "Уникальные домохозяйства за сутки";
        break;
      case "Audience":
        title = "Аудитория, тыс.";
        break;
      case "AudienceDemo":
        title = "Аудитория, тыс.";
        break;
      case "TVR":
        title = "Рейтинг";
        break;
      case "Share":
        title = "Доля, %";
        break;
      case "All":
        title = "Аудитория, тыс.";
        break;
      case "Reach":
        title = "Охват, тыс. чел.";
        break;
      case "ReachPercent":
        title = "Охват, %";
        break;
    }
    return title;
  }

  aggregationName(aggregation, report = null) {
    switch (aggregation) {
      case "Period":
        return "Период";
      case "Month":
        return "Месяц";
      case "Year":
        return "Год";
      case "Week":
        return "Неделя";

      case "None":
        return "Без группировки";

      case "Program":
        return "По передаче";

      case "ProgramTime":
        return "По передаче и времени выхода";

      case "ProgramSubprogram":
        return "По передаче и серии";

      case "ProgramSubprogramTime":
        return "По передаче, серии и времени выхода";

      case "Day":
        if (report == "hour") {
          return "По дням";
        }
        return "День";
    }
    return "";
  }

  pluralForm(n, form1, form2, form3) {
    n = n % 100;
    let n1 = n % 10;

    if (n > 10 && n < 20) {
      return n + " " + form3;
    }

    if (n1 > 1 && n1 < 5) {
      return n + " " + form2;
    }

    if (n1 == 1) {
      return n + " " + form1;
    }

    return n + " " + form3;
  }

  range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }
  
  drawWeekdays(wdays) {
    if (!wdays.length) return "";
    let weekdays = [...wdays].sort();
    weekdays = weekdays.map((item) => CONSTS.WEEKDAYS[item - 1] ?? null);
    if (weekdays[0] == "вс") {
      weekdays.shift();
      weekdays.push("вс");
    }
    return `(${weekdays.join(",")})`;
  }
  generateUID() {
    var firstPart = (Math.random() * 46656);
    var secondPart = (Math.random() * 46656);
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  }

}

export default new Utils();
